import {
    Ad_PLAN_REQUEST,
    Ad_PLAN_SUCCESS,
    Ad_PLAN_FAIL,
    CLEAR_ERRORS,
    STRIPE_PAYMENT_REQUEST,
    STRIPE_PAYMENT_SUCCESS,
    STRIPE_PAYMENT_FAIL,
    CHECK_PLAN_REQUEST,
    CHECK_PLAN_FAIL,
    CHECK_PLAN_SUCCESS,
    CHECK_PLAN_RESET,
    ADVERTISE_PRODUCT_REQUEST,
    ADVERTISE_PRODUCT_SUCCESS,
    ADVERTISE_PRODUCT_FAIL,
    ADVERTISE_FREE_REQUEST,
    ADVERTISE_FREE_SUCCESS,
    ADVERTISE_FREE_FAIL,
    ADVERTISE_FREE_RESET,
    DELETE_ADVERTISE_REQUEST,
    DELETE_ADVERTISE_RESET,
    DELETE_ADVERTISE_SUCCESS,
    DELETE_ADVERTISE_FAIL,
} from '../constants/productConstants';
import { CLEAR_MESSAGE } from '../constants/userConstants';
import { makeRequestGet, makeRequestPost } from '../utils/apiHelper';
import $ from 'jquery';

export const getAllAdProducts = (category_slug, page = 0) => async (dispatch) => {
    try {
        dispatch({ type: ADVERTISE_PRODUCT_REQUEST })
        var filter = {};
        var order = {};
        var order_by_type = '';
        var order_by_value = '';
        if (page >= 1) {
            filter = JSON.parse(localStorage.getItem(category_slug + "filter"));
            order = JSON.parse(localStorage.getItem(category_slug + "_sorting"));
            if (order) {
                order_by_type = order.order_by_type
                order_by_value = order.order_by_value
            }
        } else {
            localStorage.removeItem(category_slug + "filter")
            localStorage.removeItem(category_slug + "_sorting")
        }
        const data = await makeRequestPost('advertised-products', { category_slug, page, filter, order_by_type, order_by_value })

        if (data.status) {
            dispatch({
                type: ADVERTISE_PRODUCT_SUCCESS,
                payload: data.data
            })
            return;
        }
        else {
            dispatch({
                type: ADVERTISE_PRODUCT_FAIL,
                payload: data.message
            })
        }
    } catch (error) {
        dispatch({
            type: ADVERTISE_PRODUCT_FAIL,
            payload: error
        })
    }
}

export const getAllAdProductsFilter = (category_slug, filter, page = 0, discountDetails) => async (dispatch) => {
    try {
        dispatch({ type: ADVERTISE_PRODUCT_REQUEST })
        localStorage.setItem(category_slug + "filter", JSON.stringify(filter));
        let order_by_type = discountDetails?.order_by_type;
        let order_by_value = discountDetails?.order_by_value;
        const data = await makeRequestPost('advertised-products', { category_slug, filter, page, order_by_type, order_by_value })

        if (data.status) {
            dispatch({
                type: ADVERTISE_PRODUCT_SUCCESS,
                payload: data.data
            })
            return;
        }
        else {
            dispatch({
                type: ADVERTISE_PRODUCT_FAIL,
                payload: data.message
            })
        }
    } catch (error) {
        dispatch({
            type: ADVERTISE_PRODUCT_FAIL,
            payload: error
        })
    }
}


export const getAdProductsFilterByAscending = (category_slug, value, type) => async (dispatch) => {
    try {
        dispatch({ type: ADVERTISE_PRODUCT_REQUEST })
        var filter = {};
        filter = JSON.parse(localStorage.getItem(category_slug + "filter"));
        localStorage.setItem(category_slug + "_sorting", JSON.stringify({ order_by_value: value, order_by_type: type }));
        const data = await makeRequestPost('advertised-products', { category_slug, order_by_value: value, order_by_type: type, filter })

        if (data.status) {
            dispatch({
                type: ADVERTISE_PRODUCT_SUCCESS,
                payload: data.data
            })
            return;
        }
        else {
            dispatch({
                type: ADVERTISE_PRODUCT_FAIL,
                payload: data.message
            })
        }
    } catch (error) {
        dispatch({
            type: ADVERTISE_PRODUCT_FAIL,
            payload: error
        })
    }
}


export const myAdProductFilterByAscending = (category_slug, value, type) => async (dispatch) => {
    try {
        dispatch({ type: ADVERTISE_PRODUCT_REQUEST })

        const data = await makeRequestPost('advertised-products', { category_slug, order_by_value: value, order_by_type: type, userProducts: true })
        localStorage.setItem(category_slug + "_sortingmy", JSON.stringify({ order_by_value: value, order_by_type: type }));
        if (data.status) {
            dispatch({
                type: ADVERTISE_PRODUCT_SUCCESS,
                payload: data.data
            })
            return;
        }
        else {
            dispatch({
                type: ADVERTISE_PRODUCT_FAIL,
                payload: data.message
            })
        }
    } catch (error) {
        dispatch({
            type: ADVERTISE_PRODUCT_FAIL,
            payload: error
        })
    }
}


export const myAdProductFilter = (category_slug, filter) => async (dispatch) => {
    try {
        dispatch({ type: ADVERTISE_PRODUCT_REQUEST })

        const data = await makeRequestPost('advertised-products', { category_slug, filter, userProducts: true, })

        if (data.status) {
            dispatch({
                type: ADVERTISE_PRODUCT_SUCCESS,
                payload: data.data
            })
            return;
        }
        else {
            dispatch({
                type: ADVERTISE_PRODUCT_FAIL,
                payload: data.message
            })
        }
    } catch (error) {
        dispatch({
            type: ADVERTISE_PRODUCT_FAIL,
            payload: error
        })
    }
}


export const advertisementPlan = () => async (dispatch) => {

    try {
        dispatch({ type: Ad_PLAN_REQUEST })

        const data = await makeRequestGet('advertisment-plans')

        if (data.status) {
            dispatch({
                type: Ad_PLAN_SUCCESS,
                payload: data.data
            })
        } else {
            dispatch({
                type: Ad_PLAN_FAIL,
                payload: data.message
            })
        }
    } catch (error) {
        dispatch({
            type: Ad_PLAN_FAIL,
            payload: error
        })
    }
}

export const AdvertisementPurchaseAction = (payload) => async (dispatch) => {
    try {
        dispatch({
            type: STRIPE_PAYMENT_REQUEST
        })

        const data = await makeRequestPost("/advertisement-purchase", payload, true)
        if (data && data?.status === true) {
            dispatch({
                type: STRIPE_PAYMENT_SUCCESS,
                payload: data
            })
        } else {
            dispatch({
                type: STRIPE_PAYMENT_FAIL,
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: STRIPE_PAYMENT_FAIL,
            payload: error?.message
        })

    }
}

export const checkAdvertisementPlan = (id) => async (dispatch) => {
    if (id === 'RESET') {
        console.log("reset action");
        dispatch({
            type: CHECK_PLAN_RESET
        });
    } else {
        try {
            dispatch({
                type: CHECK_PLAN_REQUEST
            })

            const checkPlan = await makeRequestPost("/check-plan", { id })
            if (checkPlan && checkPlan?.status === true) {
                dispatch({
                    type: CHECK_PLAN_SUCCESS,
                    payload: checkPlan
                })
            } else {
                dispatch({
                    type: CHECK_PLAN_FAIL,
                    payload: checkPlan
                })
            }
        } catch (error) {
            dispatch({
                type: CHECK_PLAN_FAIL,
                payload: error?.message
            })
        }
    }
}

export const AdvertisementFreePurchaseAction = (product_id) => async (dispatch) => {
    if (product_id === 'RESET') {
        dispatch({
            type: ADVERTISE_FREE_RESET
        });
    } else {
        try {
            dispatch({
                type: ADVERTISE_FREE_REQUEST
            })

            const checkPlan = await makeRequestPost("/advertisement-purchase-free", { product_id })
            if (checkPlan && checkPlan?.status === true) {
                dispatch({
                    type: ADVERTISE_FREE_SUCCESS,
                    payload: checkPlan
                })
            } else {
                dispatch({
                    type: ADVERTISE_FREE_FAIL,
                    payload: checkPlan
                })
            }
        } catch (error) {
            dispatch({
                type: ADVERTISE_FREE_FAIL,
                payload: error?.message
            })

        }
    }
}

export const DeleteAdvertisementAction = (id) => async (dispatch) => {
    if (id === 'RESET') {
        dispatch({
            type: DELETE_ADVERTISE_RESET
        });
    } else {
        try {
            dispatch({
                type: DELETE_ADVERTISE_REQUEST
            })
            const checkPlan = await makeRequestPost("/delete-advertisement", { id })
            if (checkPlan && checkPlan?.status === true) {
                dispatch({
                    type: DELETE_ADVERTISE_SUCCESS,
                    payload: checkPlan
                })
            } else {
                dispatch({
                    type: DELETE_ADVERTISE_FAIL,
                    payload: checkPlan
                })
            }
        } catch (error) {
            dispatch({
                type: DELETE_ADVERTISE_FAIL,
                payload: error?.message
            })
        }
    }
}

export const clearError = () => (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}

export const clearMessage = () => (dispatch) => {
    dispatch({
        type: CLEAR_MESSAGE
    })
}