import React, {Fragment, useEffect,useState} from 'react';
import Diamonds from './Diamonds';
import Watch from './Watch';
import ProtectedHeader from '../layout/ProtectedHeader';
import { IMAGES } from '../../constants';
import { useLocation, useHistory } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { url } from '../../config';
import {getProductDetails }  from "../../actions/productActions";
import { useDispatch, useSelector } from 'react-redux';
import {getLastURL} from '../../helper';

const Index = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const product_id = getLastURL(location);

    useEffect(async () =>{
       await dispatch(getProductDetails(product_id))
    }, [])

    const { details } = useSelector(state => state.productDetails);

    return (
        <Fragment>
            <ProtectedHeader />
            <ul className="breadcrub">
                <li>
                    <FormattedMessage
                        id="myaccount.myaccountname"
                        defaultMessage="My Account"
                    />
                </li>
                <li><img src={IMAGES.ARROW_SVG} alt="" /></li>
                <li>
                    <FormattedMessage
                        id="Products"
                        defaultMessage="Products"
                    />
                </li>
                <li><img src={IMAGES.ARROW_SVG} alt="" /></li>
                <li>
                    <FormattedMessage
                        id="addsingleproduct.editproduct"
                        defaultMessage="Edit Product"
                    />
                </li>
            </ul>
            <section className="container-fluid py-4 conPart">
                <div className="row justify-content-center">
                    <div className="col-md-9">
                        <div className="titles nbrd mt-4 mb-3 pb-3">
                            <div className="row">
                                <div className="col-md-12">
                                    <h2 className="m-0">
                                        <FormattedMessage
                                            id="addsingleproduct.editproduct"
                                            defaultMessage="Edit Product"
                                        />
                                    </h2>
                                </div>
                            </div>
                        </div>
                        {  <Diamonds /> }
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default Index
