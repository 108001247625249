import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    REGISTER_REQUEST,
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    USER_LOAD_REQUEST,
    USER_LOAD_SUCCESS,
    USER_LOAD_FAIL,
    LOGOUT_SUCCESS,
    LOGOUT_FAIL,
    GET_PROFILE_REQUEST,
    GET_PROFILE_SUCCESS,
    GET_PROFILE_FAIL,
    UPDATE_PROFILE_REQUEST,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PROFILE_FAIL,
    UPDATE_PROFILE_RESET,
    CLEAR_ERRORS,
    UPDATE_PASSWORD_REQUEST,
    UPDATE_PASSWORD_SUCCESS,
    UPDATE_PASSWORD_RESET,
    UPDATE_PASSWORD_FAIL,
    FORGET_PASSWORD_REQUEST,
    FORGET_PASSWORD_FAIL,
    FORGET_PASSWORD_SUCCESS,
    GET_PLAN_REQUEST,
    GET_PLAN_SUCCESS,
    GET_PLAN_FAIL,
    GET_CITY_REQUEST,
    GET_CITY_SUCCESS,
    GET_CITY_FAIL,
    GET_COUNTRY_REQUEST,
    GET_COUNTRY_SUCCESS,
    GET_COUNTRY_FAIL,
    GET_STATE_REQUEST,
    GET_STATE_SUCCESS,
    SUBMIT_BUSINESSINFO_REQUEST,
    SUBMIT_BUSINESSINFO_SUCCESS,
    SUBMIT_BUSINESSINFO_FAIL,
    SUBMIT_PAYMENTINFO_REQUEST,
    SUBMIT_PAYMENTINFO_SUCCESS,
    SUBMIT_PAYMENTINFO_FAIL,
    CONTACT_US_REQUEST,
    CONTACT_US_SUCCESS,
    CONTACT_US_FAIL,
    CURRENT_PLAN_REQUEST,
    CURRENT_PLAN_SUCCESS,
    CURRENT_PLAN_FAIL,
    GET_PLAN_ROLE_REQUEST,
    GET_PLAN_ROLE_SUCCESS,
    GET_PLAN_ROLE_FAIL,
    GET_STATE_FAIL,
    SEND_OTP_REQUEST,
    SEND_OTP_SUCCESS,
    SEND_OTP_FAIL,
    SEND_OTP_RESET,
    ADD_REVIEW_REQUEST,
    ADD_REVIEW_SUCCESS,
    ADD_REVIEW_FAIL,
    ADD_REVIEW_RESET,
    GET_REVIEW_REQUEST,
    GET_REVIEW_SUCCESS,
    GET_REVIEW_FAIL,
    PAYMENTHISTORY_REQUEST,
    PAYMENTHISTORY_SUCCESS,
    PAYMENTHISTORY_FAIL,
    CANCEL_SUBSCRIPTION_REQUEST,
    CANCEL_SUBSCRIPTION_SUCCESS,
    CANCEL_SUBSCRIPTION_FAIL,
    SET_FREEMIUM_REQUEST,
    SET_FREEMIUM_SUCCESS,
    SET_FREEMIUM_FAIL
} from '../constants/userConstants';


export const userReducer = (state = { user: null }, action) => {

    switch (action.type) {
        case LOGIN_REQUEST:
        case REGISTER_REQUEST:
        case USER_LOAD_REQUEST:

            return {
                loading: true,
                isAuthenticated: false
            }

        case LOGIN_SUCCESS:
        case REGISTER_SUCCESS:
        case USER_LOAD_SUCCESS:
            // case CURRENT_PLAN_SUCCESS :        
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                user: action.payload
            }


        case LOGOUT_SUCCESS:
            return {
                loading: false,
                isAuthenticated: false,
                user: null
            }

        case USER_LOAD_FAIL:
            return {
                loading: false,
                isAuthenticated: false,
                user: null,
                error: action.payload
            }


        case LOGOUT_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case LOGIN_FAIL:
        case REGISTER_FAIL:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                user: null,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default: return state
    }

}

export const profileReducer = (state = {}, action) => {

    switch (action.type) {
        case UPDATE_PROFILE_REQUEST:
        case UPDATE_PASSWORD_REQUEST:
            return {
                ...state,
                loading: true
            }
        case UPDATE_PROFILE_SUCCESS:
        case UPDATE_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: action.payload,
                error: null
            }
        case UPDATE_PROFILE_RESET:
        case UPDATE_PASSWORD_RESET:
            return {
                ...state,
                loading: false,
                isUpdated: false
            }
        case UPDATE_PROFILE_FAIL:
        case UPDATE_PASSWORD_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
                message: null
            }

        default: return state
    }

}

export const forgetReducer = (state = {}, action) => {

    switch (action.type) {
        case FORGET_PASSWORD_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            }
        case FORGET_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                message: action.payload
            }
        case FORGET_PASSWORD_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
                message: null
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
                message: null
            }

        default: return state
    }

}

export const getPlanReducer = (state = { plan: [] }, action) => {

    switch (action.type) {
        case GET_PLAN_REQUEST:
            return {
                loading: true,
                plan: []
            }
        case GET_PLAN_SUCCESS:
            return {
                loading: false,
                plan: action.payload
            }
        case GET_PLAN_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        default: return state
    }
}

export const getCountryReducer = (state = { country: [] }, action) => {

    switch (action.type) {
        case GET_COUNTRY_REQUEST:
            return {
                loading: true,
                country: []
            }
        case GET_COUNTRY_SUCCESS:
            return {
                loading: false,
                country: action.payload
            }
        case GET_COUNTRY_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        default: return state
    }
}

export const getStateReducer = (state = { state: [] }, action) => {

    switch (action.type) {
        case GET_STATE_REQUEST:
            return {
                loading_state: true,
                state: []
            }
        case GET_STATE_SUCCESS:
            return {
                loading_state: false,
                state: action.payload
            }
        case GET_STATE_FAIL:
            return {
                loading_state: false,
                error: action.payload
            }

        default: return state
    }
}

export const getCityReducer = (state = { city: [] }, action) => {

    switch (action.type) {
        case GET_CITY_REQUEST:
            return {
                loading_city: true,
                city: []
            }
        case GET_CITY_SUCCESS:
            return {
                loading_city: false,
                city: action.payload
            }
        case GET_CITY_FAIL:
            return {
                loading_city: false,
                error: action.payload
            }

        default: return state
    }
}

export const submitBusinessInfo = (state = { businessInfoData: null }, action) => {
    switch (action.type) {
        case SUBMIT_BUSINESSINFO_REQUEST:
            return {
                loading: true,
                businessInfoData: null
            }
        case SUBMIT_BUSINESSINFO_SUCCESS:
            return {
                loading: false,
                error: null,
                businessInfoData: action.payload
            }
        case SUBMIT_BUSINESSINFO_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        default: return state
    }
}

export const submitPaymentInfo = (state = { paymentInfoData: null }, action) => {
    switch (action.type) {
        case SUBMIT_PAYMENTINFO_REQUEST:
            return {
                loading: true,
                paymentInfoData: null
            }
        case SUBMIT_PAYMENTINFO_SUCCESS:
            return {
                loading: false,
                error: null,
                paymentscript: action.paymentscript,
                paymentInfoData: action.payload
            }
        case SUBMIT_PAYMENTINFO_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        default: return state
    }
}

export const paymentHistory = (state = { paymentHistoryData: null }, action) => {
    switch (action.type) {
        case PAYMENTHISTORY_REQUEST:
            return {
                loading: true,
                paymentHistoryData: null
            }
        case PAYMENTHISTORY_SUCCESS:
            return {
                loading: false,
                error: null,
                paymentHistoryData: action.payload
            }
        case PAYMENTHISTORY_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        default: return state
    }
}

export const contactusReducer = (state = { contactus: null }, action) => {
    switch (action.type) {
        case CONTACT_US_REQUEST:
            return {
                loading: true,
                contactus: null
            }
        case CONTACT_US_SUCCESS:
            return {
                loading: false,
                error: null,
                contactus: action.payload
            }
        case CONTACT_US_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
                message: null
            }
        default: return state
    }
}

export const currentplanReducer = (state = { currentplan: null }, action) => {
    switch (action.type) {
        case CURRENT_PLAN_REQUEST:
            return {
                loadingCp: true,
                currentplan: null
            }
        case CURRENT_PLAN_SUCCESS:
            return {
                loadingCp: false,
                error: null,
                currentplan: action.payload
            }
        case CURRENT_PLAN_FAIL:
            return {
                loadingCp: false,
                error: action.payload
            }
        default: return state
    }
}

export const cancelPlanReducer = (state = { cancelplan: null }, action) => {
    switch (action.type) {
        case CANCEL_SUBSCRIPTION_REQUEST:
            return {
                loading: true,
                cancelplan: null
            }
        case CANCEL_SUBSCRIPTION_SUCCESS:
            return {
                loading: false,
                error: null,
                cancelplan: action.payload
            }
        case CANCEL_SUBSCRIPTION_FAIL:
            return {
                loading: false,
                error: action.payload,
                cancelplan: action.payload
            }
        default: return state
    }
}


export const planByRoleReducer = (state = { plan_role: null }, action) => {
    switch (action.type) {
        case GET_PLAN_ROLE_REQUEST:
            return {
                loading: true,
                plan_role: null
            }
        case GET_PLAN_ROLE_SUCCESS:
            return {
                loading: false,
                error: null,
                plan_role: action.payload
            }
        case GET_PLAN_ROLE_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        default: return state
    }
}

export const updateProfileReducer = (state = { updateProfile: null }, action) => {

    switch (action.type) {
        case UPDATE_PROFILE_REQUEST:
            return {
                loading: true,
                updateProfile: null
            }
        case UPDATE_PROFILE_SUCCESS:
            return {
                loading: false,
                updateProfile: action.payload
            }
        case UPDATE_PROFILE_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        case UPDATE_PROFILE_RESET:
            return {
                updateProfile: null
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default: return state
    }
}


export const sendOtpReducer = (state = { sendOtp: null }, action) => {

    switch (action.type) {
        case SEND_OTP_REQUEST:
            return {
                loading: true,
                sendOtp: null
            }
        case SEND_OTP_SUCCESS:
            return {
                loading: false,
                sendOtp: action.payload
            }
        case SEND_OTP_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        case SEND_OTP_RESET:
            return {
                sendOtp: null
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default: return state
    }
}


export const addReviewReducer = (state = { review: null }, action) => {

    switch (action.type) {
        case ADD_REVIEW_REQUEST:
            return {
                loading: true,
                review: null
            }
        case ADD_REVIEW_SUCCESS:
            return {
                loading: false,
                review: action.payload
            }
        case ADD_REVIEW_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        case ADD_REVIEW_RESET:
            return {
                review: null,
                error: null
            }
        case CLEAR_ERRORS:
            return {
                error: null
            }
        default: return state
    }
}



export const getReviewReducer = (state = { allReview: null }, action) => {

    switch (action.type) {
        case GET_REVIEW_REQUEST:
            return {
                loading: true,
                allReview: null
            }
        case GET_REVIEW_SUCCESS:
            return {
                loading: false,
                allReview: action.payload
            }
        case GET_REVIEW_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default: return state
    }
}


export const getReviewProfileReducer = (state = { allReview: null }, action) => {

    switch (action.type) {
        case GET_REVIEW_REQUEST:
            return {
                loading: true,
                allReview: null
            }
        case GET_REVIEW_SUCCESS:
            return {
                loading: false,
                allReview: action.payload
            }
        case GET_REVIEW_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default: return state
    }
}


export const getProfileReducer = (state = { getProfile: null }, action) => {

    switch (action.type) {
        case GET_PROFILE_REQUEST:
            return {
                loading: true,
                getProfile: null
            }
        case GET_PROFILE_SUCCESS:
            return {
                loading: false,
                getProfile: action.payload
            }
        case GET_PROFILE_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default: return state
    }
}


export const setFreemiumReducer = (state = { setFreemium: null }, action) => {

    switch (action.type) {
        case SET_FREEMIUM_REQUEST:
            return {
                loading: true,
                setFreemium: null
            }
        case SET_FREEMIUM_SUCCESS:
            return {
                loading: false,
                setFreemium: action.payload
            }
        case SET_FREEMIUM_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default: return state
    }
}

