import React, { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import $ from 'jquery';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { url } from '../../../config';
import { getLastURL } from '../../../helper';
import { FormattedMessage } from 'react-intl';

const Sidebar = () => {

    const location = useLocation();
    const path = getLastURL(location);

    const { category } = useSelector(state => state.categories);

    useEffect(() => {
        $('.myproduct-sidebar li').removeClass('active');
        $('.' + path).addClass('active');
    }, [category, path])

    return (
        <Fragment>
            <div className="col-xl-2 col-lg-3 col-md-3 lefts pl-xl-5 col-sm-4">
                <h2 className="mt-4 mb-3">
                    <FormattedMessage
                        id="Products"
                        defaultMessage="Products"
                    />
                </h2>
                <ul className="myproduct-sidebar">
                    {category?.categories?.map((category) => {
                        return (
                            <li className={category.slug}>
                                <Link to={url + '/myproducts/' + category?.slug}>
                                    <FormattedMessage
                                        id={"category." + category?.slug}
                                        defaultMessage={category?.name}
                                    />
                                    <span className="pro_nub w-auto px-2"> {category?.products_count} </span>
                                </Link>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </Fragment>
    )
}

export default Sidebar
