import * as yup from "yup";
import { ValidationMessage } from "./CommonValidation";

const DiamondValidation = yup.object().shape({
    shape: yup.string()
        .required(ValidationMessage.Field("shape"))
        .trim(),
    clarity: yup.string()
        .required(ValidationMessage.Field("clarity"))
        .trim(),
    color: yup.string()
        .required(ValidationMessage.Field("color"))
        .trim(),
    milky: yup.string()
        .required(ValidationMessage.Field("milky"))
        .trim(),
    carat: yup.string()
        .required(ValidationMessage.Field("carat"))
        .trim(),
    mm_h: yup.string()
        // .required(ValidationMessage.Field("depth"))
        .trim(),
    mm_l: yup.string()
        // .required(ValidationMessage.Field("length"))
        .trim(),
    mm_w: yup.string()
        // .required(ValidationMessage.Field("weight"))
        .trim(),
    certificate: yup.string()
        .required(ValidationMessage.Field("certificate"))
        .trim(),
    availability_status: yup.string()
        .required(ValidationMessage.Field("status"))
        .trim(),
    price: yup.string().trim(),
    discount: yup.string().trim(),
    stock_number: yup.string()
        .required(ValidationMessage.Field("stock number"))
        .trim(),
    images: yup.mixed()
        .notRequired()
        .test("fileType", ValidationMessage?.UNSUPPORTED + " Please choose jpg, jpeg and png", (file) => {
            if (file?.length > 0 && file[0]?.type) {
                var file_type = file?.length && ["image/jpeg", "image/png", "image/jpg"].includes(file[0]?.type)
                if (file_type) {
                    return file_type;
                }
                else {
                    return file_type;
                }
            }
            else {
                return true;
            }
        }),
    video: yup.string(),
    comment: yup.string()
        .trim(),
});

export default DiamondValidation;