import React, { Fragment, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { url, postPerPage, hrdurl, giaurl } from '../../config';
import { FormattedMessage, useIntl } from 'react-intl';
import { AdvertismentList, getAllAdvertismentList, clearMessage, GetProductCount } from '../../actions/productActions';
import { getLastURL, handleSortBy, pColmDisplay, GenerateCertificatePdf, capitalizeFirstLetterWithOnlyLetter, totalPriceDisplay, totalPriceDisplayForAds, totalCarat, totalPriceDisplayforDiscount } from '../../helper';
import Loader from '../layout/Loader';
import { advertisementJson } from '../../utils/json';
import $ from 'jquery';
import { getCategory } from '../../actions/categoryAction';
import { useAlert } from 'react-alert';
import ReactPaginate from "react-paginate";
import PopupConfirm from '../layout/PopupConfirm';
import MetaData from '../layout/MetaData';
import { DeleteAdvertisementAction } from '../../actions/Advertisement';
import { IMAGES } from '../../constants';

const Advertisement = (props) => {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const alert = useAlert();
    const category_slug = getLastURL(location);

    useEffect(() => {
        dispatch(AdvertismentList(category_slug, 1));
    }, [dispatch])

    const [pageNumber, setPageNumber] = useState(props.pageNumber && props.pageNumber);
    const { myproduct, data, loading } = useSelector(state => state.AdvertisementReducer);
    const deleteAd = useSelector(state => state.DeleteAdvertisementState);
    const [filterdisplayname, setFilterDisplayName] = useState(<FormattedMessage id="Select Sort Option" />);
    const [search, setSearch] = useState();
    const [currentPage, setCurrentPage] = useState(0);
    const [postsPerPage, setPostPerPage] = useState(postPerPage);

    const handlePageChange = ({ selected }) => {
        setPageNumber(selected)
        props.updateResetPageHandler(selected)
        dispatch(getAllAdvertismentList(category_slug, (selected + 1)));
    }

    const pageCount = Math.ceil((myproduct && myproduct.total) / postsPerPage);

    const handleAcendingFilter = (value, type, displayname) => {
        setFilterDisplayName(displayname)
        $('.sort_by_list').css({
            display: "none"
        })
        props.tabChangeHandler(1)
        dispatch(AdvertismentList(category_slug, value, type, null))
    }

    const handleSearch = (e) => {
        setSearch(e.target.value);
    }

    const handleSearchFilter = (e) => {
        if (e.key === 'Enter') {
            dispatch(AdvertismentList(category_slug, null, null, search));
        }
    }
    const handleSearchIconClick = () => {
        dispatch(AdvertismentList(category_slug, null, null, search));
    };

    const handleResForm = async (status) => {
        if (status) {
            await dispatch(GetProductCount());
            await dispatch(getCategory());
        }
        $("#dismiss-modal").trigger('click');

    }

    const handleDetails = (id) => {
        history.push(url + `/details/diamonds/${id}`);
    }
    let i = 1;

    const handleDelete = async (id) => {
        await dispatch(DeleteAdvertisementAction(id))
    }
    
    useEffect(() => {
        if(deleteAd?.details){
            dispatch(AdvertismentList(category_slug, 1))
            dispatch(DeleteAdvertisementAction('RESET'))
        }
    },[deleteAd?.details])
console.log("myproduct?.data>>>>>", myproduct?.data);

    return (
        <Fragment>
            {(loading || deleteAd?.loading) && <Loader />}
            <MetaData title={`Advertisement History`} />
            <Fragment>
                <div className="col-xl-10 col-lg-9 col-md-9 col-sm-8">

                    <div className="titles mt-4  border-0">
                        <div className="filter_search_right">
                            <div className="search_cat_top border-0">
                                <div className="row">

                                    <div className="col-lg-12 col-md-12 col-sm-12 d-flex flex-wrap justify-content-md-end">
                                        <div className="form-group pb-0 sort_by_box">
                                            <p className="sort_b_h text-right" onClick={handleSortBy}><b className="mr-2"><FormattedMessage id="Sort_By" />:</b>{filterdisplayname} <span className="icon-chevron_down"></span></p>
                                            <div className="sort_by_list">
                                                <ul>
                                                    {
                                                        advertisementJson && advertisementJson.map((data, key) => {
                                                            return (
                                                                <li className="activess" onClick={() => handleAcendingFilter(data?.value, data?.type, data?.display_name)}>{data?.display_name}</li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row filter_search_right align-items-center justify-content-between" >
                        <div className="col-lg-6 col-md-6"></div>
                        <div className=" col-lg-4 col-md-4 col-sm-4">
                            <div className="seach_filter">
                                <input type="text" value={search} onKeyPress={(e) => handleSearchFilter(e)} onChange={handleSearch} className="form-control" placeholder={useIntl().formatMessage({ id: 'SearchByAdvertisement' })} />
                                <span className="icon-search_1  input_icon position-absolute" onClick={handleSearchIconClick} style={{ cursor: 'pointer' }}></span>
                            </div>
                        </div>
                    </div>

                    <div className="tbles mb-5">
                        <table width="100%">
                            <tr>
                                <th>#</th>
                                <th><FormattedMessage id="addsingleproduct.stockno" /></th>
                                <th><FormattedMessage id="Shape" defaultMessage="Shape" /></th>
                                <th><FormattedMessage id="addsingleproduct.carat" defaultMessage="Carat" /></th>
                                <th><FormattedMessage id="addsingleproduct.color" defaultMessage="Color" /></th>
                                <th><FormattedMessage id="addsingleproduct.clarity" defaultMessage="Clarity" /></th>
                                <th><FormattedMessage id="addsingleproduct.cut" defaultMessage="Cut" /></th>
                                <th><FormattedMessage id="addsingleproduct.pol" defaultMessage="Pol" /></th>
                                <th><FormattedMessage id="addsingleproduct.sym" defaultMessage="Sym" /></th>
                                <th><FormattedMessage id="addsingleproduct.Fluor" defaultMessage="Fluor" /></th>
                                <th><FormattedMessage id="Disc" defaultMessage="Disc" /></th>
                                <th><FormattedMessage id="addsingleproduct.price" /></th>
                                <th><FormattedMessage id="Cert" defaultMessage="Cert" /></th>
                                <th><FormattedMessage id="addsingleproduct.transaction_id" defaultMessage="Transaction Id" /></th>
                                <th><FormattedMessage id="addsingleproduct.start_date" defaultMessage="Start Date" /></th>
                                <th><FormattedMessage id="addsingleproduct.end_date" defaultMessage="End Date" /></th>
                                <th><FormattedMessage id="addsingleproduct.amount" defaultMessage="Amount" /></th>
                                <th><FormattedMessage id="addsingleproduct.plan_type" defaultMessage="Plan Type" /></th>
                                <th><FormattedMessage id="advertisement.status" defaultMessage="Status" /></th>
                                <th><FormattedMessage id="advertisement.admin_approved" defaultMessage="Admin Approved" /></th>
                                <th><FormattedMessage id="advertisement.action" defaultMessage="Action" /></th>
                            </tr>

                            {
                                myproduct && myproduct.data?.length > 0 ?
                                    myproduct && myproduct?.data.map((data, key) => {
                                        console.log("pColmDisplay(data?.product?.a_shape, 'a_shape')>>", pColmDisplay(data?.product?.a_shape, 'a_shape'));
                                        
                                        return (
                                            <tr>
                                                <td>{i++}.</td>
                                                <td onClick={() => handleDetails(data?.product?.id)}>{data?.product?.stock_number}</td>
                                                <td onClick={() => handleDetails(data?.product?.id)}>{data?.product?.a_shape?.display_name}</td>
                                                <td onClick={() => handleDetails(data?.product?.id)}>{data?.product?.carat && totalCarat(data?.product)}</td>
                                                <td onClick={() => handleDetails(data?.product?.id)}>{pColmDisplay(data?.product, 'a_color')}</td>
                                                <td onClick={() => handleDetails(data?.product?.id)}>{pColmDisplay(data?.product, 'a_clarity')}</td>
                                                <td onClick={() => handleDetails(data?.product?.id)}>{data?.product?.a_cut && capitalizeFirstLetterWithOnlyLetter(pColmDisplay(data?.product, 'a_cut'))}</td>
                                                <td onClick={() => handleDetails(data?.product?.id)}>{data?.product?.a_polish && capitalizeFirstLetterWithOnlyLetter(pColmDisplay(data?.product, 'a_polish'))}</td>
                                                <td onClick={() => handleDetails(data?.product?.id)}>{data?.product?.a_symmetry && capitalizeFirstLetterWithOnlyLetter(pColmDisplay(data?.product, 'a_symmetry'))}</td>
                                                <td onClick={() => handleDetails(data?.product?.id)}>{data?.product?.a_fluorescence && capitalizeFirstLetterWithOnlyLetter(pColmDisplay(data?.product, 'a_fluorescence'))}</td>

                                                <td onClick={() => handleDetails(data?.product?.id)}>
                                                    {data && data?.product?.discount && totalPriceDisplayforDiscount(data?.product)}</td>
                                                <td onClick={() => handleDetails(data?.product?.id)}>{
                                                    totalPriceDisplayForAds(data)}</td>
                                                {
                                                    (() => {
                                                        if (data && data?.product && data?.product?.certificate_url) {
                                                            return <td><a href={GenerateCertificatePdf(data?.product?.certificate_url)} target={GenerateCertificatePdf(data?.product?.certificate_url)}>{pColmDisplay(data?.product?.certificate_url, 'a_certificate')}</a></td>
                                                        }
                                                        else if (pColmDisplay(data && data?.product, 'a_certificate') == "HRD") {
                                                            return <td><a href={hrdurl + data?.product?.certificate_number} target="_blank">{data?.product?.a_certificate?.display_name}</a></td>
                                                        } else if (data && data?.product && data?.product?.certificate == "25" && data?.product?.a_certificate?.display_name == "GIA") {
                                                            return <td><a href={giaurl + data?.product?.certificate_number} target="_blank">{data?.product?.a_certificate?.display_name}</a></td>
                                                        } else {
                                                            return <td>{pColmDisplay(data && data?.product, 'a_certificate')}</td>
                                                        }
                                                    })()
                                                }
                                                <td onClick={(e) => handleDetails(data?.product?.id)}>
                                                    {data && data?.transaction_id}</td>
                                                <td onClick={(e) => handleDetails(data?.product?.id)}>
                                                    {data && data?.start_date} </td>
                                                <td onClick={(e) => handleDetails(data?.product?.id)}>
                                                    {data && data?.end_date}</td>
                                                <td onClick={(e) => handleDetails(data?.product?.id)}>{data && (data?.plan_amount ? `$${data?.plan_amount}` : "")}</td>
                                                <td onClick={(e) => handleDetails(data?.product?.id)}>
                                                    {data && data?.plan_type}</td>
                                                <td onClick={(e) => handleDetails(data?.product?.id)}>
                                                    {data && data?.advertisment_status}</td>
                                                <td onClick={(e) => handleDetails(data?.product?.id)}>
                                                    {data && data?.is_admin_approve == 1 ? "Approved" : data && data?.is_admin_approve == 0 ? "Pending" : "Reject"}

                                                </td>
                                                <td onClick={(e) => handleDelete(data?.id)}>
                                                    <span><img src={IMAGES.DELETE} alt="" /></span>
                                                </td>
                                            </tr>
                                        )
                                    })
                                    :
                                    <tr>
                                        <th colspan="20"><FormattedMessage id="ProductNotFound" defaultMessage="Product not Found" /></th>
                                    </tr>
                            }
                        </table>
                    </div>
                    <div className="table_pagination">
                        <ReactPaginate
                            previousLabel={<i className="icon icon-chevron_gray-left"></i>}
                            nextLabel={<i className="icon icon-chevron_black"></i>}
                            pageCount={pageCount}
                            onPageChange={handlePageChange}
                            containerClassName={"paginationBttns"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"paginationDisabled"}
                            activeClassName={"paginationActive"}
                            forcePage={props?.pageReset}
                        />
                    </div>
                </div>
            </Fragment>
        </Fragment>
    )
}

export default Advertisement


