import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { productReducer, ProductTypeReducer, ProductAttributes, AddProductReducer, ProductDetailsReducer, MyProductReducer, MyProductSelectActionReducer, MyProductActiveAllReducer, AddFavoriteReducer, GetFavoriteReducer, GetFavoriteCategoryReducer, GetProductCountReducer, GetModelAttributeReducer, GetRatingTagReducer, UpdateSingleProductReducer, getSelersReducer, DownloadPdfWithFilterReducer, SoryByFilterReducer, updateDiamondReducer, AdvertisementReducer, AdvertisementPlanReducer, checkPlanReducer, adProductReducer, MyProductStatusActionReducer, MessaageTransferReducer, downloadReducer, AddColorStoneReducer, AdvertisementFreePurchaseReducer, DeleteAdvertisementReducer, UpdateColorStoneReducer } from "./reducers/productReducer";
import { userReducer, profileReducer, forgetReducer, getPlanReducer, getCountryReducer, getStateReducer, getCityReducer, submitBusinessInfo, submitPaymentInfo, contactusReducer, currentplanReducer, planByRoleReducer, updateProfileReducer, sendOtpReducer, addReviewReducer, getReviewReducer, getProfileReducer, paymentHistory, cancelPlanReducer, getReviewProfileReducer } from "./reducers/userReducer";
import { homePageReducer, termConditionReducer, policyReducer } from "./reducers/HomePageReducer";
import { getCategoryReducer } from "./reducers/categoryReducer";
import { getChatUserReducer, getMessageHistoryReducer, addChatUserReducer } from "./reducers/chatReducer";
import { PurchasePlanStripeReducer } from "./reducers/stripeReducer";
import { SubscriptionStripeReducer } from "./reducers/SubscriptionReducer";

const reducer = combineReducers({
    products: productReducer,
    user: userReducer,
    profile: profileReducer,
    forgetPassword: forgetReducer,
    productType: ProductTypeReducer,
    attributes: ProductAttributes,
    productAdd: AddProductReducer,
    colorStoneAdd: AddColorStoneReducer,
    colorStoneUpdate: UpdateColorStoneReducer,
    plan: getPlanReducer,
    country: getCountryReducer,
    state: getStateReducer,
    city: getCityReducer,
    businessData: submitBusinessInfo,
    paymentData: submitPaymentInfo,
    home: homePageReducer,
    contact: contactusReducer,
    currentPlan: currentplanReducer,
    planByRole: planByRoleReducer,
    categories: getCategoryReducer,
    updateProfile: updateProfileReducer,
    term: termConditionReducer,
    policy: policyReducer,
    chatuser: getChatUserReducer,
    messageHistory: getMessageHistoryReducer,
    productDetails: ProductDetailsReducer,
    myproduct: MyProductReducer,
    myproductselectaction: MyProductSelectActionReducer,
    myproductactiveall: MyProductActiveAllReducer,
    addChatUser: addChatUserReducer,
    addFavorite: AddFavoriteReducer,
    getFavorite: GetFavoriteReducer,
    FavoriteCategory: GetFavoriteCategoryReducer,
    ProductCount: GetProductCountReducer,
    ModelAttribute: GetModelAttributeReducer,
    SendOtp: sendOtpReducer,
    Review: addReviewReducer,
    AllReview: getReviewReducer,
    AllReviewProfile: getReviewProfileReducer,
    GetProfileWithBusiness: getProfileReducer,
    getpaymentHistory: paymentHistory,
    cancelPlanRes: cancelPlanReducer,
    GetRatingTagReducer: GetRatingTagReducer,
    UpdateSingleProductReducer: UpdateSingleProductReducer,
    getSelersReducer: getSelersReducer,
    updateDiamondReducer: updateDiamondReducer,
    // STRIPE
    PurchasePlanStripeState: PurchasePlanStripeReducer,
    SubscriptionStripeState: SubscriptionStripeReducer,
    AdvertisementReducer: AdvertisementReducer,
    DownloadPdfWithFilterState: DownloadPdfWithFilterReducer,
    SoryByFilterState: SoryByFilterReducer,
    AdvertisementPlanReducer: AdvertisementPlanReducer,
    checkPlanReducer: checkPlanReducer,
    adproducts: adProductReducer,
    myproductstatusaction: MyProductStatusActionReducer,

    // Direct Reducer
    MessaageTransferState: MessaageTransferReducer,

    //Download pdf or excel
    downloadState: downloadReducer,
    
    AdvertisementFreePurchaseState: AdvertisementFreePurchaseReducer,
    DeleteAdvertisementState: DeleteAdvertisementReducer
})

let initialState = {}

const middleware = [thunk]

const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)))

export default store;