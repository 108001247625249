import React, { useState, useEffect, Fragment, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { FEATURE_RESTRICTION, IMAGES } from "../../../constants";
import { url } from "../../../config";
import Avatar from "react-avatar";
import { logout } from "../../../actions/userActions";
import { useAlert } from "react-alert";
import PopupFail from "../PopupFail";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { GetProductCount } from "../../../actions/productActions";
import { FormattedMessage, useIntl } from "react-intl";
import { getAllProductsFilter } from "../../../actions/productActions";
import { useLocation, useHistory } from "react-router";
import {
  decryptData,
  getLastURL,
  getSecondLastURL,
  numberWithCommas,
} from "../../../helper";
import { socket } from "../../../utils/socket";
import { Context } from "../../Wrapper";
import $ from "jquery";

const TurkeyFlagPng = require("../../../assets/images/turkey.png");
const UKFlapPng = require("../../../assets/images/uk.png");

const ProtectedHeader = () => {
  const history = useHistory();
  const location = useLocation();
  const context = useContext(Context);
  const pathname = getLastURL(location);
  const secpathname = getSecondLastURL(location);

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
  };

  const handleOnHover = (result) => {
    // the item hovered
  };

  const handleOnSelect = (item) => {
    // the item selected
  };

  const formatResult = (item) => {
    return item;
    // return (<p dangerouslySetInnerHTML={{__html: '<strong>'+item+'</strong>'}}></p>); //To format result as html
  };

  const dispatch = useDispatch();
  const alert = useAlert();
  const { user } = useSelector((state) => state.user);
  const { getProfile } = useSelector((state) => state.GetProfileWithBusiness);

  const { attributes } = useSelector((state) => state.attributes);
  const { ProductCount } = useSelector((state) => state.ProductCount);
  const { getFavorite } = useSelector((state) => state.getFavorite);
  const { message } = useSelector((state) => state.productAdd);
  const { category } = useSelector((state) => state.categories);
  const [showCountryDropdown, setShowCountryDropdown] = useState(false);

  const handleLogout = async () => {
    await dispatch(logout());
    localStorage.removeItem("isAuthenticated");
    sessionStorage.removeItem("user");
    alert.success("Logout successful");
    setTimeout(() => {
      window.location.href = url;
    }, 1500);
  };

  /*   const user_id = sessionStorage.getItem('user') && JSON.parse(sessionStorage.getItem('user')) && JSON.parse(sessionStorage.getItem('user')).id && JSON.parse(sessionStorage.getItem('user')).id;
   
      socket.on('total_message_count_response', res => {
          if(res){
              
          }
          messasgeCount
      })
      socket.emit('total_message_count', {
          user_id: user_id,
      }); */
  useEffect(() => {
    if (!ProductCount) {
      dispatch(GetProductCount());
    }
    // if(sessionStorage.getItem('user') != null){
    //     const user_id = sessionStorage.getItem('user') &&  JSON.parse(sessionStorage.getItem('user')).id && JSON.parse(sessionStorage.getItem('user')).id;
    //     if(user_id){
    //         socket.emit('total_message_count', {
    //             user_id: user_id
    //         });
    //     }
    // }
    if (sessionStorage.length >= 1) {
      try {
        socket.emit("total_message_count", {});
      } catch (e) {
        console.log("eeeeeeeeeeeeeeee", e);
      }
    }

    try {
      socket.on("total_message_count_response", (res) => {
        const new_res = decryptData(res)
        if (new_res) {
          $(".messageCountGloble").html(new_res.total_unseen);
        }
      });
    } catch (e) {
      console.log("eeeeeeeeeeeeeeee", e);
    }
  }, [ProductCount]);

  const [productTypeSlug, setProductTypeSlug] = useState(pathname);
  const [search, setSearch] = useState();

  const handleCategoryChange = (event) => {
    setProductTypeSlug(event.target.value);
    // dispatch(getAllProductsFilter(productTypeSlug, { search: search }));
    localStorage.setItem(
      event.target.value + "filter",
      JSON.stringify({ search: search })
    );
    if (pathname == event.target.value && secpathname == "category") {
      dispatch(getAllProductsFilter(pathname, { search: search }, 0));
    } else {
      if (search && search.length > 0) {
        history.push(
          `${url + "/category/" + event.target.value + "?search=" + search}`
        );
      } else {
        history.push(`${url + "/category/" + event.target.value}`);
      }
    }
  };
  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleSearchFilter = (e) => {
    if (e.key === "Enter") {
      localStorage.setItem(
        productTypeSlug + "filter",
        JSON.stringify({ search: search })
      );
      if (pathname == productTypeSlug && secpathname == "category") {
        dispatch(getAllProductsFilter(pathname, { search: search }, 0));
      } else {
        history.push(`${url + "/category/diamonds?search=" + search}`);
      }
    }
  };
  const handleAcendingFilter = (value) => {
    //setFilterDisplayName(displayname)
    $(".sort_by_list").css({
      display: "none",
    });
    context.selectLanguage(value);
    // dispatch(myProductFilterByAscending(category_slug, value, type))
  };

  const handleSelectCountry = (country) => {
    localStorage.removeItem(country);
    localStorage.setItem("country", country);
    setTimeout(() => {
      window.location.reload();
    }, 200);
  };

  useEffect(() => {
    if (showCountryDropdown === true) {
      if ($(".lang_list").css("display") === "block") {
        $(".lang_list").css({
          display: "none",
        });
      }
    }
  }, [showCountryDropdown]);

  const handleLangList = () => {
    setShowCountryDropdown(false);
    if ($(".lang_list").css("display") === "block") {
      $(".lang_list").css({
        display: "none",
      });
    } else {
      $(".lang_list").css({
        display: "block",
      });
    }
  };

  // useEffect(() => {
  //     if (showCountryDropdown === true) {
  //         const handleOutsideClick = (e) => {
  //             const countryDropdown = document.querySelector('.country-dropdown');
  //             if (countryDropdown && !countryDropdown.contains(e.target)) {
  //                 setShowCountryDropdown(false);
  //             }
  //         };
  //         document.addEventListener('click', handleOutsideClick);

  //         return () => {
  //             document.removeEventListener('click', handleOutsideClick);
  //         }
  //     };
  // });

  // $(document).on('click', function (event) {
  //     if (showCountryDropdown === true) {
  //         if ($('.country-dropdown').is(':visible')) {
  //             if (!$(event.target).closest('.country-dropdown').length) {
  //                 setShowCountryDropdown(false);
  //             }
  //         }
  //     }
  // });
  const handleMessage = (e) => {
    e.preventDefault()
    if (user?.is_valid_plan == 1) {
      window.location.href = url + "/message"
    } else {
      alert.error(FEATURE_RESTRICTION.toLocaleLowerCase(), {
        timeout: 200000,
        containerStyle: { textTransform: "initial" }
      })
    }
  }
  return (
    <div className="pre-hader-bg">
      <div
        className="container-fluid"
        style={{ borderBottom: "solid 1px #E7E7E7" }}
      >
        <div className="row py-3 animated fadeInDownShort go">
          <div className="col-xs-12 col-md-5 col-lg-3">
            <div className="logo">
              <Link to={url + "/category/diamonds"} className="hidden-sm">
                <img src={IMAGES.WHITE_LOGO} alt="" />
              </Link>
            </div>
          </div>
          <div className="col-xs-12  col-md-7 col-lg-5">
            <div className="form-group mb-0 size">
              <select
                className="form-control top-cat-dropdown"
                onChange={handleCategoryChange}
                value={productTypeSlug}
              >
                {/* <FormattedMessage id="addsingleproduct.choose" defaultMessage="Choose">
                                            {(id) => <option value="">{id}</option>}
                                        </FormattedMessage> */}
                {category &&
                  category.categories &&
                  category.categories.map((category) => {
                    return (
                      <Fragment>
                        {category && (
                          <FormattedMessage
                            id={"category." + category.slug}
                            defaultMessage={category.name}
                          >
                            {(id) => (
                              <option
                                data-id={category.id}
                                value={category.slug}
                              >
                                {id}
                              </option>
                            )}
                          </FormattedMessage>
                        )}
                      </Fragment>
                    );
                  })}
              </select>
              <input
                type="text"
                className="form-control txt-box top-search"
                placeholder={useIntl().formatMessage({ id: "Search" })}
                onKeyPress={(e) => handleSearchFilter(e)}
                onChange={handleSearch}
              />
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 text-right mt-3 mt-lg-0 nav-right-box">
            {user && user?.role == 2 && user?.is_valid_plan == 1 && (
              <Link
                to={url + "/myproducts/diamonds"}
                className="options op_btn mx-1"
              >
                {" "}
                <img src={IMAGES.BOX_IMG} alt="" />{" "}
                {ProductCount &&
                  "$" +
                  numberWithCommas(
                    parseFloat(ProductCount.productCount).toFixed(0)
                  )}
              </Link>
            )}
            {/* Language Select Dropdown  */}
            <span className="options filter_search_right">
              <div className=" search_cat_top">
                <div className="sort_by_box">
                  <p
                    className="sort_b_h text-right lang_p"
                    onClick={handleLangList}
                  >
                    <b className="mr-2">
                      <i className="fa fa-globe"></i>
                    </b>{" "}
                  </p>
                  <div className="lang_list">
                    {
                      <ul>
                        <li
                          className={context.locale == "tr" && "active"}
                          onClick={() => handleAcendingFilter("tr")}
                        >
                          <i className="fa fa-globe"></i> Turkish
                        </li>
                        <li
                          className={context.locale == "en" && "active"}
                          onClick={() => handleAcendingFilter("en")}
                        >
                          <i className="fa fa-globe"></i> English
                        </li>
                      </ul>
                    }
                  </div>
                </div>
              </div>
            </span>

            {/* Country Select Dropdown  */}
            <span className="options filter_search_right">
              <div className=" search_cat_top">
                <div className="sort_by_box">
                  <p
                    className="sort_b_h text-right lang_p"
                    onClick={() =>
                      setShowCountryDropdown(
                        showCountryDropdown === true ? false : true
                      )
                    }
                  >
                    <b className="mr-2">
                      <i>
                        <img
                          src={
                            localStorage.getItem("country") === "turkey"
                              ? TurkeyFlagPng
                              : UKFlapPng
                          }
                          alt="Country Flag"
                          width={"25px"}
                        />
                      </i>
                    </b>{" "}
                  </p>
                  <div
                    className={`country_list ${showCountryDropdown === false && "d-none"
                      }`}
                  >
                    {
                      <ul style={{ textAlign: "left" }}>
                        {/* <li className={localStorage.getItem("country") === 'turkey' && 'active'} onClick={() => handleSelectCountry('turkey')}><i className="fa fa-globe"></i> Turkey</li>
                                                <li className={localStorage.getItem("country") === 'uk' && 'active'} onClick={() => handleSelectCountry('uk')} ><i className="fa fa-globe"></i>&nbsp; UK</li> */}

                        <li
                          className={`country-dropdown ${localStorage.getItem("country") === "turkey" &&
                            "active"
                            }`}
                          onClick={() => handleSelectCountry("turkey")}
                        >
                          <i className="fa fa-globe"></i> Turkey
                        </li>
                        <li
                          className={`country-dropdown ${localStorage.getItem("country") === "uk" && "active"
                            }`}
                          onClick={() => handleSelectCountry("uk")}
                        >
                          <i className="fa fa-globe"></i>&nbsp; UK
                        </li>
                      </ul>
                    }
                  </div>
                </div>
              </div>
            </span>
            <span className="options ml-1">
              <a href="javascript:void(0)" onClick={(e) => handleMessage(e)}>
                <img src={IMAGES.MESSAGE_IMG} alt="" />
              </a>
              <span className="messageCountGloble">
                {ProductCount && ProductCount?.chatCount}
              </span>
            </span>
            <span className="options mx-1">
              <Link to={url + "/favorites/diamonds"}>
                <img src={IMAGES.HEART_IMG} alt="" />
              </Link>
              <span>{ProductCount && ProductCount.favCount}</span>
            </span>
            {/* <Link to={url + "/profile"}><img src={IMAGES.PROFILE_IMG} className="u-img" alt="" /></Link> */}
            <span className="dropdown">
              <span
                className="dropdown-toggle"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                style={{ cursor: 'pointer' }}
              >
                <Avatar
                  round={true}
                  color={"#003B45"}
                  size={36}
                  name={getProfile?.user_business?.company_name ? getProfile?.user_business?.company_name : IMAGES?.PROFILE_IMG}
                  src={
                    user?.profile_picture_full_thumb ?
                      user?.profile_picture_full_thumb :
                      IMAGES?.PROFILE1
                  }
                  className="u-img"
                />
              </span>
              <span
                className="dropdown-menu profile-drop-menu p-2"
                aria-labelledby="dropdownMenuButton"
              >
                <Link
                  className="dropdown-item font-weight-bold"
                  to={url + "/profile"}
                >
                  <FormattedMessage
                    id="main.header.myprofile"
                    defaultMessage="Profile"
                  />
                </Link>
                <span
                  className="dropdown-item font-weight-bold"
                  onClick={handleLogout}
                >
                  <FormattedMessage
                    id="main.header.logout"
                    defaultMessage="Logout"
                  />
                </span>
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProtectedHeader;
