import React, { useState } from 'react';
import { IntlProvider } from 'react-intl';
import French from '../lang/fr.json';
import Arabic from '../lang/ar.json';
import English from '../lang/en.json';
import Turkish from '../lang/tr.json';

export const Context = React.createContext();

if (localStorage.getItem('lang') == null) {
    var local = navigator.language;
}else {
    local = localStorage.getItem('lang');
}


let lang;
if (local === 'ar') {
    lang = Arabic;
} else if (local === 'fr') {
    lang = French;
} else if (local === 'en') {
    lang = English;
}
else {
    lang = Turkish;
}

const Wrapper = (props) => {
    const [locale, setLocale] = useState(local);

    const [messages, setMessages] = useState(lang);

    function selectLanguage(value) {
        const newLocale =value;
        localStorage.setItem('lang', newLocale);
        window.location.reload();
        setLocale(newLocale);
        if (newLocale === 'en') {
            setMessages(English);
        } else if (newLocale === 'fr') {
                setMessages(French);
            } else if(newLocale === 'tr'){
                    setMessages(Turkish);
                }
             else {
                setMessages(Arabic);
            }
        }

    return (
        <Context.Provider value={{ locale, selectLanguage }}>
            <IntlProvider messages={messages} locale={locale}>
                {props.children}
            </IntlProvider>
        </Context.Provider>
    );
}

export default Wrapper;