import validator from 'validator';
import { FormattedMessage } from 'react-intl';


class ValidateFields {

  validateEmail(email) {
    if (validator.isEmpty(email)) {
      return 'Email is required';
    } else if (!validator.isEmail(email)) {
      return 'Invalid Email';
    }
    return false;
  }

  validatePassword(password) {
    if (validator.isEmpty(password)) {
      return 'Password is required';
    } else if (!validator.isLength(password, { min: 8 })) {
      return 'Password should be minimum 8 characters';
    }
    return false;
  }

  validateName(name) {
    if (validator.isEmpty(name)) {
      return 'Name is required';
    } 
    // else if (!validator.isAlpha(name)) {
    //   return 'Name must be alphabetic';
    // }
    return false;
  }

  validateConfirmPassword(password, confirmPassword) {
    if (validator.isEmpty(confirmPassword)) {
      return 'Confirm Password is required';
    } else if (!validator.isLength(confirmPassword, { min: 8 })) {
      return 'Confirm Password should be minimum 8 characters';
    } else if (password !== confirmPassword) {
      return 'Password must be match';
    }
    return false;
  }

  validatePlan(plan) {
    if (!plan) {
      return 'Plan is required';
    }
    return false;
  }

  validatePurpose(purpose) {
    if (!purpose) {
      return 'Purpose is required';
    }
    return false;
  }

  validateMessage(message) {
    if (!message) {
      return 'Message is required';
    }
    return false;
  }

  validateSubscription(subscription) {
    if (!subscription) {
      return 'please select plan';
    }
    return false;
  }

  validateCvv(cvv) {
    if (!cvv) {
      return 'CVV is required';
    }
    return false;
  }

  validateTerm(check) {
    if (!check) {
      return 'Term & Conditions is required';
    }
    return false;
  }

  validateMonth(month) {
    if (!month) {
      return 'Month is required';
    }
    return false;
  }

  validateYear(year) {
    if (!year) {
      return 'Year is required';
    }
    return false;
  }

  validateCardNumber(num) {
    if (!num) {
      return 'Card Number is required';
    } else if (!validator.isLength(num, { min: 4 })) {
      return 'Card Number must be 4 length'
    }
    return false;
  }

  validateStock_Number(stock_number){
    if(!stock_number)
    {
      return <FormattedMessage id="error.stock_number" defaultMessage="stock number is required"/>
    }
    return false
  }  
  validateShape(shape){
    if(!shape)
    {
      return <FormattedMessage id="error.shape" defaultMessage="shape is required"/>
    }
    return false
  }  
  validateCarat(carat){
    if(!carat)
    {
      return <FormattedMessage id="error.carat" defaultMessage="carat is required"/>
    }
    const regex = /^\d*\.?\d*$/; 
    if (!regex.test(carat)) {
      return <FormattedMessage id="error.numeric" defaultMessage="Only numeric digits and  period is allowed"/>
    }
    return false
  } 

  validateTablePer(table_per){
    if(table_per)
    {
      const regex = /^\d*\.?\d*$/; 
      if (!regex.test(table_per)) {
        return <FormattedMessage id="error.numeric" defaultMessage="Only numeric digits and  period is allowed"/>
      }
    }
    return false
  } 

  validateDepthPer(depth_per){
    if(depth_per)
    {
      const regex = /^\d*\.?\d*$/; 
      if (!regex.test(depth_per)) {
        return <FormattedMessage id="error.numeric" defaultMessage="Only numeric digits and  period is allowed"/>
      }
    }
    return false
  }
  validateMm_l(mm_l){
    if(mm_l)
    {
      const regex = /^\d*\.?\d*$/; 
      if (!regex.test(mm_l)) {
        return <FormattedMessage id="error.numeric" defaultMessage="Only numeric digits and  period is allowed"/>
      }
    }
    return false
  }
  validateMn_h(mm_h){
    if(mm_h)
    {
      const regex = /^\d*\.?\d*$/; 
      if (!regex.test(mm_h)) {
        return <FormattedMessage id="error.numeric" defaultMessage="Only numeric digits and  period is allowed"/>
      }
    }
    return false
  }

  validateMm_w(mm_w){
    if(mm_w)
    {
      const regex = /^\d*\.?\d*$/; 
      if (!regex.test(mm_w)) {
        return <FormattedMessage id="error.numeric" defaultMessage="Only numeric digits and  period is allowed"/>
      }
    }
    return false
  }

   validateColor(color){
    if(!color)
    {
      return <FormattedMessage id="error.color" defaultMessage="color is required"/>
    }
    return false
  }  

  validateClarity(clarity){
    if(!clarity)
    {
      return <FormattedMessage id="error.clarity" defaultMessage="clarity is required"/>
    }
    return false
  }  

  validateMeasurement(measurement){
    if(!measurement)
    {
      return <FormattedMessage id="error.measurement" defaultMessage="measurement is required"/>
    }
    return false
  }  
  validateCertificate(Certificate){
    if(!Certificate)
    {
      return <FormattedMessage id="error.certificate" defaultMessage="Certificate is required"/>
    }
    return false
  }  
  validatePrice(Price){
    if(!Price)
    {
      return <FormattedMessage id="error.price" defaultMessage="Price/ct is required"/>
    }
    const regex = /^\d*\.?\d*$/; 
    if (!regex.test(Price)) {
      return <FormattedMessage id="error.numeric" defaultMessage="Only numeric digits and  period is allowed"/>
    }
    return false
  }  
  validateDiscount(Discount){
    if(!Discount)
    {
      return <FormattedMessage id="error.discount" defaultMessage="Discount is required"/>
    }
    const regex = /^\d*\.?\d*$/; 
    if (!regex.test(Discount)) {
      return <FormattedMessage id="error.numeric" defaultMessage="Only numeric digits and  period is allowed"/>

    }
    return false
  }

  validateBrand(brand){
    if(!brand)
    {
      return   <FormattedMessage id="error.brand" defaultMessage="Brand is required"/>
    }
    return false
  }

  validateModel(model){
    if(!model)
    {
      return `Model is required`
    }
    return false
  }

  
  validateStrapColor(color){
    if(!color)
    {
      return `strap color is required`
    }
    return false
  }

  validateCaseSize(casestrap){
    if(!casestrap)
    {
      return `Case Size is required`
    }
    return false
  }

  validatePapers(Paper){
    if(!Paper)
    {
      return `Papers and Box is required`
    }
    return false
  }

  validateCondition(condition){
    if(!condition)
    {
      return `condition is required`
    }
    return false
  }

  
  validateGender(gender){
    if(!gender)
    {
      return `Gender is required`
    }
    return false
  }

  validateMovement(movement){
    if(!movement)
    {
      return `Movement is required`
    }
    return false
  }

  validateFunction(functions){
    if(!functions)
    {
      return `Functions is required`
    }
    return false
  }

  validateDialNumeral(dial){
    if(!dial)
    {
      return `Dial Numerals is required`
    }
    return false
  }

  validateDialType(dial){
    if(!dial)
    {
      return `Dial Type is required`
    }
    return false
  }

  validateCaseMaterial(dial){
    if(!dial)
    {
      return `Case Material is required`
    }
    return false
  }

  validateBezelMaterial(dial){
    if(!dial)
    {
      return `Bezel Material is required`
    }
    return false
  }


  validateStrapMaterial(dial){
    if(!dial)
    {
      return `Strap Material is required`
    }
    return false
  }

  validateProductType(type){
    if(!type)
    {
      return `Product Type is required`
    }
    return false
  }

  
  validateExcel(excel){
    console.log("excel::::::;", excel);
    
    if(!excel)
    {
      return `Excel file is required`
    }
    return false
  }

  validateStyle(style){
    if(!style)
    {
      return 'Style is required'
    }
    return false
  }

  validateType(type){
    if(!type)
    {
      return 'Type is required'
    }
    return false
  }

  validateMetalType(mtype){
    if(!mtype)
    {
      return 'Metal Type is required'
    }
    return false
  }

  validateMetalWeight(mweight){
    if(!mweight)
    {
      return 'Gold Weight is required'
    }
    return false
  }

  validateMinOrder(minorder){
    if(!minorder)
    {
      return 'Min Order is required'
    }
    return false
  }


  validateStoneCarat(stonecarat){
    if(!stonecarat)
    {
      return 'Stone Carat is required'
    }
    return false
  }


  validateStoneShape(stoneShape){
    if(!stoneShape)
    {
      return 'Stone Shape is required'
    }
    return false
  }


  validateStoneType(stonetype){
    if(!stonetype)
    {
      return 'Stone Type is required'
    }
    return false
  }

  validateGoldKarat(gold) {
    if(!gold)
    {
      return 'Gold Karat is required'
    }
    return false
  }

  validateStoneColor(stonecolor){
    if(!stonecolor)
    {
      return 'Stone color is required'
    }
    return false
  }

  validateThickness(thickness){
    if(!thickness)
    {
      return 'Thickenss is required'
    }
    return false
  }

  validateWideness(wideness){
    if(!wideness)
    {
      return 'Wideness is required'
    }
    return false
  }

  validateFinish(finish){
    if(!finish)
    {
      return 'Finish is required'
    }
    return false
  }

  validateFancyColor(fancy_color){
    if(!fancy_color)
    {
      return   <FormattedMessage id="error.fancy_color" defaultMessage="Fancy Color is required"/>
    }
    return false
  }

  validateFancyColorIntensity(intensity){
    if(!intensity)
    {
      return   <FormattedMessage id="error.intensity" defaultMessage='Intensity is required'/>

      return 
    }
    return false
  }

  validateFancyColorOvertone(overtone){
    if(!overtone)
    {
      return   <FormattedMessage id="error.overtone" defaultMessage="Overtone is required"/>
    }
    return false
  }


  validateOtp(otp){
    if(!otp)
    {
      return 'otp is required'
    }
    return false
  }


  validateSieve(sieve){
    if(!sieve)
    {
      return 'sieve is required'
    }
    return false
  }

  
  validateRating(rating){
    if(!rating)
    {
      return 'rating is required'
    }
    return false
  }

  validateCompanyName(company_name){
    if(!company_name)
    {
      return 'company name is required'
    }
    return false
  }

  validateAddress(address){
    if(!address)
    {
      return 'address is required'
    }
    return false
  }

  validateBusinessPhone(business_phone){
    if(!business_phone)
    {
      return 'business phone is required'
    }
    return false
  }

  validateCountry(country){
    if(!country)
    {
      return 'country is required'
    }
    return false
  }

  validateState(state){
    if(!state)
    {
      return 'state is required'
    }
    return false
  }

  validateCity(city){
    if(!city)
    {
      return 'city is required'
    }
    return false
  }


  validateComment(comment){
    if(!comment)
    {
      return <FormattedMessage id="error.comment" defaultMessage="comment is required"/>
    }
    return false
  }


  validateHeading(heading){
    if(!heading)
    {
      return  <FormattedMessage id="error.heading" defaultMessage="heading is required"/>
    }
    return false
  }

  validateAvailabilityStatus(availability_status){
    if(!availability_status)
    {
      return   <FormattedMessage id="error.availability_status" defaultMessage="Status is required"/>
    }
    return false
  }

  validateMilky(milky){
    if(!milky)
    {
      return  <FormattedMessage id="error.milky" defaultMessage="Milky is required"/>
    }
    return false
  }

  validatebgm(bgm){
    if(!bgm)
    {
      return <FormattedMessage id="error.bgm" defaultMessage="BGM is required"/>
    }
    return false
  }


  validateAny(value, fieldName=null){
    if(!value)
    {
      if(fieldName){
        return fieldName+' is required'
      }
      return 'This field is required'
    }
    return false
  }

  


}

const validateFields = new ValidateFields();

export { validateFields };
