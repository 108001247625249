import React, { Fragment, useState, useEffect } from "react";
import { IMAGES } from "../../../constants";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router";
import { useAlert } from "react-alert";
import {
  getAttributes,
  addProduct,
  GetProductCount,
  clearMessage,
} from "../../../actions/productActions";
import { validateFields } from "../../../validation";
import classnames from "classnames";
import { FormattedMessage, useIntl } from "react-intl";
import Loader from "../../layout/Loader";
import $ from "jquery";
import PopupSucess from "../../layout/PopupSucess";
import { getCategory } from "../../../actions/categoryAction";
import { url } from "../../../config";
import { Link } from "react-router-dom";
import { ExtentionSupport } from "../../../utils/json";

const queryString = require("query-string");
const addButtonImg = require("../../../assets/images/plus.png");
const removeButtonImg = require("../../../assets/images/minus.png");

const AddDiamonds = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const image1 = [];
  const video1 = {};
  const location = useLocation();
  const [images, setImages] = useState([]);
  const [video, setVideo] = useState(null);
  const [add3DLinkInput, setAdd3DLinkInput] = useState([{ id: 1, value: "" }]);
  const [checkFancy, setCheckFancy] = useState(false);

  const parsed = queryString.parse(location.search);
  const { attributes } = useSelector((state) => state.attributes);

  useEffect(() => {
    dispatch(getAttributes(parsed && parsed.cat));
  }, [dispatch]);

  const [addproduct, setAddProduct] = useState({
    category_slug: parsed && parsed.cat,
    shape: "",
    carat: "",
    color: "",
    clarity: "",
    cut: "",
    polish: "",
    symmetry: "",
    milky: "",
    availability_status: "",
    bgm: "",
    fluorescence: "",
    table_per: "",
    depth_per: "",
    mm_h: "",
    mm_l: "",
    mm_w: "",
    culet: "",
    certificate: "",
    certificate_number: "",
    discount: "",
    comment: "",
    price: "",
    stock_number: "",
    images: "",
    diamond_3d_link: "",
    fancy_color: "",
    fancy_intensity: "",
    fancy_overtone: "",
  });
  const [validerror, setValidError] = useState({
    stock_numberError: "",
    shapeError: "",
    caratError: "",
    colorError: "",
    clarityError: "",
    certificateError: "",
    milkyError: "",
    StatusError: "",
    priceError: "",
    depth_perError: "",
    mm_l_Error: "",
    mm_h_Error: "",
    mm_w_Error: "",
    table_perError: "",
  });

  const handleInputChange = (event) => {
    setValidError({
      stock_numberError: "",
      shapeError: "",
      caratError: "",
      colorError: "",
      clarityError: "",
      certificateError: "",
      milkyError: "",
      StatusError: "",
      priceError: "",
      depth_perError: "",
      mm_l_Error: "",
      mm_h_Error: "",
      mm_w_Error: "",
      table_perError: "",
    });
    if (event.target.name == "discount") {
      if (event.target.value != "") {
        setAddProduct({
          ...addproduct,
          discount: event.target.value,
          price: "",
        });
        $(".price").attr("readonly", "readonly");
      } else {
        $(".price").removeAttr("readonly");
      }
    }
    if(event.target.name == "mm_l"){
        setAddProduct({ ...addproduct, [event.target.name]: Number(event?.target?.value)?.toFixed(2) });
    }
    if(event.target.name == "mm_w"){
        setAddProduct({ ...addproduct, [event.target.name]: Number(event?.target?.value)?.toFixed(2) });
    }
    if(event.target.name == "mm_h"){
        setAddProduct({ ...addproduct, [event.target.name]: Number(event?.target?.value)?.toFixed(2) });
    }
    if (event.target.name == "price") {
      if (event.target.value != "") {
        if (checkFancy) {
          setAddProduct({
            ...addproduct,
            price: event.target.value,
            discount: 0,
          });
        } else {
          setAddProduct({
            ...addproduct,
            price: event.target.value,
            discount: "",
          });
        }
        $(".discount").attr("readonly", "readonly");
      } else {
        $(".discount").removeAttr("readonly");
      }
    } else {
      setAddProduct({ ...addproduct, [event.target.name]: event.target.value });
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    const stock_numberError = validateFields.validateStock_Number(
      addproduct.stock_number
    );
    const shapeError = validateFields.validateShape(addproduct.shape);
    const caratError = validateFields.validateCarat(addproduct.carat);
    const clarityError = validateFields.validateClarity(addproduct.clarity);
    const certificateError = validateFields.validateCertificate(
      addproduct.certificate
    );
    const milkyError = checkFancy
      ? false
      : validateFields.validateMilky(addproduct.milky);
    const StatusError = validateFields.validateAvailabilityStatus(
      addproduct.availability_status
    );
    var priceError = false;
    var mm_l_Error = false;
    var mm_h_Error = false;
    var mm_w_Error = false;
    var table_perError = false;
    var depth_perError = false;

    if (addproduct.mm_l) {
      mm_l_Error = validateFields.validateMm_l(addproduct.mm_l);
    }
    if (addproduct.mm_h) {
      mm_h_Error = validateFields.validateMn_h(addproduct.mm_h);
    }
    if (addproduct.mm_w) {
      mm_w_Error = validateFields.validateMm_w(addproduct.mm_w);
    }
    if (addproduct.table_per) {
      table_perError = validateFields.validateTablePer(addproduct.table_per);
    }
    if (addproduct.depth_per) {
      depth_perError = validateFields.validateDepthPer(addproduct.depth_per);
    }
    if (!addproduct.discount) {
      priceError = validateFields.validatePrice(addproduct.price);
    }

    const diamond_color = $("#diamond_color").val();
    if (
      [
        stock_numberError,
        shapeError,
        caratError,
        clarityError,
        certificateError,
        priceError,
        StatusError,
        depth_perError,
        table_perError,
        mm_w_Error,
        mm_l_Error,
        mm_h_Error,
        milkyError,
      ].every((e) => e === false)
    ) {
      setValidError({
        ...validerror,
        stock_numberError,
        shapeError,
        caratError,
        clarityError,
        certificateError,
        StatusError,
        priceError,
        depth_perError,
        table_perError,
        mm_w_Error,
        mm_l_Error,
        mm_h_Error,
        milkyError,
      });
      // if (diamond_color === '0') {
      //     setValidError({
      //         ...validerror, milkyError
      //     })
      // }
      // const updatedInputs = add3DLinkInput
      //     .filter((data) => data?.value !== "")
      //     .map((input) => ({ ...input, value: "" }));

      // setAdd3DLinkInput(updatedInputs);
      // console.log(addproduct);

      await dispatch(addProduct(addproduct));
      dispatch(GetProductCount());
      dispatch(getCategory());
    } else {
      if (diamond_color === "0") {
        setValidError({
          ...validerror,
          stock_numberError,
          shapeError,
          caratError,
          clarityError,
          certificateError,
          priceError,
          StatusError,
          milkyError,
          depth_perError,
          table_perError,
          mm_w_Error,
          mm_l_Error,
          mm_h_Error,
        });
      } else {
        setValidError({
          ...validerror,
          stock_numberError,
          shapeError,
          caratError,
          clarityError,
          certificateError,
          priceError,
          StatusError,
          depth_perError,
          table_perError,
          mm_w_Error,
          mm_l_Error,
          mm_h_Error,
        });
      }
    }
    return;
  };

  const { status, data, message, error, loading } = useSelector(
    (state) => state.productAdd
  );

  useEffect(() => {
    if (data) {
      // alert.success(message);
      $("#popup-click-sucess").trigger("click");
      dispatch(clearMessage());
      setAddProduct({
        category_slug: parsed && parsed.cat,
        shape: "",
        carat: "",
        color: "",
        clarity: "",
        cut: "",
        polish: "",
        symmetry: "",
        fluorescence: "",
        milky: "",
        availability_status: "",
        bgm: "",
        table_per: "",
        depth_per: "",
        mm_h: "",
        mm_l: "",
        mm_w: "",
        certificate: "",
        certificate_number: "",
        discount: "",
        comment: "",
        price: "",
        diamond_3d_link: "",
        stock_number: "",
        images: "",
        fancy_color: "",
        fancy_intensity: "",
        fancy_overtone: "",
      });
      setValidError({
        stock_numberError: "",
        shapeError: "",
        caratError: "",
        colorError: "",
        clarityError: "",
        certificateError: "",
        milkyError: "",
        StatusError: "",
        priceError: "",
        depth_perError: "",
        mm_l_Error: "",
        mm_h_Error: "",
        mm_w_Error: "",
        table_perError: "",
      });
      setVideo(null);
      // $('.videoUploadDiv').css('display', 'block');
      // $('.videoEdits').css('display', 'none');
      $("#video_upload").val("");
      setImages("");
      setTimeout(() => {
        window.location = url + "/myproducts/diamonds";
      }, 3000);
    }
    if (error) {
      alert.error(error);
    }
  }, [alert, error, message, data, addproduct]);

  //images functionality

  const convertArrayToFileList = (filesArray) => {
    const dataTransfer = new DataTransfer();
    filesArray?.forEach((fileObj) => dataTransfer.items.add(fileObj?.file));
    return dataTransfer.files;
  };

  useEffect(() => {
    if (images.length > 0) {
      setAddProduct({ ...addproduct, images: convertArrayToFileList(images) });
    }
  }, [images]);

  const image_select = (e) => {
    // Exclude unsupported document
    let ext = [];
    let image = e?.target?.files;

    const maxSize = 10 * 1024 * 1024; // 10MB in bytes

    for (let i = 0; i < image?.length; i++) {
      ext.push(image[i]?.name.split(".").pop());
    }

    let tempImgArr = [];
    let remaining_space = 5 - images?.length;
    let max_len =
      image?.length > remaining_space ? remaining_space : image?.length;

    for (let i = 0; i < max_len; i++) {
      let ext = image[i]?.name.split(".").pop();

      // Check for valid extension
      if (!ExtentionSupport.includes(ext)) {
        alert.error(image[i].name + " has an unsupported file format.");
        continue;
      }

      if (image[i].size > maxSize) {
        alert.error(image[i].name + " exceeds the 10MB size limit");
        continue;
      }

      if (check_duplicate(image[i].name)) {
        tempImgArr.push({
          name: image[i].name,
          url: URL.createObjectURL(image[i]),
          file: image[i],
          size: image[i].size,
        });
      } else {
        alert.error(image[i].name + " is already added to the list");
      }
    }
    setImages((prevImg) => [...prevImg, ...tempImgArr]);
  };

  const video_select = (e) => {
    const ext = e?.target?.files[0]?.name.split(".").pop();
    if (ext == "mp4") {
      // var video = document.getElementById('video_upload').files;
      let video = e?.target?.files;
      let videoUp = e?.target?.files[0];
      setAddProduct({ ...addproduct, video: videoUp });
      if (videoUp) {
        video1.name = video[0].name;
        video1.url = URL.createObjectURL(video[0]);
        video1.file = video[0];
        video1.size = video[0].size;
        setVideo(video1);
        var videor = document.getElementById("video");
        var source = document.createElement("source");
        source.setAttribute("src", video1.url);
        source.setAttribute("type", "video/mp4");
        videor?.load();
      }
    } else {
      alert.error("Please upload supported file format");
    }
  };

  const check_duplicate = (name) => {
    let image = true;
    if (images && images.length > 0) {
      for (let e = 0; e < images.length; e++) {
        if (images[e].name == name) {
          image = false;
          break;
        }
      }
    }
    return image;
  };

  const delete_image = (index) => {
    setImages(images.filter((x, i) => i !== index));
    const dt = new DataTransfer();
    for (let file of addproduct.images) {
      if (file !== addproduct.images[index]) dt.items.add(file);
    }
    setAddProduct({ ...addproduct, images: dt.files });
  };

  const delete_video = () => {
    setVideo(null);
    $("#video_upload").val("");
    setAddProduct({ ...addproduct, video: null });
  };

  const handleSwitchColor = (e) => {
    setCheckFancy(e.target.checked);
    if (e.target.checked) {
      $("#fancy_color").css({ display: "block" });
      $("#fancy_color_intensity").css({ display: "block" });
      $("#fancy_color_overtone").css({ display: "block" });

      $("#color").css({ display: "none" });
      $("#color").val("");
      setAddProduct((prevState) => ({ ...prevState, color: "" }));

      $("#white_diamond_milky").css({ display: "none" });
      console.log("jfklahdfkla hfere");
      setAddProduct((prevState) => ({ ...prevState, milky: "" }));

      $(".white_diamond_bgm").css({ display: "none" });
      $("#white_diamond_bgm").val("");
      setAddProduct({ ...addproduct, bgm: "" });

      $("#diamond_color").val("1");
    } else {
      $("#fancy_color").css({ display: "none" });
      $("#fancy_color").val("");

      $("#fancy_color_intensity").css({ display: "none" });
      $("#fancy_color_intensity").val("");

      $("#fancy_color_overtone").css({ display: "none" });
      $("#fancy_color_overtone").val("");

      $("#color").css({ display: "block" });
      $("#discount").css({ display: "block" });
      $("#white_diamond_milky").css({ display: "block" });
      $(".white_diamond_bgm").css({ display: "block" });
      $("#diamond_color").val("0");
    }
  };

  $("#diamond_color").val("0");
  const bgmCheckbox = document.getElementById("bgmCheckbox");
  if (bgmCheckbox) {
    bgmCheckbox.addEventListener("change", function () {
      if (this.checked) {
        addproduct.bgm = "1";
      } else {
        addproduct.bgm = "0";
      }
    });
  }
  const intl = useIntl();
  // const handleAddMoreLinks = () => {
  //     const newInput = { id: add3DLinkInput.length + 1, value: "" };
  //     setAdd3DLinkInput([...add3DLinkInput, newInput]);
  // };

  // const handleSetProduct3DLinks = (e, id) => {
  //     const updatedInputs = add3DLinkInput.map((data) => {
  //         if (data.id === id) {
  //             return { ...data, value: e.target.value };
  //         }
  //         return data;
  //     });
  //     setAdd3DLinkInput(updatedInputs);
  // };

  // const handleSubmitLinks = () => {
  //     const updatedInputs = add3DLinkInput.filter((data) => data?.value !== "");
  //     setAdd3DLinkInput(updatedInputs);
  // }

  // const handleRemoveProduct3DLinks = (id) => {
  //     const updatedInputs = add3DLinkInput.filter((data) => data.id !== id);
  //     setAdd3DLinkInput(updatedInputs);
  // }

  const stk_number = intl.formatMessage({
    id: "Enter Stock No",
    defaultMessage: "Enter Stock No.",
  });
  const crt = intl.formatMessage({
    id: "Enter Carat",
    defaultMessage: "Enter Carat",
  });

  console.log("addproduct::>>", addproduct);

  return (
    <Fragment>
      {loading && <Loader />}
      <Fragment>
        <h3 className="forms">
          <FormattedMessage
            id="addsingleproduct.add-a-single"
            defaultMessage="Add a Single"
          />
          <span>{` `}</span>
          <FormattedMessage
            id="addsingleproduct.diamond"
            defaultMessage="Diamond"
          />
        </h3>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-lg-4">
              <div className="form-group">
                <label htmlFor="">
                  <FormattedMessage
                    id="addsingleproduct.stockno"
                    defaultMessage="Stock No."
                  />
                  <span className="strike_red">*</span>
                </label>
                <input
                  className={classnames(
                    "form-control",
                    { "is-valid": validerror.stock_numberError === false },
                    { "is-invalid": validerror.stock_numberError }
                  )}
                  type="text"
                  placeholder={stk_number}
                  onChange={handleInputChange}
                  name="stock_number"
                  value={addproduct.stock_number}
                />
                <p className="invalid-feedback">
                  {validerror.stock_numberError}
                </p>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="form-group">
                <label htmlFor="">
                  <FormattedMessage
                    id="addsingleproduct.carat"
                    defaultMessage="Carat"
                  />
                  <span className="strike_red">*</span>
                </label>
                <input
                  type="text"
                  className={classnames(
                    "form-control",
                    { "is-valid": validerror.caratError === false },
                    { "is-invalid": validerror.caratError }
                  )}
                  placeholder={crt}
                  name="carat"
                  onChange={handleInputChange}
                  value={addproduct.carat}
                />
                <p className="invalid-feedback">{validerror.caratError}</p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4">
              <div className="form-group">
                <label htmlFor="">
                  <FormattedMessage
                    id="addsingleproduct.shape"
                    defaultMessage="Shape"
                  />
                  <span className="strike_red">*</span>
                </label>
                <select
                  className={classnames(
                    "form-control",
                    { "is-valid": validerror.shapeError === false },
                    { "is-invalid": validerror.shapeError }
                  )}
                  onChange={handleInputChange}
                  name="shape"
                  value={addproduct.shape}
                >
                  <FormattedMessage
                    id="addsingleproduct.chooseshape"
                    defaultMessage="Choose"
                  >
                    {(id) => <option value="">{id}</option>}
                  </FormattedMessage>
                  {attributes &&
                    attributes.shape &&
                    attributes.shape.map((shape) => {
                      return <option value={shape.id}>{shape.name}</option>;
                    })}
                </select>
                <p className="invalid-feedback">{validerror.shapeError}</p>
              </div>
            </div>
            <div className="filter_top switch_color">
              <div className="cus_switch d-flex align-item-center">
                <p className="font-14 fm black-3B pr-3">
                  <FormattedMessage
                    id="addsingleproduct.White"
                    defaultMessage="White"
                  />
                </p>
                <label className="switch">
                  <input
                    type="checkbox"
                    onChange={handleSwitchColor}
                    id="diamond_color"
                  />
                  <span className="slider round"></span>
                </label>
                <p className="gray-75 font-14 fm pl-3">
                  {" "}
                  <FormattedMessage
                    id="addsingleproduct.Fancy"
                    defaultMessage="Fancy"
                  />
                </p>
              </div>
            </div>
            <div id="color" className="col-lg-4">
              <div className="form-group">
                <label htmlFor="">
                  <FormattedMessage
                    id="addsingleproduct.color"
                    defaultMessage="Color"
                  />
                  <span className="strike_red">*</span>
                </label>
                <select
                  className={classnames(
                    "form-control",
                    { "is-valid": validerror.colorError === false },
                    { "is-invalid": validerror.colorError }
                  )}
                  onChange={handleInputChange}
                  name="color"
                  value={addproduct?.color}
                >
                  <FormattedMessage
                    id="addsingleproduct.choosecolor"
                    defaultMessage="Choose"
                  >
                    {(id) => <option value="">{id}</option>}
                  </FormattedMessage>
                  {attributes &&
                    attributes.color &&
                    attributes.color.map((color) => {
                      return <option value={color.id}>{color.name}</option>;
                    })}
                </select>
                <p className="invalid-feedback">{validerror.colorError}</p>
              </div>
            </div>

            <div
              id="fancy_color"
              style={{ display: "none" }}
              className="col-lg-4"
            >
              <div className="form-group">
                <label htmlFor="">
                  <FormattedMessage
                    id="addsingleproduct.fancycolor"
                    defaultMessage="Fancy Color"
                  />
                </label>
                <select
                  className="form-control"
                  onChange={handleInputChange}
                  name="fancy_color"
                  value={addproduct?.fancy_color}
                >
                  <FormattedMessage
                    id="addsingleproduct.choosefancycolor"
                    defaultMessage="Choose"
                  >
                    {(id) => <option value="">{id}</option>}
                  </FormattedMessage>
                  {attributes &&
                    attributes?.fancy_color &&
                    attributes?.fancy_color.map((fancy_color) => {
                      return (
                        <option value={fancy_color.id}>
                          {fancy_color.name}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4"></div>
          </div>

          <div className="row"></div>

          <div className="row">
            <div
              className="col-lg-4"
              id="fancy_color_intensity"
              style={{ display: "none" }}
            >
              <div className="form-group">
                <label htmlFor="">
                  <FormattedMessage
                    id="addsingleproduct.fancycolorintensity"
                    defaultMessage="Fancy Intensity"
                  />
                </label>
                <select
                  className="form-control"
                  onChange={handleInputChange}
                  name="fancy_intensity"
                  value={addproduct.fancy_intensity}
                >
                  <FormattedMessage
                    id="addsingleproduct.choosefancycolorintensity"
                    defaultMessage="Choose"
                  >
                    {(id) => <option value="">{id}</option>}
                  </FormattedMessage>
                  {attributes &&
                    attributes.fancy_intensity &&
                    attributes.fancy_intensity.map((fancy_intensity) => {
                      return (
                        <option value={fancy_intensity.id}>
                          {fancy_intensity.name}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>

            <div
              id="fancy_color_overtone"
              style={{ display: "none" }}
              className="col-lg-4"
            >
              <div className="form-group">
                <label htmlFor="">
                  <FormattedMessage
                    id="addsingleproduct.fancycolorovertone"
                    defaultMessage="Fancy Overtone"
                  />
                </label>
                <select
                  className="form-control"
                  onChange={handleInputChange}
                  name="fancy_overtone"
                  value={addproduct.fancy_overtone}
                >
                  <FormattedMessage
                    id="addsingleproduct.choosefancycolorovertone"
                    defaultMessage="Choose"
                  >
                    {(id) => <option value="">{id}</option>}
                  </FormattedMessage>
                  {attributes &&
                    attributes.fancy_overtone &&
                    attributes.fancy_overtone.map((fancy_overtone) => {
                      return (
                        <option value={fancy_overtone.id}>
                          {fancy_overtone.name}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4">
              <div className="form-group">
                <label htmlFor="">
                  <FormattedMessage
                    id="addsingleproduct.clarity"
                    defaultMessage="Clarity"
                  />
                  <span className="strike_red">*</span>
                </label>
                <select
                  className={classnames(
                    "form-control",
                    { "is-valid": validerror.clarityError === false },
                    { "is-invalid": validerror.clarityError }
                  )}
                  onChange={handleInputChange}
                  name="clarity"
                  value={addproduct.clarity}
                >
                  <FormattedMessage
                    id="addsingleproduct.chooseclarity"
                    defaultMessage="Choose"
                  >
                    {(id) => <option value="">{id}</option>}
                  </FormattedMessage>
                  {attributes &&
                    attributes.clarity &&
                    attributes.clarity.map((clarity) => {
                      return <option value={clarity.id}>{clarity.name}</option>;
                    })}
                </select>
                <p className="invalid-feedback">{validerror.clarityError}</p>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="form-group">
                <label htmlFor="">
                  <FormattedMessage
                    id="addsingleproduct.cut"
                    defaultMessage="Cut"
                  />
                </label>
                <select
                  className="form-control"
                  onChange={handleInputChange}
                  name="cut"
                  value={addproduct.cut}
                >
                  <FormattedMessage
                    id="addsingleproduct.choosecut"
                    defaultMessage="Choose"
                  >
                    {(id) => <option value="">{id}</option>}
                  </FormattedMessage>
                  {attributes &&
                    attributes.cut &&
                    attributes.cut.map((cut) => {
                      return <option value={cut.id}>{cut.name}</option>;
                    })}
                </select>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4">
              <div className="form-group">
                <label htmlFor="">
                  <FormattedMessage
                    id="addsingleproduct.polish"
                    defaultMessage="Polish"
                  />
                </label>
                <select
                  className="form-control"
                  onChange={handleInputChange}
                  name="polish"
                  value={addproduct.polish}
                >
                  <FormattedMessage
                    id="addsingleproduct.choosepolish"
                    defaultMessage="Choose"
                  >
                    {(id) => <option value="">{id}</option>}
                  </FormattedMessage>
                  {attributes &&
                    attributes.polish &&
                    attributes.polish.map((polish) => {
                      return <option value={polish.id}>{polish.name}</option>;
                    })}
                </select>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="form-group">
                <label htmlFor="">
                  <FormattedMessage
                    id="addsingleproduct.symmetry"
                    defaultMessage="Symmetry"
                  />
                </label>
                <select
                  className="form-control"
                  onChange={handleInputChange}
                  name="symmetry"
                  value={addproduct.symmetry}
                >
                  <FormattedMessage
                    id="addsingleproduct.choosesymmetry"
                    defaultMessage="Choose"
                  >
                    {(id) => <option value="">{id}</option>}
                  </FormattedMessage>
                  {attributes &&
                    attributes.symmetry &&
                    attributes.symmetry.map((symmetry) => {
                      return (
                        <option value={symmetry.id}>{symmetry.name}</option>
                      );
                    })}
                </select>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4">
              <div className="form-group">
                <label htmlFor="">
                  <FormattedMessage
                    id="addsingleproduct.fluorescence"
                    defaultMessage="Fluorescence"
                  />
                </label>
                <select
                  className="form-control"
                  onChange={handleInputChange}
                  name="fluorescence"
                  value={addproduct.fluorescence}
                >
                  <FormattedMessage
                    id="addsingleproduct.choosefluorescence"
                    defaultMessage="Choose"
                  >
                    {(id) => <option value="">{id}</option>}
                  </FormattedMessage>
                  {attributes &&
                    attributes.fluorescence &&
                    attributes.fluorescence.map((flurescence) => {
                      return (
                        <option value={flurescence.id}>
                          {flurescence.name}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
            <div className="col-lg-4" id="white_diamond_milky">
              <div className="form-group">
                <label htmlFor="">
                  <FormattedMessage
                    id="addsingleproduct.milky"
                    defaultMessage="Milky"
                  />
                  <span className="strike_red">*</span>
                </label>

                <select
                  className={classnames(
                    "form-control milky",
                    { "is-valid": validerror.clarityError === false },
                    { "is-invalid": validerror.milkyError }
                  )}
                  onChange={handleInputChange}
                  name="milky"
                  value={addproduct?.milky}
                >
                  <FormattedMessage
                    id="addsingleproduct.choosemilky"
                    defaultMessage="Choose"
                  >
                    {(id) => <option value="">{id}</option>}
                  </FormattedMessage>
                  {attributes &&
                    attributes.milky &&
                    attributes.milky.map((milky) => {
                      return <option value={milky.id}>{milky.name}</option>;
                    })}
                </select>
                <p className="invalid-feedback">{validerror.milkyError}</p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-1 white_diamond_bgm">
              <div className="form-group">
                <label htmlFor="">
                  <FormattedMessage
                    id="addsingleproduct.bgm"
                    defaultMessage="NO BGM"
                  />
                  <span className="strike_red">*</span>
                </label>
              </div>
            </div>
            <div className="col-lg-4 white_diamond_bgm">
              <div className="cus_switch d-flex align-item-center">
                {/* <p className="font-14 fm black-3B pr-3"><FormattedMessage
                                    id="addsingleproduct.No"
                                    defaultMessage="No"
                                /></p> */}
                <label className="switch">
                  <input
                    type="checkbox"
                    name="bgm"
                    value={addproduct.bgm}
                    class="bgm"
                    id="bgmCheckbox"
                  />
                  <span className="slider round"></span>
                </label>
                {/* <p className="gray-75 font-14 fm pl-3"> <FormattedMessage
                                    id="addsingleproduct.Yes"
                                    defaultMessage="Yes"
                                /></p> */}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4">
              <div className="form-group">
                <label htmlFor="">
                  <FormattedMessage
                    id="addsingleproduct.status"
                    defaultMessage="Status"
                  />
                  <span className="strike_red">*</span>
                </label>
                <select
                  className={classnames(
                    "form-control",
                    { "is-valid": validerror.clarityError === false },
                    { "is-invalid": validerror.StatusError }
                  )}
                  onChange={handleInputChange}
                  name="availability_status"
                  value={addproduct.availability_status}
                >
                  <FormattedMessage
                    id="addsingleproduct.choose_status"
                    defaultMessage="Choose Status"
                  >
                    {(id) => <option value="">{id}</option>}
                  </FormattedMessage>
                  {attributes &&
                    attributes.availability_status &&
                    attributes.availability_status
                      .filter(
                        (availability_status) => availability_status.id !== 2321
                      )
                      .map((availability_status) => {
                        return (
                          <option value={availability_status.id}>
                            {availability_status.name}
                          </option>
                        );
                      })}
                </select>
                <p className="invalid-feedback">{validerror.StatusError}</p>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="form-group">
                <label htmlFor="">
                  <FormattedMessage
                    id="addsingleproduct.measurement"
                    defaultMessage="Measurement"
                  />
                </label>
                <div className="row">
                  <div className="col-md-4 mb-md-0 mb-3">
                    <input
                      type="text"
                      className={classnames(
                        "form-control",
                        { "is-valid": validerror.mm_l_Error === false },
                        { "is-invalid": validerror.mm_l_Error }
                      )}
                      placeholder="length"
                      name="mm_l"
                      onChange={handleInputChange}
                      value={addproduct.mm_l}
                    />
                    <p className="invalid-feedback">{validerror.mm_l_Error}</p>

                    {/* <input className="form-control" type="text" placeholder="length" onChange={handleInputChange} name="mm_l" value={addproduct.mm_l} /> */}
                  </div>
                  <div className="col-md-4 mb-md-0 mb-3">
                    <input
                      type="text"
                      className={classnames(
                        "form-control",
                        { "is-valid": validerror.mm_w_Error === false },
                        { "is-invalid": validerror.mm_w_Error }
                      )}
                      placeholder="width"
                      name="mm_w"
                      onChange={handleInputChange}
                      value={addproduct.mm_w}
                    />
                    <p className="invalid-feedback">{validerror.mm_w_Error}</p>

                    {/* <input className="form-control" type="text" placeholder="width" onChange={handleInputChange} name="mm_w" value={addproduct.mm_w} /> */}
                  </div>
                  <div className="col-md-4 mb-md-0 mb-0">
                    <input
                      type="text"
                      className={classnames(
                        "form-control",
                        { "is-valid": validerror.mm_h_Error === false },
                        { "is-invalid": validerror.mm_h_Error }
                      )}
                      placeholder="depth"
                      name="mm_h"
                      onChange={handleInputChange}
                      value={addproduct.mm_h}
                    />
                    <p className="invalid-feedback">{validerror.mm_h_Error}</p>

                    {/* <input className="form-control" type="text" placeholder="depth" onChange={handleInputChange} name="mm_h" value={addproduct.mm_h} /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4">
              <div className="form-group">
                <label htmlFor="">
                  <FormattedMessage
                    id="addsingleproduct.table"
                    defaultMessage="Table"
                  />{" "}
                  %
                </label>
                <input
                  type="text"
                  className={classnames(
                    "form-control",
                    { "is-valid": validerror.table_perError === false },
                    { "is-invalid": validerror.table_perError }
                  )}
                  placeholder="Enter Table %"
                  name="table_per"
                  onChange={handleInputChange}
                  value={addproduct.table_per}
                />
                <p className="invalid-feedback">{validerror.table_perError}</p>

                {/* <input className="form-control" type="text" placeholder="Enter Table %" onChange={handleInputChange} name="table_per" value={addproduct.table_per} /> */}
              </div>
            </div>

            <div className="col-lg-4">
              <div className="form-group">
                <label htmlFor="">
                  <FormattedMessage
                    id="addsingleproduct.depth"
                    defaultMessage="Depth"
                  />
                  %
                </label>
                <input
                  type="text"
                  className={classnames(
                    "form-control",
                    { "is-valid": validerror.depth_perError === false },
                    { "is-invalid": validerror.depth_perError }
                  )}
                  placeholder="Enter Depth %"
                  name="depth_per"
                  onChange={handleInputChange}
                  value={addproduct.depth_per}
                />
                <p className="invalid-feedback">{validerror.depth_perError}</p>

                {/* <input className="form-control" type="text" placeholder="Enter Depth %" onChange={handleInputChange} name="depth_per" value={addproduct.depth_per} /> */}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4">
              <div className="form-group">
                <label htmlFor="">
                  <FormattedMessage
                    id="addsingleproduct.certificate"
                    defaultMessage="Certificate"
                  />
                  <span className="strike_red">*</span>
                </label>
                <select
                  className={classnames(
                    "form-control",
                    { "is-valid": validerror.certificateError === false },
                    { "is-invalid": validerror.certificateError }
                  )}
                  onChange={handleInputChange}
                  name="certificate"
                  value={addproduct.certificate}
                >
                  <FormattedMessage
                    id="addsingleproduct.choosecertificate"
                    defaultMessage="Choose"
                  >
                    {(id) => <option value="">{id}</option>}
                  </FormattedMessage>
                  {attributes &&
                    attributes.certificate &&
                    attributes.certificate.map((certificate) => {
                      return (
                        <option value={certificate.id}>
                          {certificate.name}
                        </option>
                      );
                    })}
                </select>
                <p className="invalid-feedback">
                  {validerror.certificateError}
                </p>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="form-group">
                <label htmlFor="">
                  <FormattedMessage
                    id="addsingleproduct.certificate-no"
                    defaultMessage="Cetificate No."
                  />
                </label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Enter Certificate No."
                  onChange={handleInputChange}
                  name="certificate_number"
                  value={addproduct.certificate_number}
                />
              </div>
            </div>
          </div>
          <div className="row">
            {!checkFancy ? (
              <div className="col-lg-4" id="discount">
                <div className="form-group">
                  <label htmlFor="">
                    <FormattedMessage
                      id="addsingleproduct.discount"
                      defaultMessage="Discount"
                    />
                    % <span className="strike_red">*</span>
                  </label>
                  <input
                    className="form-control discount"
                    type="text"
                    placeholder="Enter Discount"
                    onChange={handleInputChange}
                    name="discount"
                    value={addproduct?.discount}
                  />
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="col-lg-4">
              <div className="form-group">
                <label htmlFor="">
                  <FormattedMessage
                    id="addsingleproduct.price-ct"
                    defaultMessage="Price/ct"
                  />
                  <span className="strike_red">*</span>
                </label>
                <input
                  className={classnames(
                    "form-control price",
                    { "is-valid": validerror.priceError === false },
                    { "is-invalid": validerror.priceError }
                  )}
                  type="text"
                  placeholder="Enter Price"
                  onChange={handleInputChange}
                  name="price"
                  // value={addproduct?.price}
                />
                <p className="invalid-feedback">{validerror.priceError}</p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-8">
              <div className="form-group">
                <label htmlFor="">
                  <FormattedMessage
                    id="addsingleproduct.comment"
                    defaultMessage="Supplier Comment (optional)"
                  />
                </label>
                <textarea
                  placeholder="Comment"
                  rows="5"
                  className="form-control"
                  onChange={handleInputChange}
                  name="comment"
                  maxLength={300}
                  value={addproduct.comment}
                ></textarea>
              </div>
            </div>
          </div>

          <h3 className="forms toBrdr">
            <FormattedMessage
              id="addsingleproduct.uploadimage"
              defaultMessage="Upload Product Photos"
            />
            <p>
              <FormattedMessage
                id="addsingleproduct.supported-image"
                defaultMessage="JPEG, PNG supported, max 10mb per image"
              />
            </p>
          </h3>

          {images &&
            images.map((data, key) => {
              return (
                <div className="row imgEdits mb-4">
                  <div className="col-md-4 imgBlock">
                    <img className="w-100" src={data.url} alt="" />
                    <div className="hover">
                      <img
                        onClick={(e) => delete_image(key)}
                        src={IMAGES.CLOSE_WHITE}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-md-8">
                    <h4>{data.name}</h4>
                    <p className="mb-10">
                      <FormattedMessage
                        id="addsingleproduct.ImageUploaded"
                        defaultMessage="Image Uploaded"
                      />{" "}
                      ({data.size / 1024}kb)
                    </p>
                  </div>
                </div>
              );
            })}
          {images?.length < 5 ? (
            <div className="row imgEdits mb-4">
              <div className="col-md-4">
                <div className="moreUpload">
                  <span>
                    <img src={IMAGES.DOWNLOAD_SVG} alt="" />
                  </span>
                  <FormattedMessage
                    id="addsingleproduct.drop-photo"
                    defaultMessage="Drop Photos"
                  />
                  <input
                    type="file"
                    name="Image"
                    id="image"
                    multiple
                    onChange={image_select}
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          {/* video Code start */}
          <h3 className="forms toBrdr">
            <FormattedMessage
              id="addsingleproduct.uploadvideo"
              defaultMessage="Upload Product Video"
            />
            <p>
              <FormattedMessage
                id="addsingleproduct.Mp4supported"
                defaultMessage="Mp4 supported"
              />
            </p>
          </h3>

          {video && video.url ? (
            <div className="col-md-4 imgBlock">
              <div className="row imgEdits videoEdits mb-4">
                <div className="close-vid">
                  <img onClick={delete_video} src={IMAGES.CLOSE_WHITE} alt="" />
                </div>
                <video id="video" style={{ "max-width": "100%" }}>
                  <source src={video && video.url} type="video/mp4" />
                  <FormattedMessage
                    id="addsingleproduct.YourBrowserNotSupport"
                    defaultMessage="Your browser does not support the video tag."
                  />
                </video>
              </div>
            </div>
          ) : (
            <div className="row imgEdits mb-4 videoUploadDiv">
              <div className="col-md-4">
                <div className="moreUpload">
                  <span>
                    <img src={IMAGES.DOWNLOAD_SVG} alt="" />
                  </span>
                  <FormattedMessage
                    id="addsingleproduct.drop-videos"
                    defaultMessage="Drop Video"
                  />
                  <input
                    type="file"
                    name="video_upload"
                    id="video_upload"
                    onChange={video_select}
                  />
                </div>
              </div>
            </div>
          )}

          {/* 3D Link  Code start */}
          <h3 className="forms toBrdr">
            <FormattedMessage
              id="addsingleproduct.3dlink"
              defaultMessage="Upload Product Video"
            />
            {/* &nbsp;
                        &nbsp;
                        &nbsp;
                        <img src={addButtonImg} alt="Add Button" width={"20px"} onClick={handleAddMoreLinks} style={{ cursor: "pointer" }} />
                        &nbsp; */}
          </h3>

          <div className="row">
            <div className="col-lg-8">
              <div className="form-group">
                <label htmlFor="">
                  <FormattedMessage
                    id="addsingleproduct.add3dlink"
                    defaultMessage="Stock No."
                  />
                </label>
                <input
                  className="form-control"
                  type="text"
                  // placeholder="Enter product 3d Link."
                  placeholder="https://www.v360.in"
                  value={addproduct?.diamond_3d_link}
                  onChange={(e) =>
                    setAddProduct({
                      ...addproduct,
                      diamond_3d_link: e?.target?.value,
                    })
                  }
                />
              </div>
            </div>

            {/* {
                            add3DLinkInput &&
                            add3DLinkInput?.map((data, index) => {
                                return (
                                    <>
                                        <div className="col-lg-8">
                                            <div className="form-group">
                                                <label htmlFor="">
                                                    <FormattedMessage
                                                        id="addsingleproduct.add3dlink"
                                                        defaultMessage="Stock No."
                                                    />
                                                </label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="Enter product 3d Link."
                                                    value={data.value}
                                                    onChange={(e) => handleSetProduct3DLinks(e, data.id)}
                                                />

                                                <p className="invalid-feedback">{validerror.stock_numberError}</p>
                                            </div>
                                        </div>
                                        {
                                            index !== 0 &&
                                            <div className="col-lg-4" onClick={() => handleRemoveProduct3DLinks(data?.id)} >
                                                <img src={removeButtonImg} alt="Remove Button" width={"20px"} style={{ cursor: "pointer" }} onClick={() => handleRemoveProduct3DLinks(data?.id)} />
                                            </div>
                                        }
                                    </>
                                )
                            })
                        } */}
          </div>

          <div className="row actions my-5">
            <div className="col-md-6">
              <Link
                className="btns outline mb-2 mr-2"
                style={{ textAlign: "center" }}
                to={url + "/myproducts/diamonds"}
              >
                <FormattedMessage
                  id="addsingleproduct.cancel"
                  defaultMessage="Cancel"
                />
              </Link>

              <button type="submit" className="btn btn-blue mb-2">
                <FormattedMessage
                  id="addsingleproduct.addproduct"
                  defaultMessage="Add Products"
                />
              </button>
            </div>
          </div>
        </form>
      </Fragment>
      {data && <PopupSucess title={"SUCCESS"} props={data} />}
    </Fragment>
  );
};

export default AddDiamonds;
