import { FormattedMessage, useIntl } from "react-intl"

export const advertisementJson = [
    { "value": "id", "type": "asc", "display_name": <FormattedMessage id="Oldest to Newest" /> },
    { "value": "id", "type": "desc", "display_name": <FormattedMessage id="Newest to Oldest" /> },
    { "value": "updated_total_price", "type": "asc", "display_name": <FormattedMessage id="Total Price: Low to High" /> },
    { "value": "updated_total_price", "type": "desc", "display_name": <FormattedMessage id="Total Price: High to Low" /> },
]

export const diamondsJson = [
    { "value": "updated_discount", "type": "asc", "display_name": <FormattedMessage id="Discount: Low to High" /> },
    { "value": "updated_discount", "type": "desc", "display_name": <FormattedMessage id="Discount: High to Low" /> },
    { "value": "updated_total_price", "type": "asc", "display_name": <FormattedMessage id="Total Price: Low to High" /> },
    { "value": "updated_total_price", "type": "desc", "display_name": <FormattedMessage id="Total Price: High to Low" /> },
    { "value": "carat", "type": "asc", "display_name": <FormattedMessage id="Carat: Low to High" /> },
    { "value": "carat", "type": "desc", "display_name": <FormattedMessage id="Carat: High to Low" /> },
   
]

export const parcelJson = [

    { "value": "price", "type": "asc", "display_name": <FormattedMessage id="Price/ct: Low to High" /> },
    { "value": "price", "type": "desc", "display_name": <FormattedMessage id="Price/ct: High to Low" /> },
    { "value": "carat", "type": "asc", "display_name": <FormattedMessage id="Carat: Low to High" /> },
    { "value": "carat", "type": "desc", "display_name": <FormattedMessage id="Carat: High to Low" /> },
]

export const jewelleryJson = [
    { "value": "created_at", "type": "asc", "display_name": <FormattedMessage id="New Arrivals: Low to High" /> },
    { "value": "created_at", "type": "desc", "display_name": <FormattedMessage id="New Arrivals: High to Low" /> },
    { "value": "price", "type": "asc", "display_name": <FormattedMessage id="Price: Low to High" /> },
    { "value": "price", "type": "desc", "display_name": <FormattedMessage id="Price: High to Low" /> },
]

export const colorstoneJson = [
    { "value": "price", "type": "asc", "display_name": <FormattedMessage id="Price/ct: Low to High" /> },
    { "value": "price", "type": "desc", "display_name": <FormattedMessage id="Price/ct: High to Low" /> },
    { "value": "carat", "type": "asc", "display_name": <FormattedMessage id="Carat: Low to High" /> },
    { "value": "carat", "type": "desc", "display_name": <FormattedMessage id="Carat: High to Low" /> },
]

export const watchJson = [
    { "value": "price", "type": "asc", "display_name": <FormattedMessage id="Price: Low to High" /> },
    { "value": "price", "type": "desc", "display_name": <FormattedMessage id="Price: High to Low" /> },
]

export const weddingBandJson = [
    { "value": "pricegr", "type": "asc", "display_name": <FormattedMessage id="Price: Low to High" /> },
    { "value": "pricegr", "type": "desc", "display_name": <FormattedMessage id="Price: High to Low" /> },

]

export const selectActionJson = [
    { "value": "active", "display_name": <FormattedMessage id="List Item" /> },
    { "value": "deactivate", "display_name": <FormattedMessage id="Unlist Item" /> },
    { "value": "instock", "display_name": <FormattedMessage id="Mark as In Stock" /> },
    { "value": "outofstock", "display_name": <FormattedMessage id="Mark as Sold" /> },
    { "value": "delete", "display_name": <FormattedMessage id="Delete" /> }
]

export const singleProductAction = [
    { "value": "active", "display_name": <FormattedMessage id="List Item" />, "status": 1 },
    { "value": "deactivate", "display_name": <FormattedMessage id="Unlist Item" />, "status": 0 },
]

export const listProductAction = [
    { "value": "compare", "display_name": <FormattedMessage id="Show Selected Items" />, "status": 1 },
    { "value": "pdf", "display_name": <FormattedMessage id="Download Selected Items" />, "status": 1 },
    { "value": "pdf_all", "display_name": <FormattedMessage id="Download All Items As Pdf" />, "status": 1 },
    { "value": "excel_all", "display_name": <FormattedMessage id="Download All Items As Excel" />, "status": 1 },
]


export const CaratJson = [
    { "id": 1.3, "from": "0.30", "to": "0.39", "display_name": "0.30ct - 0.39ct" },
    { "id": 2.3, "from": "0.40", "to": "0.49", "display_name": "0.40ct - 0.49ct" },
    { "id": 3.3, "from": "0.50", "to": "0.69", "display_name": "0.50ct - 0.69ct" },
    { "id": 4.3, "from": "0.70", "to": "0.89", "display_name": "0.70ct - 0.89ct" },
    { "id": 5.3, "from": "0.90", "to": "0.99", "display_name": "0.90ct - 0.99ct" },
    { "id": 6.3, "from": "1.00", "to": "1.49", "display_name": "1.00ct - 1.49ct" },
    { "id": 7.3, "from": "1.50", "to": "1.99", "display_name": "1.50ct - 1.99ct" },
    { "id": 8.3, "from": "2.00", "to": "2.99", "display_name": "2.00ct - 2.99ct" },
    { "id": 9.3, "from": "3.00", "to": "3.99", "display_name": "3.00ct - 3.99ct" },
    { "id": 10.3, "from": "4.00", "to": "4.99", "display_name": "4.00ct - 4.99ct" },
    { "id": 11.3, "from": "5.00", "to": "5.99", "display_name": "5.00ct - 9.99ct" },
]

export const CaratDiamondJson = [
    { "id": 0.1, "from": "0.30", "to": "0.39", "display_name": "0.30ct - 0.39ct" },
    { "id": 0.2, "from": "0.40", "to": "0.49", "display_name": "0.40ct - 0.49ct" },
    { "id": 0.3, "from": "0.50", "to": "0.69", "display_name": "0.50ct - 0.69ct" },
    { "id": 0.4, "from": "0.70", "to": "0.89", "display_name": "0.70ct - 0.89ct" },
    { "id": 0.5, "from": "0.90", "to": "0.99", "display_name": "0.90ct - 0.99ct" },
    { "id": 1.3, "from": "1.00", "to": "1.49", "display_name": "1.00ct - 1.49ct" },
    { "id": 2.3, "from": "1.50", "to": "1.99", "display_name": "1.50ct - 1.99ct" },
    { "id": 3.3, "from": "2.00", "to": "2.99", "display_name": "2.00ct - 2.99ct" },
    { "id": 4.3, "from": "3.00", "to": "3.99", "display_name": "3.00ct - 3.99ct" },
    { "id": 5.3, "from": "4.00", "to": "4.99", "display_name": "4.00ct - 4.99ct" },
    { "id": 6.3, "from": "5.00", "to": "5.99", "display_name": "5.00ct - 5.99ct" },
    { "id": 7.3, "from": "10.00", "to": "10.99", "display_name": "10.00ct - 10.99ct" }
]

export const CaratColorJson = [
    { "id": 1.6, "from": "1.00", "to": "2.99", "display_name": "1.00ct - 2.99ct" },
    { "id": 2.6, "from": "3.00", "to": "4.99", "display_name": "3.00ct - 4.99ct" },
    { "id": 3.6, "from": "5.00", "to": "6.99", "display_name": "5.00ct - 6.99ct" },
    { "id": 4.6, "from": "7.00", "to": "9.99", "display_name": "7.00ct - 9.99ct" },
    { "id": 5.6, "from": "10.00", "to": "", "display_name": "10.00+" },
]

export const CaratParcelsJson = [
    { "id": 1.6, "from": "0.10", "to": "0.12", "display_name": "0.10ct – 0.12ct (1/10)" },
    { "id": 2.6, "from": "0.15", "to": "0.17", "display_name": "0.15ct – 0.17ct (1/6)" },
    { "id": 3.6, "from": "0.20", "to": "0.23", "display_name": "0.20ct – 0.23ct (1/5)" },
    { "id": 4.6, "from": "0.25", "to": "0.27", "display_name": "0.25ct – 0.27ct (1/4)" },
    { "id": 5.6, "from": "0.30", "to": "0.33", "display_name": "0.30ct – 0.33ct (1/3)" },
]
export const widenessJson = [
    { "id": 0.11, "value": "2.00", "display_name": "2.00" },
    { "id": 0.22, "value": "2.50", "display_name": "2.50" },
    { "id": 0.33, "value": "3.00", "display_name": "3.00" },
    { "id": 0.44, "value": "3.50", "display_name": "3.50" },
    { "id": 0.55, "value": "4.00", "display_name": "4.00" },
    { "id": 0.66, "value": "4.50", "display_name": "4.50" },
    { "id": 0.77, "value": "5.00", "display_name": "5.00" },
    { "id": 0.88, "value": "5.50", "display_name": "5.50" },
    { "id": 0.99, "value": "6.00", "display_name": "6.00" },
    { "id": 0.10, "value": "6.50", "display_name": "6.50" },
    { "id": 0.111, "value": "7.00", "display_name": "7.00" },
    { "id": 0.122, "value": "7.50", "display_name": "7.50" },
    { "id": 0.133, "value": "8.00", "display_name": "8.00" },
    { "id": 0.144, "value": "8.50", "display_name": "8.50" },
    { "id": 0.155, "value": "9.00", "display_name": "9.00" },
    { "id": 0.166, "value": "9.50", "display_name": "9.50" },
    { "id": 0.177, "value": "10.00", "display_name": "10.00" },
    { "id": 0.188, "value": "other", "display_name": "Other" },
]


export const thicknessJson = [
    { "id": 1.1, "value": "0.80", "display_name": "0.80" },
    { "id": 2.1, "value": "0.90", "display_name": "0.90" },
    { "id": 3.1, "value": "1.00", "display_name": "1.00" },
    { "id": 4.1, "value": "1.10", "display_name": "1.10" },
    { "id": 5.1, "value": "1.20", "display_name": "1.20" },
    { "id": 6.1, "value": "1.30", "display_name": "1.30" },
    { "id": 7.1, "value": "1.40", "display_name": "1.40" },
    { "id": 8.1, "value": "1.50", "display_name": "1.50" },
    { "id": 9.1, "value": "1.60", "display_name": "1.60" },
    { "id": 10.1, "value": "1.70", "display_name": "1.70" },
    { "id": 11.1, "value": "1.80", "display_name": "1.80" },
    { "id": 12.1, "value": "1.90", "display_name": "1.90" },
    { "id": 13.1, "value": "2.00", "display_name": "2.00" },
    { "id": 14.1, "value": "2.10", "display_name": "2.10" },
    { "id": 15.1, "value": "2.20", "display_name": "2.20" },
    { "id": 16.1, "value": "2.30", "display_name": "2.30" },
    { "id": 17.1, "value": "2.40", "display_name": "2.40" },
    { "id": 18.1, "value": "2.50", "display_name": "2.50" },
    { "id": 19.1, "value": "other", "display_name": "Other" },
]

export const goldKaratJson = [
    { "id": 1.2, "value": "8", "display_name": <FormattedMessage id="Karat" values={{ value: "8" }} /> },
    { "id": 2.2, "value": "9", "display_name": <FormattedMessage id="Karat" values={{ value: "9" }} /> },
    { "id": 3.2, "value": "10", "display_name": <FormattedMessage id="Karat" values={{ value: "10" }} /> },
    { "id": 4.2, "value": "14", "display_name": <FormattedMessage id="Karat" values={{ value: "14" }} /> },
    { "id": 5.2, "value": "18", "display_name": <FormattedMessage id="Karat" values={{ value: "18" }} /> },
    { "id": 6.2, "value": "20", "display_name": <FormattedMessage id="Karat" values={{ value: "20" }} /> },
    { "id": 7.2, "value": "21", "display_name": <FormattedMessage id="Karat" values={{ value: "21" }} /> },
    { "id": 8.2, "value": "22", "display_name": <FormattedMessage id="Karat" values={{ value: "22" }} /> },
]


export const ParcelMeasurementJson = [
    { "id": 1.4, "length": "3.00", "width": "1.50", "display_name": "3.00 x 1.50" },
    { "id": 2.4, "length": "4.00", "width": "2.00", "display_name": "4.00 x 2.00" },
    { "id": 3.4, "length": "5.00", "width": "3.00", "display_name": "5.00 x 3.00" },
    { "id": 4.4, "length": "6.00", "width": "4.00", "display_name": "6.00 x 4.00" },
    { "id": 5.4, "length": "8.00", "width": "6.00", "display_name": "8.00 x 6.00" }
]


export const ColorMeasurementJson = [
    { "id": 1.5, "length": "5.00", "width": "3.00", "display_name": "5.00 x 3.00" },
    { "id": 2.5, "length": "6.00", "width": "4.00", "display_name": "6.00 x 4.00" },
    { "id": 3.5, "length": "7.00", "width": "5.00", "display_name": "7.00 x 5.00" },
    { "id": 4.5, "length": "9.00", "width": "7.00", "display_name": "9.00 x 7.00" },
    { "id": 5.5, "length": "10.00", "width": "8.00", "display_name": "10.00 x 8.00" }
]


export const seiveJson = [
    { "id": 1.9, "value": "701", "display_name": "2" },
    { "id": 2.9, "value": "710", "display_name": "6.5" },
    { "id": 3.9, "value": "711", "display_name": "7" },
    { "id": 4.9, "value": "712", "display_name": "7.5" },
    { "id": 5.9, "value": "713", "display_name": "8" }
]

// export const ExtentionSupport = ["PNG", "png", "JPG", "jpg", "jpeg", "JPEG", 'webp', "WEBP"];

export const ExtentionSupport = [
    "png",
    "jpeg",
    "jpg",
  ];