import React, { Fragment, useState, useEffect, useRef } from 'react';
import { IMAGES } from '../../../constants';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router';
import { useAlert } from "react-alert";
import { getAttributes, addColorStone, GetProductCount, clearMessage, updateColorStone } from "../../../actions/productActions";
import { validateFields } from '../../../validation';
import { FormattedMessage, useIntl } from 'react-intl';
import Loader from '../../layout/Loader';
import $ from 'jquery';
import PopupSucess from '../../layout/PopupSucess';
import { getCategory } from '../../../actions/categoryAction';
import { url } from '../../../config';
import { Link } from 'react-router-dom';
import { ExtentionSupport } from '../../../utils/json';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup'
import { ErrorMessage } from '@hookform/error-message';
import ColorStoneValidation from '../../../validation/ColorStoneValidation';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import ProtectedHeader from '../../layout/ProtectedHeader';
import { getProductDetails } from "../../../actions/productActions";
import { ImageUrl } from '../../../helper';
import { deleteImage } from '../../../utils/CommonApi';

const queryString = require('query-string');
const addButtonImg = require("../../../assets/images/plus.png")
const removeButtonImg = require("../../../assets/images/minus.png")

const AddColorStones = () => {

    const dispatch = useDispatch()
    const alert = useAlert()
    const video1 = {};
    const location = useLocation();
    const { id } = useParams();
    const [images, setImages] = useState([])
    const [imageToShow, setImageToShow] = useState([])
    const [video, setVideo] = useState(null)
    const parsed = queryString.parse(location.search);
    const { data, message, error, loading } = useSelector(state => state.colorStoneAdd)
    const colorStoneUpdateState = useSelector(state => state.colorStoneUpdate)
    const { attributes } = useSelector(state => state.attributes)
    const productDetail = useSelector(state => state.productDetails);
    const [deleteImgLoader, setDeleteImgLoader] = useState(false)
    const fileInputRef = useRef(null);

    const formInputs = {
        product_title: "",
        stone_type: "",
        shape: "",
        carat: "",
        mm_h: "",
        mm_l: "",
        mm_w: "",
        certificate: "",
        certificate_number: "",
        price: "",
        stock_number: "",
        images: "",
        video: null,
        comment: "",
    }

    const { register, setValue, watch, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(ColorStoneValidation),
        defaultValues: formInputs
    })

    useEffect(() => {
        if (id) {
            dispatch(getProductDetails(id))
        }
        dispatch(getAttributes(parsed && parsed.cat))
    }, [])

    useEffect(() => {
        if (colorStoneUpdateState?.data) {
            window.location = url + '/myproducts/color-stones';
        } else {
            if (colorStoneUpdateState?.error) {
                alert.error(colorStoneUpdateState?.error)
            }
        }
        dispatch(updateColorStone('RESET'))
    }, [colorStoneUpdateState?.data])

    useEffect(() => {
        if (id && productDetail?.details) {

            
            const p_data = productDetail?.details?.productDetails

            console.log("certificate------",p_data?.a_certificate?.attribute_id);

            setValue("product_title", p_data?.product_title)
            setValue("stone_type", p_data?.stone_type)
            setValue("shape", p_data?.a_shape?.attribute_id)
            setValue("carat", p_data?.carat)
            setValue("mm_h", p_data?.mm_h)
            setValue("mm_l", p_data?.mm_l)
            setValue("mm_w", p_data?.mm_w)
            setValue("certificate", p_data?.a_certificate?.attribute_id)
            setValue("certificate_number", p_data?.certificate_number)
            setValue("price", p_data?.price)
            setValue("stock_number", p_data?.stock_number)
            setValue("comment", p_data?.comment)

            if (productDetail?.details?.singleVideo) {
                setVideo({ url: ImageUrl(productDetail?.details?.singleVideo?.video), id: productDetail?.details?.singleVideo?.id })
            }

            const imgs = productDetail?.details?.productImages?.map(i => {
                return {
                    url: ImageUrl(i?.file),
                    id: i?.id
                }
            })
            setImageToShow(imgs)
        }
    }, [productDetail?.details])

    const onSubmit = async (payload) => {
        payload.video = watch("video")
        if (watch("mm_l") || watch("mm_h") || watch("mm_w")) {
            if (!watch("mm_l") || !watch("mm_h") || !watch("mm_w")) {
                alert.error("Please either fill in all the measurements or leave them all blank.");
                return
            }
        }
        payload.mm_l = Number(payload?.mm_l)?.toFixed(2);
        payload.mm_h = Number(payload?.mm_h)?.toFixed(2);
        payload.mm_w = Number(payload?.mm_w)?.toFixed(2);

        if (id) {
            payload.id = id
            console.log("payload>.", payload);

            await dispatch(updateColorStone(payload))
        } else {
            await dispatch(addColorStone(payload))
        }
        dispatch(GetProductCount());
        dispatch(getCategory());
        return;
    } 

    useEffect(() => {
        if (data) {
            // alert.success(message);
            $("#popup-click-sucess").trigger('click');
            dispatch(clearMessage());
            setVideo(null);
            // $('.videoUploadDiv').css('display', 'block');
            // $('.videoEdits').css('display', 'none');
            $('#video_upload').val('');
            setImages("")
            window.location = url + '/myproducts/color-stones';
        }
        if (error) {
            alert.error(error)
        }
        dispatch(addColorStone("RESET"))
    }, [alert, error, message, data])

    //images functionality

    const convertArrayToFileList = (filesArray) => {
        const dataTransfer = new DataTransfer();
        filesArray?.forEach(fileObj => dataTransfer?.items?.add(fileObj?.file));
        return dataTransfer?.files;
    };

    useEffect(() => {
        if (images?.length > 0) {
            setValue("images", convertArrayToFileList(images))
        } else {
            setValue("images", null)
        }
    }, [images])

    const image_select = (e) => {
        // Exclude unsupported document 
        let image = e?.target?.files;

        const maxSize = 10 * 1024 * 1024; // 10MB in bytes
        let tempImgArr = []
        let tempImgArr2Show = []
        let remaining_space = 5 - images?.length;
        let max_len = image?.length > remaining_space ? remaining_space : image?.length;

        for (let i = 0; i < max_len; i++) {
            const file = image[i];
            const fileName = file.name;
            const fileType = fileName?.split('.').pop();

            // Check for valid extension
            if (!ExtentionSupport.includes(fileType)) {
                alert.error(fileName + " has an unsupported file format.");
                continue;
            }

            if (file.size > maxSize) {
                alert.error(fileName + " exceeds the 10MB size limit");
                continue;
            }

            if (check_duplicate(fileName)) {
                tempImgArr2Show?.push({
                    "name": fileName,
                    "url": URL.createObjectURL(file),
                    "size": file.size,
                    "id": ""
                })

                tempImgArr.push({
                    "name": fileName,
                    "url": URL.createObjectURL(file),
                    "file": file,
                    "size": file.size
                })
            } else {
                alert.error(fileName + " is already added to the list");
            }
        }
        setImageToShow((prevImg) => [...prevImg, ...tempImgArr2Show])
        setImages((prevImg) => [...prevImg, ...tempImgArr]);
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
    }

    const video_select = (e) => {
        const ext = e?.target?.files[0]?.name.split('.').pop();
        if (ext == 'mp4') {
            let video = e?.target?.files;
            let videoUp = e?.target?.files[0];

            setValue("video", videoUp)
            if (videoUp) {
                video1.name = video[0].name
                video1.url = URL.createObjectURL(video[0])
                video1.file = video[0]
                video1.size = video[0].size
                setVideo({ url: video1.url, id: "" });
                var videor = document.getElementById('video');
                var source = document.createElement('source');
                source.setAttribute('src', video1.url);
                source.setAttribute('type', 'video/mp4');
                videor?.load();
            }
        } else {
            alert.error("Please upload supported file format");
        }
    }

    const check_duplicate = (name) => {
        let image = true;
        if (images && images.length > 0) {
            for (let e = 0; e < images.length; e++) {
                if (images[e].name == name) {
                    image = false;
                    break;
                }
            }
        }
        return image;
    }

    const deleteImgOrVideo = async (id, type) => {
        const payload = {
            id: id,
            type: type
        }
        setDeleteImgLoader(true)
        await deleteImage(payload)
        setDeleteImgLoader(false)
    }

    const delete_image = (index, id) => {
        if (id) {
            deleteImgOrVideo(id, "img")
        }
        setImages(images.filter((x, i) => i !== index));
        setImageToShow(imageToShow.filter((x, i) => i !== index));

        if (fileInputRef.current) {
            console.log("fileInputRef.delete_image>>", fileInputRef.current);
            fileInputRef.current.value = "";
        }
    }

    const delete_video = (id) => {
        if (id) {
            deleteImgOrVideo(id, "video")
        }
        setVideo(null);
        $('#video_upload').val('');
        // setAddProduct({ ...addproduct, video: null });
        setValue("video", null)
    }

    const handleSwitchCertificate = (e) => {
        const selectedValue = e.target.value;
        setValue("certificate", selectedValue);

        if (selectedValue === '2311') {
            $('#certificate_number_div').hide();  
            setValue('certificate_number', '');
        } else {
            $('#certificate_number_div').show();
        }
    };
    

   
    const intl = useIntl();
    const stk_number = intl.formatMessage({ id: "Enter Stock No", defaultMessage: "Enter Stock No." });
    const _product_title = intl.formatMessage({ id: "Enter Product Title", defaultMessage: "Enter Product Title" });
    const crt = intl.formatMessage({ id: "Enter Carat", defaultMessage: "Enter Carat" });
    const certificateValue = watch("certificate");
    certificateValue ==2311 ? $('#certificate_number_div').hide() :$('#certificate_number_div').show();
    
    return (
        <Fragment>
            {(loading || productDetail?.loading || colorStoneUpdateState?.loading || deleteImgLoader) && <Loader />}
            {id ? <>
                <ProtectedHeader />
                <ul className="breadcrub">
                    <li>
                        <FormattedMessage
                            id="myaccount.myaccountname"
                            defaultMessage="My Account"
                        />
                    </li>
                    <li><img src={IMAGES.ARROW_SVG} alt="" /></li>
                    <li>
                        <FormattedMessage
                            id="Products"
                            defaultMessage="Products"
                        />
                    </li>
                    <li><img src={IMAGES.ARROW_SVG} alt="" /></li>
                    <li>
                        <FormattedMessage
                            id="addsingleproduct.editproduct"
                            defaultMessage="Edit Product"
                        />
                    </li>
                </ul>
            </>
                : ""}
            <div className={id ? `row justify-content-center py-4` : ""}>
                <div className={id ? 'col-md-9' : ""}>
                    <h3 className="forms">
                        {id
                            ? <FormattedMessage
                                id="addsingleproduct.update-stone"
                                defaultMessage="Update"
                            />
                            : <FormattedMessage
                                id="addsingleproduct.add-a-single"
                                defaultMessage="Add a Single"
                            />}
                        <span>{` `}</span>
                        <FormattedMessage
                            id="addsingleproduct.colorstone"
                            defaultMessage="Color Stone"
                        />
                    </h3>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="form-group">
                                    <label htmlFor="">
                                        <FormattedMessage
                                            id="addsingleproduct.product_title"
                                            defaultMessage="Product Title"
                                        />
                                        <span className="strike_red">*</span></label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder={_product_title}
                                        {...register("product_title")}
                                        name="product_title"
                                        value={watch("product_title")} />
                                    {/* <p style={{color:"red"}}>{validerror.stock_numberError}</p> */}
                                    <ErrorMessage
                                        errors={errors}
                                        name="product_title"
                                        render={({ message }) => <p style={{ color: "red" }}>{message}</p>}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="form-group">
                                    <label htmlFor="">
                                        <FormattedMessage
                                            id="addsingleproduct.stockno"
                                            defaultMessage="Stock No."
                                        />
                                        <span className="strike_red">*</span></label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder={stk_number}
                                        {...register("stock_number")}
                                        name="stock_number"
                                        value={watch("stock_number")} />
                                    {/* <p style={{color:"red"}}>{validerror.stock_numberError}</p> */}
                                    <ErrorMessage
                                        errors={errors}
                                        name="stock_number"
                                        render={({ message }) => <p style={{ color: "red" }}>{message}</p>}
                                    />
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="form-group">
                                    <label htmlFor="">
                                        <FormattedMessage
                                            id="addsingleproduct.carat"
                                            defaultMessage="Carat"
                                        />
                                        <span className="strike_red">*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={crt}
                                        name="carat"
                                        {...register("carat")}
                                        value={watch("carat")} />
                                    {/* <p style={{color:"red"}}>{validerror.caratError}</p> */}
                                    <ErrorMessage
                                        errors={errors}
                                        name="carat"
                                        render={({ message }) => <p style={{ color: "red" }}>{message}</p>}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                        <div className="col-lg-4">
                                <div className="form-group">
                                    <label htmlFor="">
                                        <FormattedMessage
                                            id="addsingleproduct.stone_type"
                                            defaultMessage="Stone Type"
                                        />
                                        <span className="strike_red">*</span></label>
                                    <select
                                        className="form-control"
                                        {...register("stone_type")}
                                        name="stone_type"
                                        value={watch("stone_type")}>
                                        <FormattedMessage id="addsingleproduct.stone_type" defaultMessage="Choose">
                                            {(id) => <option value="">{id}</option>}
                                        </FormattedMessage>
                                        {
                                            attributes && attributes?.stone_type?.map((stone) => {
                                                return (
                                                    <option value={stone.id}>{stone.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    {/* <p style={{color:"red"}}>{validerror.stock_numberError}</p> */}
                                    <ErrorMessage
                                        errors={errors}
                                        name="stone_type"
                                        render={({ message }) => <p style={{ color: "red" }}>{message}</p>}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="form-group">
                                    <label htmlFor="">
                                        <FormattedMessage
                                            id="addsingleproduct.shape"
                                            defaultMessage="Shape"
                                        />
                                        <span className="strike_red">*</span></label>
                                    <select
                                        className="form-control"
                                        {...register("shape")}
                                        name="shape"
                                        value={watch("shape")}>
                                        <FormattedMessage id="addsingleproduct.chooseshape" defaultMessage="Choose">
                                            {(id) => <option value="">{id}</option>}
                                        </FormattedMessage>
                                        {
                                            attributes && attributes.shape && attributes.shape.map((shape) => {
                                                return (
                                                    <option value={shape.id}>{shape.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    {/* <p style={{color:"red"}}>{validerror.shapeError}</p> */}
                                    <ErrorMessage
                                        errors={errors}
                                        name="shape"
                                        render={({ message }) => <p style={{ color: "red" }}>{message}</p>}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* stone_type */}
                        <div className="row">
                        <div className="col-lg-4">
                                <div className="form-group">
                                    <label htmlFor="">
                                        <FormattedMessage
                                            id="addsingleproduct.measurement"
                                            defaultMessage="Measurement"
                                        />
                                        <span className="strike_red">*</span>
                                     </label>
                                    <div className="row">
                                        <div className="col-md-4 mb-md-0 mb-3">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="length"
                                                name="mm_l"
                                                value={watch("mm_l")}
                                                {...register("mm_l")} />
                                            {/* <p style={{color:"red"}}>{validerror.mm_l_Error}</p> */}
                                            <ErrorMessage
                                                errors={errors}
                                                name="mm_l"
                                                render={({ message }) => <p style={{ color: "red" }}>{message}</p>}
                                            />
                                        </div>
                                        <div className="col-md-4 mb-md-0 mb-3">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="width"
                                                name="mm_w"
                                                value={watch("mm_w")}
                                                {...register("mm_w")} />
                                            {/* <p style={{color:"red"}}>{validerror.mm_w_Error}</p> */}
                                            <ErrorMessage
                                                errors={errors}
                                                name="mm_w"
                                                render={({ message }) => <p style={{ color: "red" }}>{message}</p>}
                                            />
                                        </div>
                                        <div className="col-md-4 mb-md-0 mb-0">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="depth"
                                                name="mm_h"
                                                value={watch("mm_h")}
                                                {...register("mm_h")} />
                                            {/* <p style={{color:"red"}}>{validerror.mm_h_Error}</p> */}
                                            <ErrorMessage
                                                errors={errors}
                                                name="mm_h"
                                                render={({ message }) => <p style={{ color: "red" }}>{message}</p>}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="form-group">
                                    <label htmlFor="">
                                        <FormattedMessage
                                            id="addsingleproduct.price-ct"
                                            defaultMessage="Price/ct"
                                        />
                                        <span className="strike_red">*</span></label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Enter Price"
                                        value={watch("price")}
                                        {...register("price")}
                                        name="price"
                                    />
                                    {/* <p style={{color:"red"}}>{validerror.priceError}</p> */}
                                    <ErrorMessage
                                        errors={errors}
                                        name="price"
                                        render={({ message }) => <p style={{ color: "red" }}>{message}</p>}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="form-group">
                                    <label htmlFor="">
                                        <FormattedMessage
                                            id="addsingleproduct.certificate"
                                            defaultMessage="Certificate"
                                        />
                                        <span className="strike_red">*</span></label>
                                        <select
                                            className="form-control"
                                            {...register("certificate")}
                                            onChange={handleSwitchCertificate}  
                                            value={certificateValue} 
                                            id="certificate"
                                        >
                                            <FormattedMessage id="addsingleproduct.choosecertificate" defaultMessage="Choose">
                                                {(id) => <option value="">{id}</option>} 
                                            </FormattedMessage>
                                            {attributes?.certificate?.map((certificate) => (
                                                <option key={certificate.id} value={certificate.id}>
                                                    {certificate.name}
                                                </option>
                                            ))}
                                        </select>
                                    {/* <p style={{color:"red"}}>{validerror.certificateError}</p> */}
                                    <ErrorMessage
                                        errors={errors}
                                        name="certificate"
                                        render={({ message }) => <p style={{ color: "red" }}>{message}</p>}
                                    />
                                </div>
                            </div>
                            
                            <div className="col-lg-4" id="certificate_number_div">
                                <div className="form-group">
                                    <label htmlFor="">
                                        <FormattedMessage
                                            id="addsingleproduct.certificate-no"
                                            defaultMessage="Cetificate No."
                                        />
                                    </label>
                                    <input
                                            type="text" className="form-control" 
                                            id="certificate_number"
                                            name="certificate_number"
                                            placeholder="Enter Certificate Number"
                                            {...register('certificate_number')}
                                        />
                                    <ErrorMessage
                                        errors={errors}
                                        name="certificate_number"
                                        render={({ message }) => <p style={{ color: "red" }}>{message}</p>}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="form-group">
                                    <label htmlFor="">
                                        <FormattedMessage
                                            id="addsingleproduct.comment"
                                            defaultMessage="Supplier Comment (optional)"
                                        />
                                    </label>
                                    <textarea
                                        placeholder="Comment"
                                        rows="5"
                                        className="form-control"
                                        {...register("comment")}
                                        name="comment"
                                        maxLength={300}
                                        value={watch("comment") ? watch("comment") : ""} />
                                </div>
                            </div>
                        </div>
                        <h3 className="forms toBrdr">
                            <FormattedMessage
                                id="addsingleproduct.uploadimage"
                                defaultMessage="Upload Product Photos"
                            />
                            <p><FormattedMessage
                                id="addsingleproduct.supported-image"
                                defaultMessage="JPEG, PNG supported, max 10mb per image"
                            /></p>
                        </h3>
                        {
                            imageToShow && imageToShow?.map((img, key) => {
                                return (
                                    <div className="row imgEdits mb-4">
                                        <div className="col-md-4 imgBlock">
                                            <img className="w-100" src={img?.url} alt="" />
                                            <div className="hover">
                                                <img onClick={(e) => delete_image(key, img?.id)} src={IMAGES.CLOSE_WHITE} alt="" />
                                            </div>
                                        </div>
                                        {
                                            img?.size ?
                                                <div className="col-md-8">
                                                    <h4>{img?.name}</h4>
                                                    <p className="mb-10"><FormattedMessage
                                                        id="addsingleproduct.ImageUploaded"
                                                        defaultMessage="Image Uploaded"
                                                    /> ({img?.size / 1024}kb)</p>
                                                </div>
                                                : ""
                                        }
                                    </div>
                                )
                            })
                        }
                        {
                            imageToShow?.length < 5 ?
                                <div className="row imgEdits mb-4">
                                    <div className="col-md-4">
                                        <div className="moreUpload">
                                            <span><img src={IMAGES.DOWNLOAD_SVG} alt="" /></span>
                                            <FormattedMessage
                                                id="addsingleproduct.drop-photo"
                                                defaultMessage="Drop Photos"
                                            />
                                            <input
                                                type="file"
                                                name="Image"
                                                id="image"
                                                multiple
                                                onChange={image_select}
                                                ref={fileInputRef}
                                            />
                                        </div>
                                    </div>
                                </div>
                                : ""
                        }

                        {/* video Code start */}
                        <h3 className="forms toBrdr">
                            <FormattedMessage
                                id="addsingleproduct.uploadvideo"
                                defaultMessage="Upload Product Video"
                            />
                            <p><FormattedMessage
                                id="addsingleproduct.Mp4supported"
                                defaultMessage="Mp4 supported"
                            /></p>
                        </h3>
                        {
                            video ?
                                <div className="col-md-4 imgEdits">
                                    <div className="row imgBlock videoEdits mb-4">
                                        <div className="close-vid hover">
                                            <img onClick={() => delete_video(video?.id)} src={IMAGES.CLOSE_WHITE} alt="" />
                                        </div>
                                        <video id="video" style={{ 'max-width': '100%' }} >
                                            <source src={video?.url} type="video/mp4" />
                                            <FormattedMessage
                                                id="addsingleproduct.YourBrowserNotSupport"
                                                defaultMessage="Your browser does not support the video tag."
                                            />
                                        </video>
                                    </div>

                                </div>
                                :
                                <div className="row imgEdits mb-4 videoUploadDiv">
                                    <div className="col-md-4">
                                        <div className="moreUpload">
                                            <span><img src={IMAGES.DOWNLOAD_SVG} alt="" /></span>
                                            <FormattedMessage
                                                id="addsingleproduct.drop-videos"
                                                defaultMessage="Drop Video"
                                            />
                                            <input type="file" name="video_upload" id="video_upload" onChange={video_select} />
                                        </div>
                                    </div>
                                </div>
                        }
                        <div className="row actions my-5">
                            <div className="col-md-6">
                                {
                                    id ?
                                        <button type="submit" className="btn btn-blue mb-2">
                                            <FormattedMessage
                                                id="Save"
                                                defaultMessage="Save"
                                            />
                                        </button>
                                        : <>
                                            <Link
                                                className="btns outline mb-2 mr-2"
                                                style={{ "textAlign": 'center' }}
                                                to={url + "/myproducts/diamonds"}>
                                                <FormattedMessage
                                                    id="addsingleproduct.cancel"
                                                    defaultMessage="Cancel"
                                                /></Link>
                                            <button type="submit" className="btn btn-blue mb-2">
                                                <FormattedMessage
                                                    id="addsingleproduct.addproduct"
                                                    defaultMessage="Add Products"
                                                />
                                            </button>
                                        </>
                                }
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {
                data &&
                <PopupSucess title={'SUCCESS'} props={data} />
            }
        </Fragment>
    )
}

export default AddColorStones