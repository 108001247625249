import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';


const PopupSucess = ({ title = "Success", props }) => {
    return (
        <Fragment>
            <div className="modal fade PopsData" id="popup-sucess" tabindex="-1" role="dialog" aria-labelledby="comment" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">

                        <div className="modal-body">
                            <div className="succes">
                                <span className="icon">
                                    <i className="fa fa-thumbs-up" aria-hidden="true"></i>
                                </span>
                                <h4>{title && title}</h4>
                                {props && props?.trim().split('.').map(x => (
                                    <p className='my-0' >{x}.</p>
                                ))}
                                <br/>
                                <button
                                    id="dismiss-modal"
                                    type="button"
                                    className="close m-0"
                                    data-dismiss="modal"
                                    aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <button id="dismiss-modal" type="button" className="closes" data-dismiss="modal" aria-label="Close">
                                    <FormattedMessage id="Close" defaultMessage="Close" />
                                </button>
                                <button style={{ display: 'none' }} className="btn-black active-all" id="popup-click-sucess" data-toggle="modal" data-target="#popup-sucess">
                                    <FormattedMessage id="pop_up" defaultMessage="pop up" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default PopupSucess