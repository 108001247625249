import React, { Fragment, useEffect, useState } from 'react';
import { url } from '../../config';
import { Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import { subscription_expire } from '../../lang/alert_messages';

const PlanProtectedRoute = ({ component: Component, ...rest }) => {

    const alert = useAlert();
    var { user } = useSelector(state => state.user)
    if (!user) {
        user = JSON.parse(sessionStorage.getItem('user'));
    }
    return (
        <Fragment>
            <Route
                {...rest}
                render={props => {
                    if (localStorage.getItem('isAuthenticated') === null) {
                        return <Redirect to={url + "/login"} />
                    } else {
                        // if (user && user.is_valid_plan == 1) {
                        //     return <Component {...props} />
                        // } else {
                        //     if (user?.is_business_step == 0) {
                        //         alert.error('Please setup your buisness profile first.');
                        //         return <Redirect to={url + "/profile"} />
                        //     } else if (!user) {
                        //         return <Component {...props} />
                        //     } else {
                        //         alert.error('Your plan is expired, kindly purchase new plan.');
                        //         return <Redirect to={url + "/plan-details"} />
                        //     }
                        //     //     if(!user){
                        //     //         return <Component {...props} />
                        //     //     }
                        //     //    alert.error('Your plan is expired, kindly purchase new plan.');
                        //     //    return <Redirect to={url + "/plan-details"} />
                        // }

                        //Handle Freemium plan
                        if (user?.is_business_step == 0) {
                            alert.error('Please setup your buisness profile first.');
                            return <Redirect to={url + "/profile"} />
                        }
                        else if (user?.is_valid_plan == 0  && user?.role == 2 ) {
                            alert.error(subscription_expire,{
                                timeout:10000
                            });
                            return <Redirect to={url + "/plan-details"} />
                              // window.location.href = url + '/plan-details'
                        }
                        else {
                            return <Component {...props} />
                        }
                    }
                }}
            />
        </Fragment>
    )
}

export default PlanProtectedRoute