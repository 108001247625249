import React, { Fragment, useState, useEffect } from 'react';
import { IMAGES } from '../../../constants';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router';
import { useAlert } from "react-alert";
import { getAttributes, addProduct, GetProductCount, clearMessage, GetModelAttribute } from "../../../actions/productActions";
import { validateFields } from '../../../validation';
import classnames from 'classnames';
import { Year } from '../../../helper';
import { FormattedMessage } from 'react-intl';
import Loader from '../../layout/Loader';
import $ from 'jquery';
import PopupSucess from '../../layout/PopupSucess';
import { getCategory } from '../../../actions/categoryAction';
import { url } from '../../../config';
import { Link } from 'react-router-dom';
const queryString = require('query-string');

const AddWatches = () => {

    const dispatch = useDispatch()
    const alert = useAlert()
    const image1 = [];
    const video1 = {};
    const location = useLocation();
    const [images, setImages] = useState()
    const [video, setVideo] = useState()

    const { data, message, error, loading } = useSelector(state => state.productAdd)

    useEffect(() => {
        if (data) {
            $("#popup-click-sucess").trigger('click');
            //alert.success(message);
            dispatch(clearMessage());
            setAddProduct({
                category_slug: parsed && parsed.cat,
                brand: "",
                model: "",
                strap_color: "",
                case_size_mm: "",
                year: "",
                papers_box: "",
                w_condition: "",
                gender: "",
                reference: "",
                movement: "",
                functions: "",
                dial_numerals: "",
                dial_type: "",
                case_material: "",
                bezel_material: "",
                comment: "",
                price: "",
                stock_number: "",
                strap_material: "",
                images: "",
                description: ""
            })

            setValidError({
                stock_numberError: "",
                brandError: "",
                modelError: "",
                strap_colorError: "",
                case_sizeError: "",
                yearError: "",
                papers_boxError: "",
                conditionError: "",
                genderError: "",
                movementError: "",
                functionsError: "",
                dial_numeralsError: "",
                dial_typeError: "",
                case_materialError: "",
                bezel_materialError: "",
                priceError: "",
                strap_materialError: ""
            })

            setImages("")
            window.location = url + '/myproducts/watches';
        }
        if (error) {
            alert.error(error)
        }
    }, [alert, error, message, data])


    const parsed = queryString.parse(location.search);
    const attributesData = useSelector(state => state.attributes)
    const { modelattribute } = useSelector(state => state.ModelAttribute);
    useEffect(() => {
        dispatch(getAttributes(parsed && parsed.cat))
        dispatch(GetModelAttribute('watches', [252]))
    }, [dispatch])

    const [addproduct, setAddProduct] = useState({
        category_slug: parsed && parsed.cat,
        brand: "",
        model: "",
        strap_color: "",
        case_size_mm: "",
        year: "",
        papers_box: "",
        w_condition: "",
        gender: "",
        reference: "",
        movement: "",
        functions: "",
        dial_numerals: "",
        dial_type: "",
        case_material: "",
        bezel_material: "",
        comment: "",
        price: "",
        stock_number: "",
        strap_material: "",
        images: ""
    })

    const [validerror, setValidError] = useState({
        stock_numberError: "",
        brandError: "",
        modelError: "",
        strap_colorError: "",
        case_sizeError: "",
        yearError: "",
        papers_boxError: "",
        conditionError: "",
        genderError: "",
        movementError: "",
        functionsError: "",
        dial_numeralsError: "",
        dial_typeError: "",
        case_materialError: "",
        bezel_materialError: "",
        priceError: "",
        strap_materialError: ""
    });

    const handleInputChange = (event) => {
        setValidError({
            stock_numberError: "",
            brandError: "",
            modelError: "",
            strap_colorError: "",
            case_sizeError: "",
            yearError: "",
            papers_boxError: "",
            conditionError: "",
            genderError: "",
            movementError: "",
            functionsError: "",
            dial_numeralsError: "",
            dial_typeError: "",
            case_materialError: "",
            bezel_materialError: "",
            priceError: "",
            strap_materialError: ""
        })
        setAddProduct({ ...addproduct, [event.target.name]: event.target.value })
    }

    const handleBrandChange = (event) => {
        const { name, value } = event?.target;
        setValidError({
            brandError: "",
        })
        setAddProduct({ ...addproduct, [name]: value })
        dispatch(GetModelAttribute('watches', [value]))
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const stock_numberError = validateFields.validateStock_Number(addproduct.stock_number);
        const brandError = validateFields.validateBrand(addproduct.brand);
        const modelError = validateFields.validateModel(addproduct.model);
        const strap_colorError = validateFields.validateStrapColor(addproduct.strap_color);
        const case_sizeError = validateFields.validateCaseSize(addproduct.case_size_mm);
        const yearError = validateFields.validateYear(addproduct.year);
        const papers_boxError = validateFields.validatePapers(addproduct.papers_box);
        const conditionError = validateFields.validateCondition(addproduct.w_condition);
        const genderError = validateFields.validateGender(addproduct.gender);
        const movementError = validateFields.validateMovement(addproduct.movement);
        const functionsError = validateFields.validateFunction(addproduct.functions);
        const dial_numeralsError = validateFields.validateDialNumeral(addproduct.dial_numerals);
        const dial_typeError = validateFields.validateDialType(addproduct.dial_type);
        const case_materialError = validateFields.validateCaseMaterial(addproduct.case_material);
        const bezel_materialError = validateFields.validateBezelMaterial(addproduct.bezel_material);
        const priceError = validateFields.validatePrice(addproduct.price);
        const strap_materialError = validateFields.validateStrapMaterial(addproduct.strap_material);

        if ([stock_numberError, brandError, modelError, strap_colorError, case_sizeError, yearError, papers_boxError, conditionError, genderError, movementError, functionsError, dial_numeralsError, dial_typeError, case_materialError, bezel_materialError, priceError, strap_materialError].every(e => e === false)) {
            setValidError({
                ...validerror, stock_numberError, brandError, modelError, strap_colorError, case_sizeError, yearError, papers_boxError, conditionError, genderError, movementError, functionsError, dial_numeralsError, dial_typeError, case_materialError, bezel_materialError, priceError, strap_materialError
            })
            await dispatch(addProduct(addproduct));
            dispatch(GetProductCount());
            dispatch(getCategory());
        }
        else {
            setValidError({
                ...validerror, stock_numberError, brandError, modelError, strap_colorError, case_sizeError, yearError, papers_boxError, conditionError, genderError, movementError, functionsError, dial_numeralsError, dial_typeError, case_materialError, bezel_materialError, priceError, strap_materialError
            })
        }
        return;
    }

    //images functionality
    const image_select = () => {
        var image = document.getElementById('image').files;
        for (let i = 0; i < image.length; i++) {
            if (check_duplicate(image[i].name)) {
                image1.push({
                    "name": image[i].name,
                    "url": URL.createObjectURL(image[i]),
                    "file": image[i],
                    "size": image[i].size
                })
            } else {
                alert.error(image[i].name + " is already added to the list");
            }
        }
        setImages(image1);
        setAddProduct({ ...addproduct, images: image });
    }

    const video_select = () => {
        var video = document.getElementById('video_upload').files;
        var videoUp = document.getElementById('video_upload').files[0];

        if (video[0]) {
            video1.name = video[0].name
            video1.url = URL.createObjectURL(video[0])
            video1.file = video[0]
            video1.size = video[0].size

            setVideo(video1);

            var videor = document.getElementById('video');
            var source = document.createElement('source');
            source.setAttribute('src', video1.url);
            source.setAttribute('type', 'video/mp4');
            videor.load();
            // $('.videoUploadDiv').css('display', 'none');
            // $('.videoEdits').css('display', 'block');
            setAddProduct({ ...addproduct, video: videoUp });
        }
    }

    const check_duplicate = (name) => {
        var image = true;
        if (images && images.length > 0) {
            for (let e = 0; e < images.length; e++) {
                if (images[e].name == name) {
                    image = false;
                    break;
                }
            }
        }
        return image;
    }

    const delete_image = (index) => {
        setImages(images.filter((x, i) => i !== index));

        const dt = new DataTransfer();
        for (let file of addproduct.images) {
            if (file !== addproduct.images[index])
                dt.items.add(file)
        }
        setAddProduct({ ...addproduct, images: dt.files });
    }

    const delete_video = (index) => {
        setVideo(null);
        // $('.videoUploadDiv').css('display', 'block');
        // $('.videoEdits').css('display', 'none');
        $('#video_upload').val('');
        setAddProduct({ ...addproduct, video: null });
    }

    return (
        <Fragment>
            {(loading || attributesData?.loading) && <Loader />}
            <Fragment>
                <h3 className="forms">
                    <FormattedMessage
                        id="addsingleproduct.add-a-single"
                        defaultMessage="Add a Single"
                    />
                    <span>{` `}</span>
                    <FormattedMessage
                        id="addsingleproduct.watch"
                        defaultMessage="Watch"
                    />
                </h3>
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="">
                                    <FormattedMessage
                                        id="addsingleproduct.stockno"
                                        defaultMessage="Stock No."
                                    />
                                    <span className="strike_red">*</span></label>
                                <input
                                    className={classnames("form-control",
                                        { 'is-valid': validerror.stock_numberError === false },
                                        { 'is-invalid': validerror.stock_numberError })}
                                    type="text"
                                    placeholder="Enter Stock No."
                                    onChange={handleInputChange}
                                    name="stock_number"
                                    value={addproduct.stock_number}
                                />
                                <p className="invalid-feedback">{validerror.stock_numberError}</p>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="">
                                    <FormattedMessage
                                        id="addsingleproduct.brand"
                                        defaultMessage="Brand"
                                    />
                                    <span className="strike_red">*</span></label>
                                <select
                                    className={classnames("form-control",
                                        { 'is-valid': validerror.brandError === false },
                                        { 'is-invalid': validerror.brandError })}
                                    onChange={handleBrandChange}
                                    name="brand"
                                    value={addproduct?.brand}
                                >
                                    <FormattedMessage id="addsingleproduct.choosebrand" defaultMessage="Choose">
                                        {(id) => <option value="">{id}</option>}
                                    </FormattedMessage>
                                    {
                                        attributesData?.attributes?.brand?.map((brand) => {
                                            return (
                                                <option value={brand?.id}>{brand?.name}</option>
                                            )
                                        })
                                    }
                                </select>
                                <p className="invalid-feedback">{validerror.brandError}</p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="">
                                    <FormattedMessage
                                        id="addsingleproduct.model"
                                        defaultMessage="Model"
                                    />
                                    <span className="strike_red">*</span></label>
                                <select className={classnames("form-control", { 'is-valid': validerror.modelError === false }, { 'is-invalid': validerror.modelError })} onChange={handleInputChange} name="model" value={addproduct.model}>
                                    <FormattedMessage id="addsingleproduct.choosemodel" defaultMessage="Choose">
                                        {(id) => <option value="">{id}</option>}
                                    </FormattedMessage>
                                    {

                                        modelattribute && modelattribute.model && modelattribute.model.map((model) => {
                                            return (
                                                <option value={model.id}>{model.name}</option>
                                            )
                                        })
                                    }
                                </select>
                                <p className="invalid-feedback">{validerror.modelError}</p>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="">
                                    <FormattedMessage
                                        id="addsingleproduct.casesize"
                                        defaultMessage="Case Size"
                                    />
                                    <span className="strike_red">*</span></label>
                                <input type="text" className={classnames("form-control", { 'is-valid': validerror.case_sizeError === false }, { 'is-invalid': validerror.case_sizeError })} placeholder="Enter Case Size(mm)" name="case_size_mm" onChange={handleInputChange} value={addproduct.case_size_mm} />
                                <p className="invalid-feedback">{validerror.case_sizeError}</p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="">
                                    <FormattedMessage
                                        id="addsingleproduct.year"
                                        defaultMessage="Year"
                                    />
                                    <span className="strike_red">*</span></label>
                                <select className={classnames("form-control", { 'is-valid': validerror.yearError === false }, { 'is-invalid': validerror.yearError })} onChange={handleInputChange} name="year" value={addproduct.year}>
                                    <FormattedMessage id="addsingleproduct.chooseyear" defaultMessage="Choose">
                                        {(id) => <option value="">{id}</option>}
                                    </FormattedMessage>
                                    {
                                        Year() && Year().map((year) => {
                                            return (
                                                <option value={year}>{year}</option>
                                            )
                                        })
                                    }
                                </select>
                                <p className="invalid-feedback">{validerror.yearError}</p>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="">
                                    <FormattedMessage
                                        id="addsingleproduct.papers-and-box"
                                        defaultMessage="Papers and Box"
                                    />
                                    <span className="strike_red">*</span></label>
                                <select className={classnames("form-control", { 'is-valid': validerror.papers_boxError === false }, { 'is-invalid': validerror.papers_boxError })} onChange={handleInputChange} name="papers_box" value={addproduct.papers_box}>
                                    <FormattedMessage id="addsingleproduct.choosepapers" defaultMessage="Choose">
                                        {(id) => <option value="">{id}</option>}
                                    </FormattedMessage>
                                    {
                                        attributesData?.attributes?.papers_box?.map((paperbox) => {
                                            return (
                                                <option value={paperbox.id}>{paperbox.name}</option>
                                            )
                                        })
                                    }
                                </select>
                                <p className="invalid-feedback">{validerror.papers_boxError}</p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="">
                                    <FormattedMessage
                                        id="addsingleproduct.condition"
                                        defaultMessage="Condition"
                                    />
                                    <span className="strike_red">*</span></label>
                                <select className={classnames("form-control", { 'is-valid': validerror.conditionError === false }, { 'is-invalid': validerror.conditionError })} onChange={handleInputChange} name="w_condition" value={addproduct.w_condition}>
                                    <FormattedMessage id="addsingleproduct.choosecondition" defaultMessage="Choose">
                                        {(id) => <option value="">{id}</option>}
                                    </FormattedMessage>
                                    {
                                        attributesData?.attributes?.condition?.map((condition) => {
                                            return (
                                                <option value={condition.id}>{condition.name}</option>
                                            )
                                        })
                                    }
                                </select>
                                <p className="invalid-feedback">{validerror.conditionError}</p>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="">
                                    <FormattedMessage
                                        id="addsingleproduct.gender"
                                        defaultMessage="Gender"
                                    />
                                    <span className="strike_red">*</span></label>
                                <select className={classnames("form-control", { 'is-valid': validerror.genderError === false }, { 'is-invalid': validerror.genderError })} onChange={handleInputChange} name="gender" value={addproduct.gender}>
                                    <FormattedMessage id="addsingleproduct.choosegender" defaultMessage="Choose">
                                        {(id) => <option value="">{id}</option>}
                                    </FormattedMessage>
                                    {
                                        attributesData?.attributes?.gender?.map((gender) => {
                                            return (
                                                <option value={gender.id}>{gender.name}</option>
                                            )
                                        })
                                    }
                                </select>
                                <p className="invalid-feedback">{validerror.genderError}</p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="">
                                    <FormattedMessage
                                        id="addsingleproduct.refrence"
                                        defaultMessage="Reference"
                                    />
                                </label>
                                <input className="form-control" type="text" placeholder="Reference No." onChange={handleInputChange} name="reference" value={addproduct.reference} />
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="">
                                    <FormattedMessage
                                        id="addsingleproduct.movement"
                                        defaultMessage="Movement"
                                    />
                                    <span className="strike_red">*</span></label>
                                <select className={classnames("form-control", { 'is-valid': validerror.movementError === false }, { 'is-invalid': validerror.movementError })} onChange={handleInputChange} name="movement" value={addproduct.movement}>
                                    <FormattedMessage id="addsingleproduct.choosemovement" defaultMessage="Choose">
                                        {(id) => <option value="">{id}</option>}
                                    </FormattedMessage>
                                    {
                                        attributesData?.attributes?.movement?.map((movement) => {
                                            return (
                                                <option value={movement.id}>{movement.name}</option>
                                            )
                                        })
                                    }
                                </select>
                                <p className="invalid-feedback">{validerror.movementError}</p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="">
                                    <FormattedMessage
                                        id="addsingleproduct.functions"
                                        defaultMessage="Functions"
                                    />
                                    <span className="strike_red">*</span></label>
                                <select className={classnames("form-control", { 'is-valid': validerror.functionsError === false }, { 'is-invalid': validerror.functionsError })} onChange={handleInputChange} name="functions" value={addproduct.functions}>
                                    <FormattedMessage id="addsingleproduct.choosefunctions" defaultMessage="Choose">
                                        {(id) => <option value="">{id}</option>}
                                    </FormattedMessage>
                                    {
                                        attributesData?.attributes?.functions?.map((functions) => {
                                            return (
                                                <option value={functions.id}>{functions.name}</option>
                                            )
                                        })
                                    }
                                </select>
                                <p className="invalid-feedback">{validerror.functionsError}</p>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="">
                                    <FormattedMessage
                                        id="addsingleproduct.dialnumerals"
                                        defaultMessage="Dial Numerals"
                                    />
                                    <span className="strike_red">*</span></label>
                                <select className={classnames("form-control", { 'is-valid': validerror.dial_numeralsError === false }, { 'is-invalid': validerror.dial_numeralsError })} onChange={handleInputChange} name="dial_numerals" value={addproduct.dial_numerals}>
                                    <FormattedMessage id="addsingleproduct.choosedialnumerals" defaultMessage="Choose">
                                        {(id) => <option value="">{id}</option>}
                                    </FormattedMessage>
                                    {
                                        attributesData?.attributes?.dial_numerals?.map((dialnumerals) => {
                                            return (
                                                <option value={dialnumerals.id}>{dialnumerals.name}</option>
                                            )
                                        })
                                    }
                                </select>
                                <p className="invalid-feedback">{validerror.dial_numeralsError}</p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="">
                                    <FormattedMessage
                                        id="addsingleproduct.dialtype"
                                        defaultMessage="Dial Type"
                                    />
                                    <span className="strike_red">*</span></label>
                                <select className={classnames("form-control", { 'is-valid': validerror.dial_typeError === false }, { 'is-invalid': validerror.dial_typeError })} onChange={handleInputChange} name="dial_type" value={addproduct.dial_type}>
                                    <FormattedMessage id="addsingleproduct.choosedialtype" defaultMessage="Choose">
                                        {(id) => <option value="">{id}</option>}
                                    </FormattedMessage>
                                    {
                                        attributesData?.attributes?.dial_type?.map((dialtype) => {
                                            return (
                                                <option value={dialtype.id}>{dialtype.name}</option>
                                            )
                                        })
                                    }
                                </select>
                                <p className="invalid-feedback">{validerror.dial_typeError}</p>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="">
                                    <FormattedMessage
                                        id="addsingleproduct.casematerial"
                                        defaultMessage="Case Material"
                                    />
                                    <span className="strike_red">*</span></label>
                                <select className={classnames("form-control", { 'is-valid': validerror.case_materialError === false }, { 'is-invalid': validerror.case_materialError })} onChange={handleInputChange} name="case_material" value={addproduct.case_material}>
                                    <FormattedMessage id="addsingleproduct.choosecasematerial" defaultMessage="Choose">
                                        {(id) => <option value="">{id}</option>}
                                    </FormattedMessage>
                                    {
                                        attributesData?.attributes?.case_material?.map((casematerial) => {
                                            return (
                                                <option value={casematerial.id}>{casematerial.name}</option>
                                            )
                                        })
                                    }
                                </select>
                                <p className="invalid-feedback">{validerror.case_materialError}</p>
                            </div>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="">
                                    <FormattedMessage
                                        id="addsingleproduct.bezelmaterial"
                                        defaultMessage="Bezel Material"
                                    />
                                    <span className="strike_red">*</span></label>
                                <select className={classnames("form-control", { 'is-valid': validerror.bezel_materialError === false }, { 'is-invalid': validerror.bezel_materialError })} onChange={handleInputChange} name="bezel_material" value={addproduct.bezel_material}>
                                    <FormattedMessage id="addsingleproduct.choosebezelmaterial" defaultMessage="Choose">
                                        {(id) => <option value="">{id}</option>}
                                    </FormattedMessage>
                                    {
                                        attributesData?.attributes?.bezel_material?.map((bazelmaterial) => {
                                            return (
                                                <option value={bazelmaterial.id}>{bazelmaterial.name}</option>
                                            )
                                        })
                                    }
                                </select>
                                <p className="invalid-feedback">{validerror.bezel_materialError}</p>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="">
                                    <FormattedMessage
                                        id="addsingleproduct.strapmaterial"
                                        defaultMessage="Strap Material"
                                    />
                                    <span className="strike_red">*</span></label>
                                <select className={classnames("form-control", { 'is-valid': validerror.strap_materialError === false }, { 'is-invalid': validerror.strap_materialError })} onChange={handleInputChange} name="strap_material" value={addproduct.strap_material}>
                                    <FormattedMessage id="addsingleproduct.choosestrapmaterial" defaultMessage="Choose">
                                        {(id) => <option value="">{id}</option>}
                                    </FormattedMessage>
                                    {
                                        attributesData?.attributes?.strap_material?.map((strapmaterial) => {
                                            return (
                                                <option value={strapmaterial.id}>{strapmaterial.name}</option>
                                            )
                                        })
                                    }
                                </select>
                                <p className="invalid-feedback">{validerror.strap_materialError}</p>
                            </div>
                        </div>
                    </div>



                    <div className="row">
                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="">
                                    <FormattedMessage
                                        id="addsingleproduct.strapcolor"
                                        defaultMessage="Strap Color"
                                    />
                                    <span className="strike_red">*</span></label>
                                <select className={classnames("form-control", { 'is-valid': validerror.strap_colorError === false }, { 'is-invalid': validerror.strap_colorError })} onChange={handleInputChange} name="strap_color" value={addproduct.strap_color}>
                                    <FormattedMessage id="addsingleproduct.choosestrapcolor" defaultMessage="Choose">
                                        {(id) => <option value="">{id}</option>}
                                    </FormattedMessage>
                                    {
                                        attributesData?.attributes?.strap_color?.map((strapcolor) => {
                                            return (
                                                <option value={strapcolor.id}>{strapcolor.name}</option>
                                            )
                                        })
                                    }
                                </select>
                                <p className="invalid-feedback">{validerror.strap_colorError}</p>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="">
                                    <FormattedMessage
                                        id="addsingleproduct.price"
                                        defaultMessage="Price"
                                    />
                                    <span className="strike_red">*</span></label>
                                <input className={classnames("form-control", { 'is-valid': validerror.priceError === false }, { 'is-invalid': validerror.priceError })} type="text" placeholder="Enter Price" onChange={handleInputChange} name="price" value={addproduct.price} />
                                <p className="invalid-feedback">{validerror.priceError}</p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-8">
                            <div className="form-group">
                                <label htmlFor="">Description</label>
                                <textarea rows="3" className="form-control" type="text" placeholder="Description" onChange={handleInputChange} name="description" value={addproduct.description}></textarea>
                            </div>
                        </div>
                    </div>



                    <div className="row">
                        <div className="col-lg-8">
                            <div className="form-group">
                                <label htmlFor="">
                                    <FormattedMessage
                                        id="addsingleproduct.comment"
                                        defaultMessage="Supplier Comment (optional)"
                                    />
                                </label>
                                <textarea placeholder="Comment" rows="5" className="form-control" onChange={handleInputChange} name="comment" value={addproduct.comment}></textarea>
                            </div>
                        </div>
                    </div>

                    <h3 className="forms toBrdr">
                        <FormattedMessage
                            id="addsingleproduct.uploadimage"
                            defaultMessage="Upload Product Photos or Videos"
                        />
                        <p><FormattedMessage
                            id="addsingleproduct.supported-image"
                            defaultMessage="JPEG, PNG supported, max 10mb per image"
                        /></p>
                    </h3>

                    {
                        images && images.map((data, key) => {
                            return (
                                <div className="row imgEdits mb-4">
                                    <div className="col-md-4 imgBlock">
                                        <img className="w-100" src={data.url} alt="" />
                                        <div className="hover">
                                            <img onClick={(e) => delete_image(key)} src={IMAGES.CLOSE_WHITE} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <h4>{data.name}</h4>
                                        <p className="mb-10"><FormattedMessage
                                            id="addsingleproduct.ImageUploaded"
                                            defaultMessage="Image Uploaded"
                                        />  ({data.size / 1024}kb)</p>
                                    </div>
                                </div>
                            )
                        })
                    }


                    <div className="row imgEdits mb-4">
                        <div className="col-md-4">
                            <div className="moreUpload">
                                <span><img src={IMAGES.DOWNLOAD_SVG} alt="" /></span>
                                <FormattedMessage
                                    id="addsingleproduct.drop-photo"
                                    defaultMessage="Drop Photos"
                                />

                                <input type="file" name="Image" id="image" multiple onChange={image_select} />
                            </div>
                        </div>
                    </div>

                    {/* video Code start */}
                    <h3 className="forms toBrdr">
                        <FormattedMessage
                            id="addsingleproduct.uploadvideo"
                            defaultMessage="Upload Product Video"
                        />
                        <p><FormattedMessage
                            id="addsingleproduct.Mp4supported"
                            defaultMessage="Mp4 supported"
                        /></p>
                    </h3>
                    {
                        video ?
                            <div className="col-md-4 imgEdits">
                                <div className="row imgBlock videoEdits mb-4">
                                    <div className="close-vid hover">
                                        <img onClick={() => delete_video(0)} src={IMAGES.CLOSE_WHITE} alt="" />
                                    </div>
                                    <video id="video" style={{ 'max-width': '100%' }} >
                                        <source src={video?.url} type="video/mp4" />
                                        <FormattedMessage
                                            id="addsingleproduct.YourBrowserNotSupport"
                                            defaultMessage="Your browser does not support the video tag."
                                        />
                                    </video>
                                </div>

                            </div>
                            :
                            <div className="row imgEdits mb-4 videoUploadDiv">
                                <div className="col-md-4">
                                    <div className="moreUpload">
                                        <span><img src={IMAGES.DOWNLOAD_SVG} alt="" /></span>
                                        <FormattedMessage
                                            id="addsingleproduct.drop-videos"
                                            defaultMessage="Drop Video"
                                        />
                                        <input type="file" name="video_upload" id="video_upload" onChange={video_select} />
                                    </div>
                                </div>
                            </div>
                    }
                    {/* {
                        <div className="row imgEdits videoEdits mb-4" style={{ 'display': 'none' }}>
                            <div className="col-md-4 imgBlock">
                                <video id="video" >
                                    <source src={video && video?.url} type="video/mp4" />
                                    <FormattedMessage
                                        id="addsingleproduct.YourBrowserNotSupport"
                                        defaultMessage="Your browser does not support the video tag."
                                    />
                                </video>
                                <div className="hover">
                                    <img onClick={(e) => delete_video(0)} src={IMAGES.CLOSE_WHITE} alt="" />
                                </div>
                            </div>
                        </div>
                    }
                    <div className="row imgEdits mb-4 videoUploadDiv">
                        <div className="col-md-4">
                            <div className="moreUpload">
                                <span><img src={IMAGES.DOWNLOAD_SVG} alt="" /></span>
                                <FormattedMessage
                                    id="addsingleproduct.drop-videos"
                                    defaultMessage="Drop Video"
                                />
                                <input type="file" name="video_upload" id="video_upload" onChange={video_select} />
                            </div>
                        </div>
                    </div> */}

                    <div className="row actions my-5">
                        <div className="col-md-6">
                            <Link className="btns outline mb-2 mr-2" style={{ "textAlign": 'center' }} to={url + "/myproducts/diamonds"}><FormattedMessage
                                id="addsingleproduct.cancel"
                                defaultMessage="Cancel"
                            /></Link>

                            <button type="submit" className="btn btn-blue mb-2">
                                <FormattedMessage
                                    id="addsingleproduct.addproduct"
                                    defaultMessage="Add Products"
                                />
                            </button>
                        </div>
                    </div>
                </form>
            </Fragment>
            {
                data &&
                <PopupSucess title={'SUCCESS'} props={data} />
            }
        </Fragment>
    )
}

export default AddWatches
