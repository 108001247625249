export const currentLanguage = localStorage.getItem("lang");

let subscription_expire;
if (currentLanguage == "en") {
    subscription_expire = "Your subsctiption has been expired. This could be due to your credit card limit. To continue using the system, you can either log in to your accout from this link https://www.cevaheer.com/login or you can contact our sales team by calling 530 235 9078.";

    
}

if (currentLanguage == "tr") {
    subscription_expire = "Aboneliğiniz sona ermiştir. Bu durum kredi kartı limitinizden kaynaklanıyor olabilir. Sistemi kullanmaya devam etmek için, https://www.cevaheer.com/login bağlantısından hesabınıza giriş yapabilir. Veya 530 235 9078 numaralı telefonu arayarak satış ekibimizle iletişime geçebilirsiniz.";
}

export { subscription_expire }