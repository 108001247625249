import React, {useEffect} from 'react';
import ProtectedHeader from './ProtectedHeader';
import ProtectedNav from './ProtectedNav';
import { useSelector, useDispatch } from 'react-redux';
import { getCategory } from '../../../actions/categoryAction';
import { useAlert } from 'react-alert';
import { logout } from '../../../actions/userActions';
import { url } from '../../../config';
const Index = () => {

    const dispatch = useDispatch();
    const alert = useAlert();

    const { category } = useSelector(state => state.categories);

    useEffect(() => {
        if(!category)
        {
            const is_approved = sessionStorage.getItem('user') && JSON.parse(sessionStorage.getItem('user')) && JSON.parse(sessionStorage.getItem('user')).is_approved && JSON.parse(sessionStorage.getItem('user')).is_approved;
            if(is_approved==0){
                dispatch(logout());
                localStorage.removeItem('isAuthenticated');
                sessionStorage.removeItem('user');
                alert.success('Your application is under review we will reach out to you in the next 24 hours with the result of the authentication process. Thanks')
                window.location.href = url;
            }
       
            dispatch(getCategory());
        }
    }, [category,sessionStorage.getItem('user')])


    return (
        <header className="bg-semis">
            <ProtectedHeader />
            <ProtectedNav />
        </header>
    )
}

export default Index
