import React, { Fragment, useEffect } from 'react';
import { url } from '../../config';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useState } from 'react';
import { useLocation } from 'react-router';
import { getLastURL } from '../../helper';
import { useAlert } from 'react-alert';
import { subscription_expire } from '../../lang/alert_messages';

const PlanDetailRoute = ({ component: Component, ...rest }) => {
    var { user } = useSelector(state => state.user)
    const location = useLocation();
    const pathname = getLastURL(location);
    const alert = useAlert();
    const history = useHistory()
    if (!user) {
        user = JSON.parse(sessionStorage.getItem('user'));
    }

    useEffect(() => {
        if (user?.is_business_step == 0 && (pathname != 'profile' && pathname != 'business-info')) {
            history.push(url + '/profile')
            alert.error('Please setup your buisness profile first.');
        }
    }, [pathname])

    return (
        <Fragment>
            <Route
                {...rest}
                render={props => {
                    if (localStorage.getItem('isAuthenticated') === null) {
                        return <Redirect to={url + "/login"} />
                    }
                    else {
                        return <Component {...props} />
                    }
                }}
            />
        </Fragment>
    )
}

export default PlanDetailRoute
