import React, { Fragment, useState } from 'react';
import AddComment from '../diamonds/AddComment';
import { useSelector, useDispatch } from 'react-redux';
import ReactStars from "react-rating-stars-component";
import { convertLocaleDate } from '../../../helper';
import { getReviewAction } from '../../../actions/userActions';
import { useLocation } from 'react-router';
import $ from 'jquery';
import { getLastURL } from '../../../helper';
import ReactPaginate from "react-paginate";
import { FormattedMessage } from 'react-intl';
import Loader from '../../layout/Loader';

let count = 1;
const Rating = () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const product_id = getLastURL(location);

    const { details } = useSelector(state => state.productDetails);
    const { allReview, loading } = useSelector(state => state.AllReview);
    const { user } = useSelector(state => state.user);

    const [currentPage, setCurrentPage] = useState(0);
    const [postsPerPage, setPostPerPage] = useState(2);

    const handlePageChange = ({ selected }) => {
        dispatch(getReviewAction(product_id, null, (selected + 1)))
    }

    const pageCount = Math.ceil((allReview && allReview.data && allReview.data.length) / postsPerPage);

    return (
        <Fragment>
            {loading && <Loader />}
            <section className="rating pt-4 " id="ratings-sec" >
                <div className="container-fluid">
                    <div className="rating_top d-flex justify-content-between">
                        <div className="TitleBtns">
                            <h4 className="font-20 black fb mb-2"><FormattedMessage
                                id="CustomerRatings"
                                defaultMessage="Customer Ratings"
                            /></h4>
                            <div className="d-flex hwerating">
                                <h6>{details && details.sellerDetails && details.sellerDetails?.business?.company_name}</h6>
                                <div className="mb-4 average-rating d-flex align-items-center">
                                    <i style={{ color: "#003B75" }} className='fa fa-star'></i>{details && details.productDetails && details.productDetails.average_rating && details.productDetails.average_rating.toFixed(1)}({details && details.productDetails && details.productDetails.total_rating && details.productDetails.total_rating} <FormattedMessage
                                        id="ratings"
                                        defaultMessage="ratings"
                                    />)
                                    {
                                        details && details.productDetails.seller_id !== user.id &&
                                        <button className="btn" data-toggle="modal" data-target="#comment"><FormattedMessage
                                            id="addsingleproduct.WriteAReview"
                                            defaultMessage="Write a review"
                                        /></button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row  mt-4">
                        {
                            allReview && allReview.data && allReview.data.map((data, key) => {
                                return (
                                    <div className="col-lg-3 col-md-4 col-sm-4  ">
                                        <div className="blcks p-3">
                                            {data.is_anonymous == 1 && <h5>{data.name}</h5>}
                                            {data.heading && <h6>{data.heading}</h6>}
                                            <div className="clearfix"></div>
                                            <p>{data.comment}</p>
                                            <div className="date_list d-flex justify-content-between pt-3">
                                                <span className="float-right">{convertLocaleDate(data.created_at)}</span>
                                                <span><i style={{ color: "#003B75" }} className='fa fa-star'></i> &nbsp;
                                                    {data && data.rating && data.rating.toFixed(1)}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="table_pagination">
                        <ReactPaginate
                            previousLabel={<i className="icon icon-chevron_gray-left"></i>}
                            nextLabel={<i className="icon icon-chevron_black"></i>}
                            pageCount={pageCount}
                            onPageChange={handlePageChange}
                            containerClassName={"paginationBttns"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"paginationDisabled"}
                            activeClassName={"paginationActive"}
                        />
                    </div>
                </div>
            </section>
            <AddComment />
        </Fragment>
    )
}

export default Rating
