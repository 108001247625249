import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import $ from 'jquery';
import { FormattedMessage } from 'react-intl';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import DiamondValidation from '../../../validation/DimondValidation';

const DiamondModal = ({ singleExcelData, handleEditExcelForm, setShowDiamond }) => {
    const formInputs = {
        shape: "",
        id: "",
        category_slug: "",
        carat: "",
        color: "",
        clarity: "",
        cut: "",
        polish: "",
        symmetry: "",
        milky: "",
        availability_status: "",
        bgm: "",
        fluorescence: "",
        table_per: "",
        depth_per: "",
        mm_h: "",
        mm_l: "",
        mm_w: "",
        culet: "",
        certificate: "",
        certificate_no: "",
        discount: "",
        comment: "",
        price: "",
        stock_no: "",
        diamond_3d_link: "",
        fancy_color: "",
        fancy_intensity: "",
        fancy_overtone: "",
        is_fancy: "",
    }

    const { register, setValue, watch, handleSubmit, formState: { errors } } = useForm({
        // resolver: yupResolver(DiamondValidation),
        defaultValues: formInputs
    })

    useEffect(() => {
        if (singleExcelData) {
            if (singleExcelData?.measurement) {
                const [mm_l, mm_w, mm_h] = singleExcelData?.measurement?.split(' x ');
                setValue("mm_l", mm_l ? mm_l : "")
                setValue("mm_w", mm_w ? mm_w : "")
                setValue("mm_h", mm_h ? mm_h : "")
            }

            /*
carat:1
certificate:"HRD"
certificate_no:210000073391
city:"Fatih"
clarity:"VS2"
color:"G"
comment:"Very shiny stone"
country:"Turkey"
cut:"Good"
depth:59.6
discount:-30
fancy_color:"Red"
fancy_intensity:"Faint"
fancy_overtone:"Blue"
fluorescence:"None"
image_1:"https://cdn.pixabay.com/photo/2021/04/14/11/04/eye-6178082_960_720.jpg"
image_2:"https://cdn.pixabay.com/photo/2021/04/24/11/53/fashion-6203956_960_720.jpg"
image_3:"https://cdn.pixabay.com/photo/2021/09/10/04/24/sunset-6612075_960_720.jpg"
image_4:"https://cdn.pixabay.com/photo/2020/11/08/17/43/lemon-5724522_960_720.jpg"
image_5:"https://cdn.pixabay.com/photo/2019/05/07/09/27/toucan-4185361_960_720.jpg"
measurement:"10.10 x 10.50 x 4.40"
milky:"Heavy"
no_bgm:"No"
pieces:200
polish:"Ideal"
pricect:5000
shape:"Pear"
sieve:0
state:"Istanbul"
status:"On Memo"
stock_no:"XR02365345"
symmetry:"Fair"
table:59
video:"14.39.53.mp4"
             */
            //TODO: set above keys below.
            setValue("shape", singleExcelData?.shape)
            setValue("carat", singleExcelData?.carat)
            setValue("color", singleExcelData?.color)
            setValue("clarity", singleExcelData?.clarity)
            setValue("cut", singleExcelData?.cut)
            setValue("polish", singleExcelData?.polish)
            setValue("symmetry", singleExcelData?.symmetry)
            setValue("milky", singleExcelData?.milky)
            setValue("availability_status", singleExcelData?.status)
            setValue("bgm", singleExcelData?.no_bgm == "Yes")
            setValue("fluorescence", singleExcelData?.fluorescence)
            setValue("table_per", singleExcelData?.table)
            setValue("depth_per", singleExcelData?.depth)
            setValue("certificate", singleExcelData?.certificate)
            setValue("certificate_no", singleExcelData?.certificate_no)
            setValue("discount", singleExcelData?.discount)
            setValue("price", singleExcelData?.price)
            setValue("comment", singleExcelData?.comment)
            setValue("stock_no", singleExcelData?.stock_no)
            setValue("fancy_color", singleExcelData?.fancy_color)
            setValue("fancy_intensity", singleExcelData?.fancy_intensity)
            setValue("fancy_overtone", singleExcelData?.fancy_overtone)
            setValue("diamond_3d_link", singleExcelData?.diamond_3d_link)
        }
    }, [singleExcelData])

    const [validerror, setValidError] = useState({
        stock_noError: "",
        shapeError: "",
        caratError: "",
        colorError: "",
        clarityError: "",
        certificateError: "",
        milkyError: "",
        availabilityStatusError: "",
        priceError: "",
        depth_perError: "",
        mm_l_Error: "",
        mm_h_Error: "",
        mm_w_Error: "",
        table_perError: "",
    });

    console.log("singleExcelData:", singleExcelData);

    const onSubmit = (payload) => {
        console.log("payload:", payload);
        handleEditExcelForm(payload)
        setShowDiamond(false)
    }

    return (
        <>
            <div className='custom-com-modal diamond-modal'>
                <div className='custom-modal-inner'>
                    <div className='custom-modal-body'>
                        <div className='custom-modal-header'>
                            <button className='close-custom-modal' type='button' onClick={() => setShowDiamond(false)}><i className='fa fa-times'></i></button>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="">
                                            <FormattedMessage
                                                id="addsingleproduct.stockno"
                                                defaultMessage="Stock No."
                                            />
                                            <span className="strike_red">*</span></label>
                                        <input type="hidden" name="id" />
                                        <input type="hidden" name="category_slug" value="diamonds" />

                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder=" Enter Stock No."
                                            {...register("stock_no")}
                                            name="stock_no"
                                        />
                                        <p className="invalid-feedback">{validerror.stock_noError}</p>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="">
                                            <FormattedMessage
                                                id="addsingleproduct.carat"
                                                defaultMessage="Carat"
                                            />
                                            <span className="strike_red">*</span></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Carat"
                                            {...register("carat")}
                                        />
                                        <p className="invalid-feedback">{validerror.caratError}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="">
                                            <FormattedMessage
                                                id="addsingleproduct.shape"
                                                defaultMessage="Shape"
                                            />
                                            <span className="strike_red">*</span></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            {...register("shape")}
                                            name="shape"
                                        />

                                        <p className="invalid-feedback">{validerror.shapeError}</p>
                                    </div>
                                </div>
                                <div id="color" className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="">
                                            <FormattedMessage
                                                id="addsingleproduct.color"
                                                defaultMessage="Color"
                                            />
                                            <span className="strike_red">*</span></label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            {...register("color")}
                                            name="color"
                                        />
                                        <p className="invalid-feedback">{validerror.colorError}</p>
                                    </div>
                                </div>
                                <div id="fancy_color" style={{ display: 'none' }} className="col-lg-4">
                                    <div className="form-group">
                                        <label htmlFor="">
                                            <FormattedMessage
                                                id="addsingleproduct.fancycolor"
                                                defaultMessage="Fancy Color"
                                            />
                                        </label>
                                        <input type="text" className="form-control" {...register("fancy_color")} name="fancy_color" />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-4" id="fancy_color_intensity" style={{ display: 'none' }}>
                                    <div className="form-group">
                                        <label htmlFor="">
                                            <FormattedMessage
                                                id="addsingleproduct.fancycolorintensity"
                                                defaultMessage="Fancy Intensity"
                                            />
                                        </label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            {...register("fancy_intensity")}
                                            name="fancy_intensity"
                                        />
                                    </div>
                                </div>

                                <div id="fancy_color_overtone" style={{ display: 'none' }} className="col-lg-4">
                                    <div className="form-group">
                                        <label htmlFor="">
                                            <FormattedMessage
                                                id="addsingleproduct.fancycolorovertone"
                                                defaultMessage="Fancy Overtone"
                                            />
                                        </label>
                                        <input type="text" className="form-control" {...register("fancy_overtone")} name="fancy_overtone" />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="">
                                            <FormattedMessage
                                                id="addsingleproduct.clarity"
                                                defaultMessage="Clarity"
                                            />
                                            <span className="strike_red">*</span></label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            {...register("clarity")}
                                            name="clarity"
                                        />
                                        <p className="invalid-feedback">{validerror.clarityError}</p>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="">
                                            <FormattedMessage
                                                id="addsingleproduct.cut"
                                                defaultMessage="Cut"
                                            />
                                        </label>
                                        <input type="text" className="form-control" {...register("cut")} name="cut" />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="">
                                            <FormattedMessage
                                                id="addsingleproduct.polish"
                                                defaultMessage="Polish"
                                            />
                                        </label>
                                        <input type="text" className="form-control" {...register("polish")} name="polish" />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="">
                                            <FormattedMessage
                                                id="addsingleproduct.symmetry"
                                                defaultMessage="Symmetry"
                                            />
                                        </label>
                                        <input type="text" className="form-control" {...register("symmetry")} name="symmetry" />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="">
                                            <FormattedMessage
                                                id="addsingleproduct.fluorescence"
                                                defaultMessage="Fluorescence"
                                            />
                                        </label>
                                        <input type="text" className="form-control" {...register("fluorescence")} name="fluorescence" />
                                    </div>
                                </div>

                                <div className="col-md-6" id="white_diamond_milky">
                                    <div className="form-group">
                                        <label htmlFor="">
                                            <FormattedMessage
                                                id="addsingleproduct.milky"
                                                defaultMessage="Milky"
                                            />
                                            <span className="strike_red">*</span>
                                        </label>

                                        <input
                                            className="form-control"
                                            type="text"
                                            {...register("milky")}
                                            name="milky"
                                        />
                                        <p className="invalid-feedback">{validerror.milkyError}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="row white_diamond_bgm" >
                                <div className="col-lg-1 white_diamond_bgm">
                                    <div className="form-group">
                                        <label htmlFor="">
                                            <FormattedMessage
                                                id="addsingleproduct.bgm"
                                                defaultMessage="NO BGM"
                                            />
                                            <span className="strike_red">*</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-6 white_diamond_bgm">
                                    <div className="cus_switch d-flex align-item-center">
                                        {/* <p className="font-14 fm black-3B pr-3"><FormattedMessage
                                            id="addsingleproduct.No"
                                            defaultMessage="No"
                                        /></p> */}
                                        <label className="switch">
                                            <input type="checkbox" name="bgm" className='bgm' id="bgmCheckbox" />

                                            <span className="slider round"></span>
                                        </label>
                                        {/* <p className="gray-75 font-14 fm pl-3"> <FormattedMessage
                                            id="addsingleproduct.Yes"
                                            defaultMessage="Yes"
                                        /></p> */}
                                    </div>
                                </div>
                            </div>

                            <div className="row" >
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="">
                                            <FormattedMessage
                                                id="addsingleproduct.status"
                                                defaultMessage="Status"
                                            /><span className="strike_red">*</span>
                                        </label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            {...register("availability_status")}
                                            name="availability_status"
                                        />
                                        <p className="invalid-feedback">{validerror.availabilityStatusError}</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="">
                                            <FormattedMessage
                                                id="addsingleproduct.measurement"
                                                defaultMessage="Measurement"
                                            />
                                        </label>
                                        <div className="row">
                                            <div className="col-md-4 mb-md-0 mb-3">
                                                <input
                                                    type="text" className="form-control" placeholder="length" {...register("mm_l")} />
                                                <p className="invalid-feedback">{validerror.mm_l_Error}</p>
                                            </div>
                                            <div className="col-md-4 mb-md-0 mb-3">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="width"
                                                    {...register("mm_w")} />
                                                <p className="invalid-feedback">{validerror.mm_w_Error}</p>

                                            </div>
                                            <div className="col-md-4 mb-md-0 mb-0">
                                                <input
                                                    type="text" className="form-control" placeholder="depth" {...register("mm_h")} />
                                                <p className="invalid-feedback">{validerror.mm_h_Error}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="">
                                            <FormattedMessage
                                                id="addsingleproduct.table"
                                                defaultMessage="Table"
                                            /> %
                                        </label>
                                        <input type="text" className="form-control" placeholder="Enter Table %" {...register("table_per")} />
                                        <p className="invalid-feedback">{validerror.table_perError}</p>

                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="">
                                            <FormattedMessage
                                                id="addsingleproduct.depth"
                                                defaultMessage="Depth"
                                            />
                                            %</label>
                                        <input type="text" className="form-control" placeholder="Enter Depth %" {...register("depth_per")} />
                                        <p className="invalid-feedback">{validerror.depth_perError}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="">
                                            <FormattedMessage
                                                id="addsingleproduct.certificate"
                                                defaultMessage="Certificate"
                                            />
                                            <span className="strike_red">*</span></label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            {...register("certificate")}
                                            name="certificate"
                                        />
                                        <p className="invalid-feedback">{validerror.certificateError}</p>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="">
                                            <FormattedMessage
                                                id="addsingleproduct.certificate-no"
                                                defaultMessage="Cetificate No."
                                            />
                                        </label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="Enter Certificate No."
                                            {...register("certificate_no")}
                                            name="certificate_number"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6" id="discount">
                                    <div className="form-group">
                                        <label htmlFor="">
                                            <FormattedMessage
                                                id="addsingleproduct.discount"
                                                defaultMessage="Discount"
                                            />
                                            %  <span className="strike_red">*</span></label>
                                        <input
                                            className="form-control discount"
                                            type="text"
                                            placeholder="Enter Discount"
                                            {...register("discount")}
                                            disabled={watch("price")}
                                            name="discount"
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="">
                                            <FormattedMessage
                                                id="addsingleproduct.price-ct"
                                                defaultMessage="Price/ct"
                                            />
                                            <span className="strike_red">*</span></label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="Enter Price"
                                            {...register("price")}
                                            disabled={watch("discount")}
                                            name="price"
                                        />
                                        <p className="invalid-feedback">{validerror?.priceError}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label htmlFor="">
                                            <FormattedMessage
                                                id="addsingleproduct.comment"
                                                defaultMessage="Supplier Comment (optional)"
                                            />
                                        </label>
                                        <textarea
                                            placeholder="Comment"
                                            rows={5}
                                            className="form-control"
                                            {...register("comment")}
                                            name="comment"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label htmlFor="">
                                            <FormattedMessage
                                                id="addsingleproduct.add3dlink"
                                                defaultMessage="3d Link"
                                            />
                                        </label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="https://www.v360.in"
                                            // onChange={(e) => setAddProduct({ ...addproduct, diamond_3d_link: e?.target?.value })}
                                            {...register("diamond_3d_link")}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row actions my-5">
                                <div className="col-md-6">
                                    <button type="submit" className="btn btn-blue mb-2">
                                        <FormattedMessage
                                            id="Save"
                                            defaultMessage="Save"
                                        />
                                    </button>
                                </div>
                            </div>
                        </form >
                    </div >
                </div >
            </div >
        </>
    )
}

export default DiamondModal