import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeRequestGet } from '../../utils/apiHelper';
import Loader from '../layout/Loader';
import { Base64 } from "js-base64";
import ReactSrcDocIframe from 'react-srcdoc-iframe';

const IyzipayComponent = (props) => {
    const { cancelplan , error, loading  } = useSelector(state => state.cancelPlanRes)

    const [loadingP, setLoadingP] = useState(loading);
    const [data, setData] = useState([]);

     useEffect(async () => {
        setLoadingP(true)
        const reloadCount = sessionStorage.getItem('reloadCount');
        if(reloadCount < 1) {
        sessionStorage.setItem('reloadCount', String(reloadCount + 1));
        window.location.reload();
        } else {
        sessionStorage.removeItem('reloadCount');
        }
        const data = await makeRequestGet('payment?plan_id='+props.location.state.planid+'&user_id='+props.location.state.userid+'&plan_payment_period=false&is_upgrade=1');
        if(data){
            setTimeout(() => {

                setLoadingP(false)
            }, 1500);
        } 
        setData(Base64.decode(data.data.res));
        
        let newData = data && data.data && data.data.res.replace('<script type="text/javascript">', '')
        newData = newData.replace('</script>', '')
        let myScript = document.createElement('script');
        myScript.setAttribute('class', 'payment-script');
        myScript.innerHTML = newData;
        document.body.appendChild(myScript)
    },[])
    //componentDidMount
    // componentDidMount = () =>  {
    //     const reloadCount = sessionStorage.getItem('reloadCount');
    //     if(reloadCount < 2) {
    //       sessionStorage.setItem('reloadCount', String(reloadCount + 1));
    //       window.location.reload();
    //     } else {
    //       sessionStorage.removeItem('reloadCount');
    //     }
    //   }
    return (
        <Fragment>
        {loadingP && <Loader />}
            <div style={{margin:"50px"}}>
                <h1><center>Subscription Plan - <b>{props.location.state.planName}</b> </center></h1>
             </div>
             <div id="iyzipay-checkout-form" className="responsive"></div>
        </Fragment>
    )
}

export default IyzipayComponent
