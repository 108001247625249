import React, { Fragment, useEffect, useState } from 'react';
import { handleSortBy, pColmDisplay, GenerateCertificatePdf, getLastURL, numberWithCommas, capitalizeFirstLetterWithOnlyLetter, RatingDisplay, CompanyNameDisplay, totalPriceDisplay, handleSortByAction, getSearchQueryParams, totalPriceDisplayforDiscount, totalCarat } from '../../../helper';
import { useSelector, useDispatch } from 'react-redux';
import { url, postPerPage, hrdurl, giaurl } from '../../../config';
import { IMAGES, SEARCH_LIMIT_MSG } from '../../../constants';
import { useHistory } from 'react-router';
import Loader from '../../layout/Loader';
import ReactPaginate from "react-paginate";
import { useAlert } from 'react-alert';
import $ from 'jquery';
import { getProductsFilterByAscending, getAllProductsCompare, getAllProducts, getUrlToDownloadDocument } from '../../../actions/productActions';
import { Link } from 'react-router-dom';
import { getAllProductsFilter } from '../../../actions/productActions';
import { diamondsJson, listProductAction } from '../../../utils/json';
import { useLocation } from 'react-router';
import ReactStars from "react-rating-stars-component";
import parse from "html-react-parser";
import PopupConfirm from '../../layout/PopupConfirm';
import mixpanel from 'mixpanel-browser';
import { FormattedMessage, useIntl } from 'react-intl';
import ad_image from './../../../assets/images/cevaheer_ad1.png';
import ad_image1 from './../../../assets/images/ad_img_tr.jpg';
import { DOWNLOAD_FAIL } from '../../../constants/productConstants';

const WhiteDimonds = (props) => {

    const history = useHistory();
    const alert = useAlert();
    const dispatch = useDispatch();
    const location = useLocation();

    const category_slug = getLastURL(location);

    const handleDetails = (id) => {
        history.push(url + `/details/diamonds/${id}`);
    }

    const { products, error, loading } = useSelector(state => state.products);
    const documentDetailState = useSelector(state => state.downloadState);
    const productFilter = useSelector(state => state.DownloadPdfWithFilterState)
    const [getPdf, setGetPdf] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [postsPerPage, setPostPerPage] = useState(postPerPage);
    const [filterdisplayname, setFilterDisplayName] = useState(<FormattedMessage id="Select Sort Option" />);
    const [search, setSearch] = useState();
    const [selectAllCompare, setSelectAllCompare] = useState(new Array(documentDetailState?.documentDetail?.products?.data).fill(false));
    const [selectedId, setSelectedId] = useState([]);
    const [pageNumber, setPageNumber] = useState(props.pageNumber && props.pageNumber);

    const [popUpLimitTitle, setpopUpLimitTitle] = useState(<FormattedMessage id="Search Limit Finished" />);
    const [popUpLimitMessage, setpopUpLimitMessage] = useState(SEARCH_LIMIT_MSG);
    const [productsData, setproductsData] = useState();
    useEffect(() => {
        if (error) {
            alert.error(error);
            return;
        }
        if (documentDetailState?.documentDetail?.pdf_url) {
            if (productFilter?.data?.filter?.seller) {
                sessionStorage.setItem('isSellerFilter', true)
            }

            const link = document.createElement('a');
            link.target = '__blank';
            link.href = documentDetailState?.documentDetail?.pdf_url;
            // link.download = 'diamonds.pdf';
            document.body.appendChild(link);
            link.click();

            //reset state to prevent re-downloading.
            dispatch({
                type: DOWNLOAD_FAIL,
                payload: null
            })
        }
    }, [documentDetailState?.documentDetail])

    const handleResForm = (status) => {
        if (status) {
            $("#dismiss-modal").trigger('click');
            history.push({ pathname: url + '/plan-details' });
        }
    }

    const handlePageChange = ({ selected }) => {
        setPageNumber(selected)
        props.updateResetPageHandler(selected)
        dispatch(getAllProducts(category_slug, (selected + 1)));
        //setDisableInitialCallback(0)
    }
    console.log("products>>", products);

    const pageCount = Math.ceil((products?.products?.total) / postsPerPage);

    const handleAcendingFilter = (value, type, displayname) => {
        setFilterDisplayName(displayname)
        $('.sort_by_list').css({
            display: "none"
        })
        props.tabChangeHandler(1)

        const payload = { order_by_type: type, order_by_value: value }
        sessionStorage.setItem('discountDetails', JSON.stringify(payload))
        dispatch(getProductsFilterByAscending(category_slug, value, type))
    }

    const handleListFilter = (value, type, displayname) => {
        $('.sort_by_list').css({
            display: "none"
        })
        if (value == 'pdf') {
            //  setGetPdf(true)
            handleCompare(true, value)
        } else if (value === 'pdf_all' || value === 'excel_all') {
            handleCompare(true, value)
        } else {
            handleCompare(false)
        }
    }

    const handleSearch = (e) => {
        setSearch(e.target.value);
    }

    const handleSearchFilter = (e) => {
        mixpanel.track('Search Diamond')
        if (e.key === 'Enter') {
            dispatch(getAllProductsFilter(category_slug, { search: search }));
        }
    }
    const handleSearchIconClick = () => {
        mixpanel.track('Search Diamond');
        dispatch(getAllProductsFilter(category_slug, { search: search }));
    };

    const handleSelectCompare = (id, position) => {
        const updatedSelectAllCompare = selectAllCompare && selectAllCompare.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return item;
            }
        });
        setSelectAllCompare(updatedSelectAllCompare);

        if (!selectedId?.includes(id)) {
            setSelectedId([...selectedId, id]);
        } else {
            selectedId?.splice(selectedId?.indexOf(id), 1);
        }
    }

    const handleCompare = (getPdf, type) => {
        // if (selectedId?.length > 1 && type == 'compare') {
        //     return
        //     dispatch(getUrlToDownloadDocument(category_slug, selectedId, getPdf));
        //     setSelectAllCompare(new Array(products && products?.products && products?.products?.data && products?.products?.data?.length).fill(true))
        // }
        if (type === 'pdf_all' || type === 'excel_all') {
            // return dispatch(getAllProductsCompare(category_slug, selectedId, getPdf, type));
            return dispatch(getUrlToDownloadDocument(category_slug, [], getPdf, type, productFilter?.data?.filter));
        }
        if (selectedId?.length > 0) {
            dispatch(getUrlToDownloadDocument(category_slug, selectedId, getPdf));
            setSelectAllCompare(new Array(selectedId?.length)?.fill(true))
        } else {
            alert.error('Please select product');
            return;
        }
    }

    const handleCompareRemove = (id) => {
        if (selectedId?.includes(id)) {
            selectedId?.splice(selectedId?.indexOf(id), 1);
        }
        dispatch(getAllProductsCompare(category_slug, selectedId))
    }

    const handleDeselectAll = () => {
        setSelectedId([]);
        setSelectAllCompare(new Array(products?.products && products?.products?.data).fill(false))
        dispatch({
            type: DOWNLOAD_FAIL,
            payload: null
        })
        dispatch(getAllProductsCompare(category_slug, [], false))

    }

    const advertisment_image = "";

    const DownloadPdf = () => {
        $("#download-pdf").trigger('click')
    }

    useEffect(() => {
        if (products?.overlimit) {
            alert.error(products?.message)
            console.log("products?.overlimit:", products?.overlimit);
            setTimeout(() => {
                window.location.href = url + '/plan-details'
            }, 1000);
            //Your search limit is exhausted. Upgrade and enjoy unlimited search.
            //Arama sınırınız doldu. Yükseltin ve sınırsız aramanın tadını çıkarın.
        }
        if (documentDetailState && documentDetailState?.documentDetail?.is_compare) {
            setproductsData(documentDetailState?.documentDetail?.products?.data)
        } else {
            setproductsData(products?.products?.data)
        }
    }, [products, documentDetailState])

    products && products?.over_limit &&
        $("#popup-click-confirm").trigger('click')

    const comaSeperatedFilter = (arr) => {
        let val = ""
        for (let i = 0; i < arr.length; i++) {
            val += arr[i].display_name + (i === arr.length - 1 ? '' : ', ');
        }
        return val;
    }

    return (
        <Fragment>
            {(loading || documentDetailState?.loading) && <Loader />}
            <Fragment>
                <div className="col-lg-9">
                    <div className="dashboard-content">
                        <div className="filter_search_right">
                            <div className="search_cat_top">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <Link to={`${url}/advertised-products`}>
                                            <center>
                                                {localStorage.getItem('lang') === 'tr' ? (
                                                    <img src={ad_image1} className="mb-2 w-100" alt="Ad 1" />
                                                ) : (
                                                    <img src={ad_image} className="mb-2 w-100" alt="Ad" />
                                                )}
                                            </center>
                                        </Link>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                        <div className="c_box1 c_btn mb-2">
                                            {/* <button className="btn btn-black compare-product-btn" type="button" onClick={handleCompare}><span className="mr-1"><img src={IMAGES.COMPARE_IMG} alt="" /></span> Compare products</button> */}
                                        </div>
                                        {/* {
                                            $.map(products && products?.filter, (val, key) => {
                                                const isBgmKey = key === 'no_BGM';
                                                const displayText = isBgmKey && val == "1" ? 'No BGM' : '';
                                                if (isBgmKey) {
                                                    return (
                                                        <div className="s_c" key={key}>
                                                            <p>
                                                                <span className='first-letter'>
                                                                    <b>No BGM:</b> {displayText}
                                                                </span>
                                                            </p>
                                                        </div>
                                                    );
                                                } else {
                                                    return (
                                                        <div className="s_c" key={key}>
                                                            <p>
                                                                <span className='first-letter'>
                                                                    <b>{key}:</b> {val}
                                                                </span>
                                                            </p>
                                                        </div>
                                                    );
                                                }
                                            })
                                        } */}

                                        {
                                            //Kartik's code
                                            products?.filter && Object.entries(products?.filter)?.map(([filterName, filterArr]) => {
                                                const isBgmKey = filterName === 'no_BGM';
                                                const displayText = isBgmKey && filterArr == "1" ? 'No BGM' : '';
                                                if (isBgmKey) {
                                                    return (
                                                        <div className="s_c" key={filterName}>
                                                            <p>
                                                                <span className='first-letter'>
                                                                    <b>No BGM:</b> {displayText}
                                                                </span>
                                                            </p>
                                                        </div>
                                                    );
                                                } else {
                                                    return (
                                                        <div className="s_c" key={filterName}>
                                                            <p>
                                                                <span className='first-letter'>
                                                                    {filterName}: {Array.isArray(filterArr) ? comaSeperatedFilter(filterArr) : filterArr}
                                                                </span>
                                                            </p>
                                                        </div>
                                                    )
                                                }
                                            })
                                        }
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-3 diamond">
                                        <div className="form-group sort_by_box d-flex justify-content-end">
                                            <p className="sort_b_h text-right" onClick={handleSortByAction}><b className="mr-2"><FormattedMessage id="Select Actions" />:</b><FormattedMessage id="Actions" /> <span className="icon-chevron_down"></span></p>
                                            <div className="sort_by_action">
                                                <ul>
                                                    {
                                                        listProductAction && listProductAction?.map((data, key) => {
                                                            return (
                                                                <li className="activessff" onClick={() => handleListFilter(data?.value, data?.type, data?.display_name)}>{data?.display_name}</li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-3 col-sm-3 diamond">

                                        <div className="form-group sort_by_box d-flex justify-content-end">

                                            <p className="sort_b_h" onClick={handleSortBy}><b>
                                                <FormattedMessage id="Sort_By" defaultMessage="Sort By" />:</b>{filterdisplayname}<span className="icon-chevron_down"></span></p>
                                            <div className="sort_by_list">
                                                <ul>
                                                    {
                                                        diamondsJson && diamondsJson?.map((data, key) => {
                                                            return (
                                                                <li className="activess" onClick={() => handleAcendingFilter(data?.value, data?.type, data?.display_name)}>{data?.display_name}</li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                {/* {
                                    products && products.pdf_url && products.pdf_url.length > 0 &&
                                        <div className="row pdf_download_row text-right">
                                            
                                            <a href={products.pdf_url} target="_blank" className="not_a" id="download-pdf"  download={true}>Click here for download <img src={IMAGES.DOWNLOAD_SVG} /></a>
                                    
                                        </div>
                            } */}

                            </div>
                            <div className="form-group seach_filter row justify-content-end">
                                <div className="col-xl-8 col-lg-6 col-md-6 col-sm-6">
                                    <div className="re_text">
                                        <h4 className="font-16 black-3B fb"><FormattedMessage id="Results" defaultMessage="Results" /></h4>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                                    <input
                                        type="text"
                                        value={search}
                                        onKeyPress={(e) => handleSearchFilter(e)}
                                        onChange={handleSearch}
                                        className="form-control"
                                        placeholder={useIntl().formatMessage({ id: 'SearchBySeller' })}
                                    />
                                    <span className="icon-search_1  input_icon" onClick={handleSearchIconClick} style={{ cursor: 'pointer' }}></span>
                                </div>
                            </div>
                            <div className="filter_table">
                                <div className="tbles">
                                    <table width="100%">
                                        <tr>
                                            <th width="5%"></th>
                                            <th><FormattedMessage id="Seller" defaultMessage="Seller" /></th>
                                            <th><FormattedMessage id="Shape" defaultMessage="Shape" /></th>
                                            <th><FormattedMessage id="addsingleproduct.carat" defaultMessage="Carat" /></th>
                                            {
                                                products && products?.is_fancy == 1 ?
                                                    <Fragment>
                                                        <th><FormattedMessage id="Intensity" defaultMessage="Intensity" /></th>
                                                        <th><FormattedMessage id="Overtone" defaultMessage="Overtone" /></th>
                                                    </Fragment> :
                                                    <Fragment>
                                                    </Fragment>
                                            }
                                            <th><FormattedMessage id="addsingleproduct.color" defaultMessage="Color" /></th>
                                            <th><FormattedMessage id="addsingleproduct.clarity" defaultMessage="Clarity" /></th>
                                            <th><FormattedMessage id="addsingleproduct.cut" defaultMessage="Cut" /></th>
                                            <th><FormattedMessage id="addsingleproduct.pol" defaultMessage="Pol" /></th>
                                            <th><FormattedMessage id="addsingleproduct.sym" defaultMessage="Sym" /></th>
                                            <th><FormattedMessage id="addsingleproduct.Fluor" defaultMessage="Fluor" /></th>
                                            <th><FormattedMessage id="Cert" defaultMessage="Cert" /></th>
                                            {
                                                products && products?.is_fancy == 0 ?
                                                    <Fragment>
                                                        <th><FormattedMessage id="Disc" defaultMessage="Disc" /></th>
                                                    </Fragment> :
                                                    <Fragment>
                                                    </Fragment>
                                            }
                                            <th><FormattedMessage id="addsingleproduct.price" /></th>
                                        </tr>

                                        {
                                            productsData?.length > 0 ?
                                                productsData?.map((data, key) => {
                                                    return (
                                                        <tr>
                                                            <td>
                                                                <div className="form-group mb-0 mini checks inss">
                                                                    <input
                                                                        type="checkbox"
                                                                        id={'p' + data?.id}
                                                                        className="checkbox"
                                                                        name="check"
                                                                        checked={selectAllCompare[key]}
                                                                        onChange={() => handleSelectCompare(data?.id, key)}
                                                                    />
                                                                    <label htmlFor={'p' + data?.id}></label>
                                                                </div>
                                                            </td>
                                                            <td  >
                                                                <Link to={url + `/details/diamonds/${data?.id}`} target="_blank" className="not_a">
                                                                    <span>{CompanyNameDisplay(data)}</span>&nbsp;{"\n"} <br />
                                                                    <i style={{ color: "#003B75" }} className="fa fa-star"></i>
                                                                    {RatingDisplay('list', data.average_rating, data.total_rating)}
                                                                </Link>
                                                            </td>
                                                            <td>
                                                                <Link to={url + `/details/diamonds/${data?.id}`} target="_blank" className="not_a">
                                                                    {pColmDisplay(data, 'a_shape')}
                                                                </Link>
                                                            </td>
                                                            <td>
                                                                <Link to={url + `/details/diamonds/${data?.id}`} target="_blank" className="not_a">{data && data.carat && totalCarat(data)}
                                                                </Link>
                                                            </td>
                                                            {
                                                                products && products?.is_fancy == 1 ?
                                                                    <>
                                                                        <td>
                                                                            <Link to={url + `/details/diamonds/${data?.id}`} target="_blank" className="not_a">
                                                                                {pColmDisplay(data, 'a_fancy_intensity')}
                                                                            </Link>
                                                                        </td>
                                                                        <td>
                                                                            <Link to={url + `/details/diamonds/${data?.id}`} target="_blank" className="not_a">
                                                                                {pColmDisplay(data, 'a_fancy_overtone')}
                                                                            </Link>
                                                                        </td>
                                                                        <td>
                                                                            <Link to={url + `/details/diamonds/${data?.id}`} target="_blank" className="not_a">
                                                                                {pColmDisplay(data, 'a_fancy_color')}
                                                                            </Link>
                                                                        </td>
                                                                    </>
                                                                    :
                                                                    <td>
                                                                        <Link to={url + `/details/diamonds/${data?.id}`} target="_blank" className="not_a">
                                                                            {pColmDisplay(data, 'a_color')}
                                                                        </Link>
                                                                    </td>

                                                            }
                                                            <td>
                                                                <Link to={url + `/details/diamonds/${data?.id}`} target="_blank" className="not_a">
                                                                    {pColmDisplay(data, 'a_clarity')}
                                                                </Link>
                                                            </td>
                                                            <td>
                                                                <Link to={url + `/details/diamonds/${data?.id}`} target="_blank" className="not_a">
                                                                    {data && data.a_cut && capitalizeFirstLetterWithOnlyLetter(pColmDisplay(data, 'a_cut'))}
                                                                </Link>
                                                            </td>
                                                            <td>
                                                                <Link to={url + `/details/diamonds/${data?.id}`} target="_blank" className="not_a">
                                                                    {data && data.a_polish && capitalizeFirstLetterWithOnlyLetter(pColmDisplay(data, 'a_polish'))}
                                                                </Link>
                                                            </td>
                                                            <td>
                                                                <Link to={url + `/details/diamonds/${data?.id}`} target="_blank" className="not_a">
                                                                    {data && data.a_symmetry && capitalizeFirstLetterWithOnlyLetter(pColmDisplay(data, 'a_symmetry'))}
                                                                </Link>
                                                            </td>
                                                            <td>
                                                                <Link to={url + `/details/diamonds/${data?.id}`} target="_blank" className="not_a">
                                                                    {data && data.a_fluorescence && capitalizeFirstLetterWithOnlyLetter(pColmDisplay(data, 'a_fluorescence'))}
                                                                </Link>
                                                            </td>
                                                            {/* {data && data?.certificate_url ?
                                                                <td><a href={GenerateCertificatePdf(data?.certificate_url)} target={GenerateCertificatePdf(data?.certificate_url)}>{pColmDisplay(data, 'a_certificate')}</a></td>
                                                                :
                                                                <td><a href={hrdurl+data.certificate_number} target="_blank">{pColmDisplay(data, 'a_certificate')}</a></td>
                                                            } */}
                                                            {
                                                                (() => {
                                                                    if (data && data?.certificate_url) {
                                                                        return <td><a href={GenerateCertificatePdf(data?.certificate_url)} target={GenerateCertificatePdf(data?.certificate_url)}>{pColmDisplay(data, 'a_certificate')}</a></td>
                                                                    }
                                                                    else if (data.certificate == "422" && pColmDisplay(data, 'a_certificate') == "HRD") {
                                                                        return <td><a href={hrdurl + data.certificate_number} target="_blank">{pColmDisplay(data, 'a_certificate')}</a></td>
                                                                    } else if (data.certificate == "25" && pColmDisplay(data, 'a_certificate') == "GIA") {
                                                                        return <td><a href={giaurl + data.certificate_number} target="_blank">{pColmDisplay(data, 'a_certificate')}</a></td>
                                                                    } else {
                                                                        return <td>{pColmDisplay(data, 'a_certificate')}</td>
                                                                    }
                                                                })()
                                                            }
                                                            {
                                                                products && products?.is_fancy == 0 ?
                                                                    <Fragment>
                                                                        <td>
                                                                            <Link
                                                                                to={url + `/details/diamonds/${data?.id}`}
                                                                                target="_blank"
                                                                                className="not_a">
                                                                                {data && data?.discount && data?.admin_price != 'null' ? totalPriceDisplayforDiscount(data) : "--"}
                                                                            </Link>
                                                                        </td>
                                                                    </Fragment> :
                                                                    <Fragment>
                                                                    </Fragment>
                                                            }
                                                            <td>
                                                                <Link
                                                                    to={url + `/details/diamonds/${data?.id}`}
                                                                    target="_blank"
                                                                    className="not_a">
                                                                    {totalPriceDisplay(data)}
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                                :
                                                <tr>
                                                    <th colspan="12"><FormattedMessage id="ProductNotFound" defaultMessage="Product not Found" /></th>
                                                </tr>
                                        }

                                    </table>
                                </div>

                                <div className="table_pagination">
                                    <ReactPaginate
                                        previousLabel={<i className="icon icon-chevron_gray-left"></i>}
                                        nextLabel={<i className="icon icon-chevron_black"></i>}
                                        pageCount={pageCount}
                                        onPageChange={handlePageChange}
                                        containerClassName={"paginationBttns"}
                                        previousLinkClassName={"previousBttn"}
                                        nextLinkClassName={"nextBttn"}
                                        disabledClassName={"paginationDisabled"}
                                        activeClassName={"paginationActive"}
                                        forcePage={props?.pageReset}
                                        //initialPage={pageNumber && pageNumber}
                                        //renderOnZeroPageCount={null}
                                        disableInitialCallback={true}
                                    />
                                </div>
                                <div className="compare_list">
                                    {
                                        documentDetailState?.documentDetail && documentDetailState?.documentDetail?.is_compare ?
                                            documentDetailState?.documentDetail && documentDetailState?.documentDetail?.products?.data?.map((data, key) => {
                                                return (
                                                    <div className="c_box1">
                                                        <i className="icon_b icon-minus" onClick={() => handleCompareRemove(data?.id, key)}></i>
                                                        <p className="font-14 black-3B "><b>{data.company_name}</b>${totalPriceDisplay(data)}</p>
                                                    </div>
                                                )
                                            })
                                            :
                                            null
                                    }
                                    {
                                        documentDetailState?.documentDetail && documentDetailState?.documentDetail?.is_compare
                                            ?
                                            <div className="c_box1">
                                                <i className="icon_black icon-minus" style={{ cursor: 'pointer' }} onClick={handleDeselectAll}></i>
                                                <p className="font-14 black-3B">Deselect all</p>
                                            </div>
                                            :
                                            null
                                    }

                                    {/* <div className="c_box1 c_btn">
                                        <button className="btn btn-black" type="button" onClick={handleCompare}><span className="mr-1"><img src={IMAGES.COMPARE_IMG} alt="" /></span> Compare products</button>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
            {
                <PopupConfirm
                    message={popUpLimitMessage}
                    title={popUpLimitTitle}
                    handleResForm={handleResForm}
                />
            }
        </Fragment>

    )
}

export default WhiteDimonds
