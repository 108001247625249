import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    // CardElement,
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
    // Elements,
    useStripe,
    useElements,
} from "@stripe/react-stripe-js";
import 'react-toastify/dist/ReactToastify.css';
import CurrencyLiraIcon from '@mui/icons-material/CurrencyLira';
import { FREE_PLAN_FEATURE, IMAGES } from '../../../constants';
import MetaData from '../../layout/MetaData';
import ProtectedHeader from '../../layout/ProtectedHeader';
import Sidebar from './Sidebar';
import { currentPlan, cancelPlan, clearError, loadUser } from '../../../actions/userActions';
import { PlanType, savePercentage, UnlimitedUpload } from '../../../helper';
import { setColor, numberWithCommas, getLastURL, getParamsByName, deviceDisplay } from '../../../helper';
import { makeRequestGet } from '../../../utils/apiHelper';
import $, { event } from 'jquery';
import { FormattedMessage } from 'react-intl';
import Jeweler from '../../HomePage/Jeweler';
import Dealer from '../../HomePage/Dealer';
import PopupConfirm from '../../layout/PopupConfirm';
import { useAlert } from 'react-alert';
import { JEWELER_SMALL, JEWELER_SMALL_SEARCHES } from '../../../constants/index';
import { currency, fetchFromAPI, url,base_url } from '../../../config';
import NavbarCollapse from 'react-bootstrap/esm/NavbarCollapse';
import Loader from '../../layout/Loader';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { PurchasePlanStripeAction } from '../../../actions/stripeAction';
import { SubscriptionStripeAction } from '../../../actions/SubscriptionAction';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';


const PlanDetails = () => {
    const [otherPlanPeriod, setOtherPlanPeriod] = useState(false);
    const [cancelPopUp, setcancelPopUp] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch()
    const alert = useAlert();
    const location = useLocation()
    const [email, setEmail] = useState('');
    const stripe = useStripe();
    const elements = useElements();
    const [selectedPlan, setSelectedPlan] = useState({
        planName: "",
        planPrice: "",
        stripePlanId: ""
    });
    const { currentplan, loadingCp } = useSelector(state => state.currentPlan)
    const { user } = useSelector(state => state.user)
    const { cancelplan, error, loading } = useSelector(state => state.cancelPlanRes)
    const { payment_loading, payment_status, payment_data, payment_error } = useSelector((state) => state.PurchasePlanStripeState)
    const [loadingP, setLoadingP] = useState(loading);
    const [loaderStripe, setLoaderStripe] = useState(false)
    const [clickCount, setClickCount] = useState(0);


    useEffect(() => {
        if (cancelplan && cancelplan.status) {
            alert.success(cancelplan.message);
            return
        }
        if (cancelplan && cancelplan.status == false) {
            alert.error(cancelplan.message)
            return
        }
        dispatch(currentPlan());

    }, [dispatch, cancelplan])



    useEffect(async () => {
        var dump = getParamsByName(window.location.href, 'dump')
        await sessionStorage.setItem('user', JSON.stringify(currentplan && currentplan.profile && currentplan.profile));
        if (dump == 1) {
           window.location.href = url + '/plan-details'
        }
    }, [currentplan])

    const handleOtherPlanPeriod = (event) => {
        setOtherPlanPeriod(event.target.checked);
    }

    const handleCancelSubsClick = (event) => {
        setcancelPopUp(true);
        $("#popup-click-confirm").trigger('click');
    }

    const handleResForm = async (status) => {
        if (status) {
            // setcancelPopUp(false);
            //dispatch(cancelPlan(currentplan && currentplan.transaction_id));
            setLoaderStripe(true)
            await dispatch(cancelPlan(currentplan && currentplan.CurrentPlan && currentplan.CurrentPlan.transaction_id));
            await dispatch(currentPlan());
            setLoaderStripe(false)
        }
        $("#dismiss-modal").trigger('click');

    }


    const selectPlan = (stripePlanId, planName, planPrice) => {
        setSelectedPlan({
            planName: planName,
            planPrice: planPrice,
            stripePlanId: stripePlanId
        })
    }

    const [name, setName] = useState("");

    const handleSubmit = async (event) => {

        try {
            await setLoaderStripe(true)

            let payload = await {};
            setLoaderStripe(true)
            payload = {
                price_id: event.stripePlanId,
                success_url: location?.pathname,
                cancel_url: location?.pathname
                // success_url: "/plan-details",
                // cancel_url:  "/plan-details"
            };

            await dispatch(PurchasePlanStripeAction(payload))
            await setLoaderStripe(false)
            setLoaderStripe(false)
        } catch (err) {
            console.error(err?.message);
        }
    };


    useEffect(async () => {
        if (payment_data?.status) {
            window.location.href = payment_data?.data?.url

        } else if (payment_error) {
            await alert.error(payment_error);
        }
    }, [payment_data, payment_error])

    // useEffect(async () => {
    //     if (payment_data?.data?.status === "active" || payment_data?.data?.status === "trialing") {
    //         await toast.success(payment_data?.message)
    //         setTimeout(() => {
    //             window.location.reload()
    //         }, 3000);
    //     } else if (payment_status === false) {
    //         await toast.error(payment_error?.data?.message);
    //     }
    // }, [payment_data, payment_error])

    const cardNumberElementOptions = {
        style: {
            base: {
                iconColor: "#c4f0ff",
                color: "#fff",
                fontWeight: "500",
                fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
                fontSize: "16px",
                fontSmoothing: "antialiased",
                ":-webkit-autofill": {
                    color: "#fff",
                },
                // "::placeholder": {
                //   color: "#87BBFD",
                // },
            },
            invalid: {
                iconColor: "#FFC7EE",
                color: "#eb1c26",
            },
        },
        placeholder: "Card Number",
    };

    const cardExpireElementOptions = {
        style: {
            base: {
                iconColor: "#c4f0ff",
                color: "#fff",
                fontWeight: "500",
                fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
                fontSize: "16px",
                fontSmoothing: "antialiased",
                ":-webkit-autofill": {
                    color: "#fff",
                },
                // "::placeholder": {
                //   color: "#87BBFD",
                // },
            },
            invalid: {
                iconColor: "#FFC7EE",
                color: "#eb1c26",
            },
        },
    };

    useEffect(() => {
        const handleOutsideClick = (e) => {
            const modal = document.querySelector('#stripePayment');
            if (modal && !modal.contains(e.target)) {
                setName('');
                elements.getElement('cardNumber').clear();
                elements.getElement('cardExpiry').clear();
                elements.getElement('cardCvc').clear();

            }
        };

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    });


    const onCloseModal = () => {
        setName('');
        elements.getElement('cardNumber').clear();
        elements.getElement('cardExpiry').clear();
        elements.getElement('cardCvc').clear();
    }


    const stripePayment = (stripePlanId, planName, planPrice) => {
        const payload = {
            stripePlanId,
            planName,
            planPrice
        }
        dispatch(SubscriptionStripeAction(payload))
        history.push(url + '/stripe');
    }

    const stringReplace = (text) => {
        console.log('text::', text);
        
        let replace = text.replace(/(<([^>]+)>)/ig, '');
        return replace;
    }

    let isPlanNotExpired = (date) => {
        let expiry = new Date(date)
        let today = new Date();
        return expiry >= today;
    }
console.log("currentplan", currentplan);

    return (
        <Fragment>
            {loadingP || loadingCp && <Loader />}
            {loaderStripe || payment_loading ? <Loader /> : ""}

            <MetaData title={`Plan Details`} />
            <ProtectedHeader />
            <ul className="breadcrub">
                <li>
                    <FormattedMessage
                        id="myaccount.myaccountname"
                        defaultMessage="myaccount"
                    />

                </li>
                <li><img src={IMAGES.ARROW_SVG} /></li>
                <li>
                    <FormattedMessage
                        id="myaccount.plan"
                        defaultMessage="Plan details"
                    />
                </li>
            </ul>

            <section className="container-fluid py-4 conPart ">
                <div className="row">
                    <Sidebar />
                    <div className="col-md-10 crntp">
                        {
                            currentplan?.profile?.is_valid_plan == 1
                                ?
                                currentplan?.CurrentPlan?.transaction_id &&
                                <Fragment>
                                    <div className="row">
                                        <div className="col-lg-6 col-md-8">
                                            <div className="cu_head mb-3 d-flex justify-content-between align-items-center">
                                                <h2><FormattedMessage id="CurrentPlan" defaultMessage="Current Plan" /></h2>
                                                <h4 className="cancle_plan" onClick={handleCancelSubsClick}>
                                                    <span className="icon-close_1"></span>
                                                    <FormattedMessage id="CancelSubscription" defaultMessage="Cancel Subscription" />
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mx-0 mb-5">
                                        <div className="col-lg-6 col-md-8 pl-0">
                                            <div className="paybox dShow p-3 ">
                                                <div className="p_s">
                                                    <div className="rightCon mb-3">
                                                        <div className="p_s_top mb-2 d-flex justify-content-between align-items-center">
                                                            <h4 className="font-16 Light_color fb mb-3">{currentplan && currentplan.CurrentPlan && currentplan.CurrentPlan.name}</h4>
                                                            {
                                                                currentplan && currentplan.CurrentPlan && currentplan.CurrentPlan.plan_payment_period === 1 ?
                                                                    <p>{currency} {currentplan && currentplan.CurrentPlan && currentplan.CurrentPlan.monthly_price} <span>/ <FormattedMessage id="mo" /></span></p>
                                                                    :
                                                                    <p>{currency}{currentplan && currentplan.CurrentPlan && currentplan.CurrentPlan.yearly_price} <span>/ <FormattedMessage id="yr" /></span></p>
                                                            }

                                                        </div>
                                                        <div className="d-flex">
                                                            <div className="device_d mr-3">
                                                                <span className="icon-device_1"></span>
                                                                <span className="icon-device_2"></span>
                                                                <p>{currentplan && currentplan.CurrentPlan && currentplan.CurrentPlan.device_limit + " " + deviceDisplay(currentplan.CurrentPlan.device_limit)} </p>
                                                            </div>
                                                            <div className="device_d">
                                                                <span className="fa fa-long-arrow-up"></span>
                                                                <p>

                                                                    {currentplan && currentplan.CurrentPlan && currentplan.CurrentPlan.upload_limit &&
                                                                        currentplan.CurrentPlan.upload_limit.toLowerCase() === "unlimited" ?
                                                                        localStorage.getItem('lang') == 'tr' ? ' Sınırsız' : 'Unlimited'
                                                                        : `${currency}` + numberWithCommas(currentplan?.CurrentPlan?.upload_limit)
                                                                    }
                                                                    &nbsp;
                                                                    {currentplan && currentplan.CurrentPlan && currentplan.CurrentPlan?.upload_limit &&
                                                                        currentplan.CurrentPlan?.upload_limit != "Unlimited upload" && <FormattedMessage id="upload" defaultMessage="upload" />}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row choosers d-flex brdr-top pt-4 mt-2">
                                                    <div className="col-md-5">
                                                        <p className="m-0"><FormattedMessage id="NextPayment" defaultMessage="Next Payment" />: <span> {currentplan && currentplan.CurrentPlan && currentplan.CurrentPlan.nextPaymentDate} </span></p>
                                                    </div>
                                                    <div className="col-md-7 text-right fb black-3B">
                                                        <div className="cus_switch m-0  d-flex align-item-center justify-content-end">
                                                            <p className="font-14 fr m-0  pl-3" style={{ color: "#236653", fontWeight: 600 }}><b
                                                                className="black-3B">{currentplan && currentplan.CurrentPlan && currentplan.CurrentPlan.plan_payment_period == 1 ? <FormattedMessage id="Monthly" defaultMessage="Monthly" /> : <FormattedMessage id="Annual" defaultMessage="Annual" />}  </b> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Fragment>
                                :
                                currentplan?.OtherPlans?.map(plan => (
                                    plan?.user_type === PlanType?.Freemium ?
                                        <>
                                            <div className="row">
                                                <div className="col-lg-6 col-md-8">
                                                    <div className="cu_head mb-3 d-flex justify-content-between align-items-center">
                                                        <h2><FormattedMessage id="CurrentPlan" defaultMessage="Current Plan" /></h2>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mx-0 mb-5">
                                                <div className="col-lg-6 col-md-8 pl-0">
                                                    <div className="paybox dShow p-3 ">
                                                        <div className="p_s">
                                                            <div className="rightCon mb-3">
                                                                <div className="p_s_top mb-2 d-flex justify-content-between align-items-center">
                                                                    <h4 className="font-16 Light_color fb mb-3">{plan.name}</h4>
                                                                    {/* {
                                                                        plan.plan_payment_period === 1 ?
                                                                            <p>{currency} {plan.monthly_price} <span>/ <FormattedMessage id="mo" /></span></p>
                                                                            :
                                                                            <p>{currency}{plan.yearly_price} <span>/ <FormattedMessage id="yr" /></span></p>
                                                                    } */}
                                                                </div>
                                                                {/* <div className="d-flex">
                                                                    <div className="device_d mr-3">
                                                                        <span className="icon-device_1"></span>
                                                                        <span className="icon-device_2"></span>
                                                                        <p>{plan.device_limit + " " + deviceDisplay(plan?.device_limit)} </p>
                                                                    </div>
                                                                    <div className="device_d">
                                                                        <span className="fa fa-long-arrow-up"></span>
                                                                        <p>

                                                                            {plan.upload_limit &&
                                                                                plan?.upload_limit.toLowerCase() === "unlimited" ?
                                                                                localStorage.getItem('lang') == 'tr' ? ' Sınırsız' : 'Unlimited'
                                                                                : `${currency}` + numberWithCommas(plan?.upload_limit)
                                                                            }
                                                                            &nbsp;
                                                                            {plan?.upload_limit &&
                                                                                plan?.upload_limit != "Unlimited upload" && <FormattedMessage id="upload" defaultMessage="upload" />}</p>
                                                                    </div>
                                                                </div> */}
                                                            
                                                                <p style={{ fontWeight: "bold", color: "red" }}> {
                                                                        stringReplace(FREE_PLAN_FEATURE)
                                                                }</p>
                                                            
                                                            </div>
                                                        </div>
                                                        {/* <div className="row choosers d-flex brdr-top pt-4 mt-2">                                                            
                                                            <div className="col-md-7 text-right fb black-3B">
                                                                <div className="cus_switch m-0  d-flex align-item-center justify-content-end">
                                                                    <p className="font-14 fr m-0  pl-3" style={{ color: "#236653", fontWeight: 600 }}><b
                                                                        className="black-3B">{plan.plan_payment_period == 1 ? <FormattedMessage id="Monthly" defaultMessage="Monthly" /> : <FormattedMessage id="Annual" defaultMessage="Annual" />}  </b> </p>
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        : ""
                                ))
                        }
                        < h2 className="mt-4 mb-3 font-18"><FormattedMessage id="OtherPlans" defaultMessage="Other Plans" /></h2>

                        <section id="pricings inner" className="container  pricings">
                            <div className="row justify-content-center">
                                <ul className="nav nav-tabs text-center col-md-5" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <a className={currentplan && currentplan?.CurrentPlan?.role == 1 ? "nav-link active" : "nav-link"} id="home-tab" data-toggle="tab" href="#jeweler" role="tab" aria-controls="jeweler" aria-selected="true"><FormattedMessage id="Jeweler" defaultMessage="Jeweler" /></a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a className={currentplan && currentplan?.CurrentPlan?.role == 2 ? "nav-link active" : "nav-link"} id="profile-tab" data-toggle="tab" href="#dealer" role="tab" aria-controls="dealer" aria-selected="false"><FormattedMessage id="Supplier" defaultMessage="Supplier" /></a>
                                    </li>
                                </ul>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="cus_switch mb-3  d-flex align-item-center">
                                        <p className="font-14 fr black-3B pr-3"><FormattedMessage id="Monthly" defaultMessage="Monthly" /></p>
                                        <label className="switch">
                                            <input type="checkbox" onChange={handleOtherPlanPeriod} />
                                            <span className="slider round"></span>
                                        </label>
                                        <p className="font-14 fr pl-3" style={{ color: "#236653", fontWeight: 600 }}><b className="black-3B"><FormattedMessage id="Annual" defaultMessage="Annual" /></b> <FormattedMessage
                                            id="billing.save-percentage"
                                            defaultMessage="Save up to 16.19%"
                                        /></p>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <div className="tab-content pt-5 innerdiv" id="myTabContent">
                            <div className={currentplan && currentplan?.CurrentPlan?.role == 1 ? "tab-pane fade show active" : "tab-pane fade"} id="jeweler" role="tabpanel" aria-labelledby="home-tab">
                                <div className="row  mb-3">
                                    {
                                        currentplan && currentplan.OtherPlans && currentplan.OtherPlans.map((plan, key) => {
                                            const planDetails = {
                                                "stripePlanId": otherPlanPeriod ? plan?.plan_id_year : plan?.plan_id_month,
                                                "planName": plan?.name,
                                                "planPrice": otherPlanPeriod ? plan?.yearly_price : plan?.monthly_price,
                                                "plan_validity": otherPlanPeriod ? 1 : 0
                                            }
                                            return (
                                                plan.user_type == 1 && 
                                                <div className="col-lg-3 col-md-4 mb-5">

                                                    <div onClick={() => handleSubmit(planDetails)}>
                                                        <div className="sub_plan_box active_new_plan " data-toggle="modal" data-target="#UpdatePlans">
                                                            <h3 className={setColor(plan.order_by)}>{plan.name} </h3>
                                                            <p style={{ fontWeight: "bold", color: "red" }}> {
                                                                plan.features !== null ?
                                                                    stringReplace(plan?.features)
                                                                    : ""
                                                            }</p>
                                                            <br />
                                                            {
                                                                plan.id == JEWELER_SMALL ?
                                                                    <div className="device_d">
                                                                        <p>{JEWELER_SMALL_SEARCHES} <FormattedMessage id="Searches"></FormattedMessage></p>
                                                                    </div>
                                                                    :
                                                                    <div className="device_d">
                                                                        <span className="icon-device_1"></span>
                                                                        <span className="icon-device_2"></span>
                                                                        <p>{plan.device_limit + " " + deviceDisplay(plan.device_limit)} </p>
                                                                    </div>
                                                            }
                                                            <p style={{ fontWeight: "bold", color: "red" }}> {
                                                                plan.features !== null ?
                                                                    stringReplace(plan?.features)
                                                                    : ""
                                                            }</p>

                                                            {
                                                                otherPlanPeriod ?
                                                                    <h4>{currency}{plan.yearly_price}/<FormattedMessage id="yr" /></h4> :
                                                                    <h4>{currency}{plan.monthly_price}/<FormattedMessage id="mo" /></h4>
                                                            }
                                                            <p><span><FormattedMessage id="Save" /> {savePercentage(plan.monthly_price, plan.yearly_price)}%</span> <FormattedMessage id="Billed yearly" /></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>

                            <div className={currentplan && currentplan?.CurrentPlan?.role == 2 ? "tab-pane fade show active" : "tab-pane fade"} id="dealer" role="tabpanel" aria-labelledby="profile-tab">
                                <div className="row  mb-3">
                                    {
                                        currentplan && currentplan.OtherPlans && currentplan.OtherPlans.map((plan, key) => {
                                            const planDetails = {
                                                "stripePlanId": otherPlanPeriod ? plan?.plan_id_year : plan?.plan_id_month,
                                                "planName": plan?.name,
                                                "planPrice": otherPlanPeriod ? plan?.yearly_price : plan?.monthly_price,
                                                "plan_validity": otherPlanPeriod ? 1 : 0
                                            }

                                            return (
                                                plan.user_type == 2 &&
                                                <div className="col-lg-3 col-md-4" >
                                                    <div onClick={() => handleSubmit(planDetails)}>

                                                        <div className="sub_plan_box active_new_plan " data-toggle="modal" data-target="#UpdatePlans">
                                                            <h3 className={setColor(plan.order_by)}>{plan.name}</h3>
                                                            <p style={{ fontWeight: "bold", color: "red" }}> {
                                                                plan.features !== null ?
                                                                    stringReplace(plan?.features)
                                                                    : ""

                                                            }</p>
                                                            <br />

                                                            <div className="device_d mb-3"><span className="fa fa-long-arrow-up"></span><p>

                                                                {plan.upload_limit && plan.upload_limit.toLowerCase() === "unlimited" ?
                                                                    localStorage.getItem('lang') == 'tr' ? ' Sınırsız' : 'Unlimited'
                                                                    : `${currency}` + numberWithCommas(plan?.upload_limit)
                                                                }  &nbsp;
                                                                {plan.upload_limit != "Unlimited upload" && <FormattedMessage id="upload" defaultMessage="upload" />}

                                                            </p></div>
                                                            <div className="device_d">
                                                                <span className="icon-device_1"></span>
                                                                <span className="icon-device_2"></span>
                                                                <p>{plan.device_limit + " " + deviceDisplay(plan.device_limit)} </p>
                                                            </div>
                                                            {
                                                                otherPlanPeriod ?
                                                                    <h4>{currency}{plan.yearly_price}/<FormattedMessage id="yr" /></h4> :
                                                                    <h4>{currency}{plan.monthly_price}/<FormattedMessage id="mo" /></h4>
                                                            }
                                                            <p><span><FormattedMessage id="Save" /> {savePercentage(plan.monthly_price, plan.yearly_price)}%</span> <FormattedMessage id="Billed yearly" /></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <Dealer />
                        </div>
                    </div>
                </div>
            </section>
            {
                <PopupConfirm
                    message="Are you sure to want to cancel subscription?"
                    title="Confirmation"
                    handleResForm={handleResForm}
                />
            }

            {/* //PAYMENT Model BOX */}
            <div
                className="modal moder_s2 fade"
                id="stripePayment"
                tabIndex={-1}
                aria-labelledby="stripePaymentTitle"
                aria-hidden="true">

                <div className="modal-dialog modal-dialog-centered" >
                    {loaderStripe || payment_loading ? <Loader /> : ""}
                    {/* {paymentData?.loading ? <Loader /> : ""} */}

                    <div className="modal-content">

                        <div className="modal-body p-5 text-center">
                            <button type="button" className="close" onClick={() => onCloseModal()} data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <input type="hidden" id="selectedStripePlan" />
                            <input type="hidden" id="selectedPayPalPlan" />
                            <input type="hidden" id="selectedPlanCycle" />

                            <div>
                                <h6 className='text-start'>Card Details</h6>
                                <form >
                                    <div className="row inputstyle1">
                                        <div className="col-md-12 iconInput mb-3">
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={name}
                                                placeholder="Card holder Name"
                                                onChange={(e) => setName(e.target.value)}
                                                required={true}
                                            />
                                        </div>


                                        <div className="col-12 iconInput mb-3">
                                            <CardNumberElement
                                                className="cardNumberElement"
                                                options={cardNumberElementOptions}
                                            />
                                            <i className="fa fa-credit-card-alt" aria-hidden="true"></i>
                                        </div>

                                        <div className="col-6 mb-3">
                                            <CardExpiryElement
                                                className="cardExpiryElement"
                                                options={cardExpireElementOptions}
                                            />
                                        </div>
                                        <div className="col-6 mb-3">
                                            <CardCvcElement
                                                className="cardCvcElement"
                                                options={cardExpireElementOptions}
                                            />
                                        </div>
                                    </div>
                                    <button
                                        type='submit'
                                        onClick={handleSubmit}
                                        className="btn btn-primary px-5 my-4"
                                        disabled={!stripe || !elements}
                                    >
                                        Pay
                                    </button>
                                    {selectedPlan?.planPrice === 0 ?
                                        <p style={{ color: "#fff", fontSize: "16px" }}>*179.00 TL subscription fee will be withdrawn after 30 days trial period.</p>
                                        : ""}
                                </form>

                                {selectedPlan?.planName !== "" ?
                                    <div className="col-12 N_pack">
                                        {otherPlanPeriod !== true ? "Monthly" : "Yearly"}
                                        <div>
                                            {selectedPlan?.planPrice}<CurrencyLiraIcon />/  {selectedPlan?.planName}
                                            <span className="badge">
                                                Selected Plan
                                            </span>
                                        </div>
                                    </div>
                                    : ""}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                autoClose={2500}
                hideProgressBar={true}
                className="toast-chilling"
                position="top-right"
                newestOnTop={false}
                closeOnClick={true}
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover={false}
                theme="light"
            />

        </Fragment >
    )
}

export default PlanDetails
